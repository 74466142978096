import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div``;

const Enter = styled.input`
  appearance: none;
  outline: none;
  border: none;
`;

const Header = styled.i``;

const Textarea = forwardRef(({ label, type, name, value, setValue, required, pattern, minLength, maxLength }, ref) => {
  const [isEmpty, setIsEmpty] = useState(true);
  return (
    <Container>
      <Header big={value === '' && isEmpty}>{label}</Header>
      <Enter
        ref={ref}
        type={type}
        name={name}
        value={value}
        onChange={e => setValue(e.target.value)}
        required={required}
        pattern={pattern}
        minLength={minLength}
        maxLength={maxLength}
        onFocus={() => setIsEmpty(false)}
        onBlur={e => setIsEmpty(e.target.value === '')}
      />
    </Container>
  );
});

Textarea.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  pattern: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number
};

Textarea.defaultProps = {
  value: '',
  setValue: PropTypes.func,
  required: false,
  pattern: `.`,
  minLength: 0,
  maxLength: PropTypes.value
};

export default Textarea;
