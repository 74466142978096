import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Media } from 'fowlit';
import { Loader, SearchBar, Residence } from 'Components';
import { Circle } from 'Components/Statistics';
import { useSelector } from 'react-redux';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 70px 1fr;
  grid-template-areas: 'search' 'result';
  position: relative;
  padding: 50px 10vw;
  position: relative;
  color: ${props => props.theme.color};
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
  }
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 220px;
  background: ${props => props.theme.main};
  z-index: 10;
`;

const Search = styled.div`
  grid-area: search;
  position: sticky;
  top: 100px;
  z-index: 11;
`;

const Results = styled.div`
  grid-area: result;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-areas: 'recommendation' 'available';
  z-index: 9;
`;

const RecidenceContainer = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fill, 25px);
  grid-template-rows: repeat(auto-fill, 25px);
  grid-auto-column: 25px;
  grid-auto-rows: 25px;
  padding: 25px 0;

  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-auto-flow: column wrap;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 25px);
    grid-template-rows: repeat(auto-fit, 25px);
  }
`;

const Stats = styled(RecidenceContainer)`
  margin-top: 50px;
`;

const Recommendation = styled.div`
  grid-area: recommendation;
  display: grid;
  grid-template-rows: auto 1fr;
  padding-top: 100px;
`;

const Available = styled.div`
  grid-area: available;
  display: grid;
  grid-template-rows: 50px 1fr;
  padding-top: 100px;
`;

const countTypes = bookings =>
  bookings.reduce(
    (e, e1) => {
      switch (e1.type) {
        case 'cabin':
          e.cabin += 1;
          break;
        case 'hostel':
          e.hostel += 1;
          break;
        case 'caravan':
          e.caravan += 1;
          break;
        case 'tent':
          e.tent += 1;
          break;
        case 'room':
          e.room += 1;
          break;
        default:
          console.log('HOUSING TYPE NOT YET IMPLEMENTED');
          break;
      }
      return e;
    },
    {
      cabin: 0,
      hostel: 0,
      caravan: 0,
      tent: 0,
      room: 0
    }
  );

const Camping = () => {
  const [circles, setCircles] = useState([]);

  const current = useSelector(state => state.bookings.current.bookings);
  const housings = useSelector(state => state.housings.housings);

  useEffect(() => {
    if (current.length > 0 && housings.length > 0) {
      const now = new Date();

      let flat = [];
      current
        .map(e =>
          e.bookings.filter(
            e1 =>
              new Date(Date.parse(e1.startTime)) < now &&
              new Date(Date.parse(e1.endTime)) > now &&
              e1.itemType === 'HOUSING'
          )
        )
        .forEach(e => e.forEach(e1 => flat.push(e1)));
      flat = flat.map(e => housings.find(e1 => e.itemID === e1.id));

      const vals = countTypes(flat.map(e => e));
      setCircles([
        { value: vals.cabin, label: 'stugor' },
        { value: vals.room, label: 'rum' },
        { value: vals.caravan, label: 'husvagn' },
        { value: vals.tent, label: 'tält' }
      ]);
    } else {
      setCircles([
        { value: 0, label: 'stugor' },
        { value: 0, label: 'rum' },
        { value: 0, label: 'husvagn' },
        { value: 0, label: 'tält' }
      ]);
    }
  }, [current, housings]);

  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dates, setDates] = useState([]);

  return (
    <main>
      <Container>
        <Cover />
        <Search>
          <SearchBar
            setResult={setResult}
            setLoading={setLoading}
            setError={setError}
            setDates={setDates}
            dates={dates}
          />
        </Search>
        <Results>
          {(() => {
            if (loading) {
              return <Loader active />;
            }
            if (error) {
              return <Loader active />;
            }
            if (!result) {
              if (!error) {
                return (
                  <Stats>
                    {circles.map(c => (
                      <Circle key={c.label} spec={c} />
                    ))}
                  </Stats>
                );
              }
              return (
                <Recommendation>
                  <h4>Kunde inte hitta några lediga bostäder.</h4>
                </Recommendation>
              );
            }

            return (
              <>
                {result.rec && (
                  <Recommendation>
                    <h4>Rekommenderad bokning</h4>
                    <RecidenceContainer>
                      {result.rec.map(e => (
                        <Residence
                          key={e.id}
                          spec={e}
                          info={{ housingID: e.id, startTime: dates[0], endTime: dates[1] }}
                        />
                      ))}
                    </RecidenceContainer>
                  </Recommendation>
                )}

                {result.housing ? (
                  <Available>
                    <h4>{`Tillgängliga bostäder (${result.housing.length})`}</h4>
                    <RecidenceContainer>
                      {result.housing
                        .sort((e, e1) => parseInt(e.id, 10) > parseInt(e1.id, 10))
                        .map(e => (
                          <Residence
                            key={e.id}
                            spec={e}
                            info={{ housingID: e.id, startTime: dates[0], endTime: dates[1] }}
                          />
                        ))}
                    </RecidenceContainer>
                  </Available>
                ) : (
                  <Recommendation>
                    <h4>Kunde inte hitta några lediga bostäder.</h4>
                  </Recommendation>
                )}
              </>
            );
          })()}
        </Results>
      </Container>
    </main>
  );
};

export default Camping;
