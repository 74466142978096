import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Colors, Fonts } from 'fowlit';
import { Themes } from 'Res/stylesheets/variables';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from 'Reducers/Session/actions';
import { logout } from 'Library';
import { useComponentVisible } from 'Hooks';

const Container = styled.div`
  user-select: none;
  margin: 0 20px 0 10px;
  font-size: ${Fonts.sizeSuperTiny};
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  border-radius: 50%;
  padding: ${props => (props.active ? '2px' : 0)};
  transition: 200ms ease-out;
  position: relative;
  background: ${props =>
    props.active
      ? `linear-gradient(to bottom, ${Colors.blueberry}, ${Colors.physalis}, ${Colors.strawberry});`
      : 'transparent'};
  &:focus {
    outline: none;
  }
`;

const Profile = styled.div`
  height: ${props => (props.active ? '36px' : '40px')};
  width: ${props => (props.active ? '36px' : '40px')};
  border-radius: 50%;
  border: 1px solid ${props => props.theme.border};
  transition: 200ms ease-out;
  cursor: pointer;
`;

const Image = styled.div`
  background: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

const Initials = styled.i`
  background: linear-gradient(to bottom left, ${Colors.blueberry}, ${Colors.blueberryDarker});
  color: ${Colors.white};
  text-transform: uppercase;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${Fonts.head};
`;

const Dropdown = styled.div`
  position: absolute;
  top: 50px;
  right: 0px;
  width: 200px;
  background: ${props => props.theme.background};
  border-radius: 5px;
  display: ${props => (props.active ? 'flex' : 'none')};
  flex-flow: column;
  border: 1px solid ${props => props.theme.border};
`;

const Username = styled.div`
  display: flex;
  flex-flow: column;
  padding: 10px;
  color: ${props => props.theme.color};
  b {
    font-family: ${Fonts.head};
    padding: 10px 50px 0 10px;
  }
  i {
    padding: 0 50px 10px 10px;
  }
`;

const Options = styled.div`
  display: flex;
  flex-flow: column;
  padding: 10px;
  border-bottom: 1px solid ${props => props.theme.border};
  border-top: 1px solid ${props => props.theme.border};
  a {
    font-size: ${Fonts.sizeSuperTiny};
    font-weight: ${Fonts.weightRegular};
    font-family: ${Fonts.text};
    color: ${props => props.theme.color};
    padding: 10px 50px 10px 10px;
    &:hover {
      color: ${Colors.blueberry};
    }
  }
`;

const ThemeContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 10px;
  border-bottom: 1px solid ${props => props.theme.border};
`;

const Theme = styled.div`
  cursor: pointer;
  background: ${props => props.color};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${Colors.gray};
  margin: 0 5px;
`;

const Logout = styled.div`
  display: flex;
  flex-flow: column;
  padding: 10px;
  a {
    font-size: ${Fonts.sizeSuperTiny};
    font-weight: ${Fonts.weightRegular};
    font-family: ${Fonts.text};
    color: ${props => props.theme.color};
    padding: 10px 50px 10px 10px;
    &:hover {
      color: ${Colors.blueberry};
    }
  }
`;

const ADMIN = 'admin';

const User = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(state => state.profile);
  const { ref, visible, setVisible } = useComponentVisible(false);

  if (!userInfo) {
    return null;
  }
  const initials = userInfo.firstName.charAt(0) + userInfo.lastName.charAt(0);
  const name = `${userInfo.firstName.charAt(0).toUpperCase() + userInfo.firstName.slice(1)} ${userInfo.lastName
    .charAt(0)
    .toUpperCase()}${userInfo.lastName.slice(1)}`;

  return (
    <Container active={visible} ref={ref}>
      <Profile active={visible} onClick={() => setVisible(e => !e)}>
        {userInfo.image === null ? <Initials>{initials}</Initials> : <Image src={userInfo.image} />}
      </Profile>
      <Dropdown active={visible}>
        <Username>
          <b>{name}</b>
          <i>{userInfo.username}</i>
        </Username>
        <Options>
          <Link to="/profile" onClick={() => setVisible(false)}>
            Profil
          </Link>
          {[ADMIN].includes(userInfo.role) && (
            <Link to="/settings" onClick={() => setVisible(false)}>
              Inställningar
            </Link>
          )}
        </Options>
        <ThemeContainer>
          <Theme
            color={Themes.light.body}
            onClick={() => {
              dispatch(setTheme(Themes.light));
              setVisible(false);
            }}
          />
          <Theme
            color={Themes.dark.body}
            onClick={() => {
              dispatch(setTheme(Themes.dark));
              setVisible(false);
            }}
          />
        </ThemeContainer>
        <Logout>
          <Link
            to="/"
            onClick={() => {
              logout();
              setVisible(false);
            }}
          >
            Logga ut
          </Link>
        </Logout>
      </Dropdown>
    </Container>
  );
};

export default User;
