import { Themes } from 'Res/stylesheets/variables';

import { LOAD_SESSION, SET_THEME } from './actions';

const lsTheme = window.localStorage.getItem('theme');

const initialState = {
  sessionToken: null,
  theme: lsTheme ? JSON.parse(lsTheme) : Themes.light
};

const session = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SESSION:
      return {
        ...state,
        sessionToken: window.localStorage.getItem('sessionToken')
      };

    case SET_THEME:
      localStorage.setItem('theme', JSON.stringify(action.payload.theme));
      return { ...state, theme: action.payload.theme };
    default:
      return state;
  }
};

export default session;
