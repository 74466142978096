import React from 'react';
import styled from 'styled-components';
import { Media } from 'fowlit';
import { useSelector } from 'react-redux';
import { Welcome } from '../Components/Information';
import List from '../Components/List';

const Container = styled.div`
  padding: 35px;
  display: grid;
  grid-gap: 25px;
  grid-auto-flow: row dense;
  grid-template-columns: repeat(auto-fill, 25px);
  grid-template-rows: repeat(auto-fill, 25px);
  grid-auto-column: 25px;
  grid-auto-rows: 25px;

  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-auto-flow: column wrap;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 25px);
    grid-template-rows: repeat(auto-fit, 25px);
  }
`;

const Home = () => {
  const { userInfo } = useSelector(state => state.profile);
  const latestBookings = useSelector(state => state.bookings.latest).bookings.slice(0, 10);
  const todaysBookings = useSelector(state => state.bookings.today).bookings.slice(0, 10);
  return (
    <main>
      <Container>
        <Welcome
          header={`Välkommen ${userInfo.firstName.charAt(0).toUpperCase() + userInfo.firstName.slice(1)}!`}
          text="Här får du en översikt över vad som pågår i de olika modulerna. Samt vilka som checkar in under dagen och de senaste bokningarna."
        />
        <List
          spec={{
            label: 'Senaste bokningarna',
            bookings: latestBookings.map(e => ({ ...e, type: 'camp' }))
          }}
        />
        <List
          spec={{
            label: 'Dagens bokningar',
            bookings: todaysBookings.map(e => ({ ...e, type: 'camp' }))
          }}
        />
      </Container>
    </main>
  );
};

export default Home;