import { darken, lighten } from 'polished';

export const Fonts = {
  // font-size
  sizeSuperTiny: '0.9rem',
  sizeTiny: '1.1rem',
  sizeSmall: '1.4rem',
  sizeRegular: '2rem',
  sizeLarge: '3rem',
  sizeXLarge: '3.5rem',

  // font-weight
  weightLight: 300,
  weightRegular: 400,
  weightBold: 700,

  // font-family
  head: "'Montserrat Alternates', 'sans-serif'",
  text: "'Open sans', 'sans-serif'",
  mono: "'Source Code Pro', 'monospace'"
};

// Media Queries: https://css-tricks.com/naming-media-queries/
export const Media = {
  phone: '650px',
  tablet: '1250px',
  computer: '1600px'
};

export const Colors = {
  white: '#fdfdfd',
  whiteLighter: '#ffffff',
  whiteDarker: '#f2f2f2',
  black: '#222222',
  blackLighter: '#333333',
  blackDarker: '#111111',
  gray: '#575757',
  grayLighter: '#707070',
  grayDarker: darken(0.15, '#575757'),
  blueberry: '#4897d8',
  blueberryLighter: lighten(0.15, '#4897d8'),
  blueberryDarker: darken(0.15, '#4897d8'),
  kiwi: '#5fad56',
  kiwiLighter: lighten(0.15, '#5fad56'),
  kiwiDarker: darken(0.15, '#5fad56'),
  strawberry: '#fa6e59',
  strawberryLighter: lighten(0.15, '#fa6e59'),
  strawberryDarker: darken(0.15, '#fa6e59'),
  physalis: '#f8a055',
  physalisLighter: lighten(0.15, '#f8a055'),
  physalisDarker: darken(0.15, '#f8a055'),
  banana: '#ffd85c',
  bananaLighter: lighten(0.15, '#ffd85c'),
  bananaDarker: darken(0.15, '#ffd85c'),
  ice: '#F5F7FF',
  iceLighter: lighten(0.15, '#F5F7FF'),
  iceDarker: darken(0.15, '#F5F7FF')
};

export const Dimensions = {
  navHeight: '70px',
  radius: '15px'
};

export const Regexp = {
  username: `[A-Za-z\\d]+`,
  password:
    `[A-Za-z\\d\\\\!\\\\"\\\\#\\\\$\\\\%\\\\&\\\\'\\\\(\\\\)\\\\*\\\\+\\\\,\\\\-\\\\!\\\\"\\\\#\\\\$\\\\%\\\\&\\\\'\\\\(\\\\)\\\\*\\\\+\\\\,\\\\.\\\\!\\\\"\\\\#\\\\$\\\\%\\\\&\\\\'\\\\(\\\\)\\\\*\\\\+\\\\,\\\\/\\\\:\\\\;\\\\<\\\\=\\\\>\\\\?\\\\@\\\\[\\]\\\\^\\\\_` +
    '\\\\`' +
    `\\\\{\\\\|\\\\}\\\\~]+`
};

export const Themes = {
  dark: {
    body: Colors.blackDarker,
    main: Colors.black,
    background: Colors.blackDarker,
    backgroundHover: Colors.blackLighter,
    color: Colors.white,
    subColor: Colors.whiteDarker,
    border: Colors.blackLighter,
    navBackground: Colors.blackDarker,
    navColor: Colors.white,
    navLogo: Colors.white,
    navActive: Colors.blueberry,
    navBorder: Colors.blackLighter,
    searchbarBackground: Colors.blackDarker,
    searchbarColor: Colors.white,
    searchbarHover: Colors.black,
    searchbarActive: Colors.blueberry,
    searchbarBorder: Colors.blackLighter
  },
  light: {
    body: Colors.white,
    main: Colors.ice,
    background: Colors.white,
    backgroundHover: Colors.whiteDarker,
    color: Colors.black,
    subColor: Colors.gray,
    border: Colors.whiteDarker,
    navBackground: Colors.white,
    navColor: Colors.grayLighter,
    navLogo: Colors.blueberry,
    navActive: Colors.blueberry,
    navBorder: Colors.whiteDarker,
    searchbarBackground: Colors.white,
    searchbarColor: Colors.gray,
    searchbarHover: Colors.whiteDarker,
    searchbarActive: Colors.blueberry,
    searchbarBorder: Colors.whiteDarker
  }
};
