import React from 'react';
import ReactDOM from 'react-dom';
import GlobalStyle from 'Res/stylesheets/site';
import { ThemeProvider } from 'styled-components';
import { Provider, useSelector } from 'react-redux';
import Errors from 'Components/Errors';
import App from 'App';
import UnauthRouter from 'UnauthRouter';
import { ApolloProvider } from '@apollo/react-hooks';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { loadSession } from './Reducers/Session/actions';
import client from './Apollo';
import rootReducer from './Reducers';

const store = createStore(rootReducer, applyMiddleware(thunk));
const { dispatch } = store;

const Index = () => {
  const { sessionToken, theme } = useSelector(state => state.session);

  return (
    <ApolloProvider client={client}>
      <GlobalStyle theme={theme} />
      <ThemeProvider theme={theme}>
        <>
          {sessionToken === null ? <UnauthRouter /> : <App />}
          <Errors />
        </>
      </ThemeProvider>
    </ApolloProvider>
  );
};

const Wrapper = () => {
  dispatch(loadSession());
  return (
    <Provider store={store}>
      <Index />
    </Provider>
  );
};

ReactDOM.render(<Wrapper />, document.getElementById('root'));
