export const INIT_USERS = 'INIT_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_USER = 'ADD_USER';
export const DEL_USER = 'DEL_USER';
export const ERR_USERS = 'ERR_USERS';
export const LOADING_USERS = 'LOADING_USERS';

export const errorUsers = error => ({
  type: ERR_USERS,
  payload: {
    error
  }
});

export const loadingUsers = loading => ({
  type: LOADING_USERS,
  payload: {
    loading
  }
});

export const initUsers = users => ({
  type: INIT_USERS,
  payload: {
    users
  }
});

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: {
    user
  }
});

export const addUser = user => ({
  type: ADD_USER,
  payload: {
    user
  }
});

export const deleteUser = user => ({
  type: DEL_USER,
  payload: {
    user
  }
});
