import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Dimensions } from 'fowlit';
import { useSelector } from 'react-redux';

const Container = styled.div`
  z-index: 10001;
  position: fixed;
  top: ${Dimensions.navHeight};
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  width: ${Dimensions.navHeight};
  border-right: 2px solid ${props => props.theme.navBorder};
  background: ${props => props.theme.navBackground};

  a {
    display: block;
    text-align: center;
    font-size: 25px;
    line-height: ${Dimensions.navHeight};
    color: ${props => props.theme.navColor};

    &:hover,
    &.active {
      opacity: 1;
      color: ${props => props.theme.navActive};
    }
  }
`;

const ADMIN = 'admin';
const WORKER = 'worker';

const Sidebar = () => {
  const { userInfo } = useSelector(state => state.profile);
  return (
    <Container>
      {[ADMIN, WORKER].includes(userInfo.role) && <NavLink exact to="/" className="icon-dashboard" />}
      {[ADMIN, WORKER].includes(userInfo.role) && <NavLink exact to="/camping" className="icon-camp" />}
      {[ADMIN, WORKER].includes(userInfo.role) && <NavLink exact to="/bookings?page=1" className="icon-book" />}
      {[ADMIN].includes(userInfo.role) && <NavLink exact to="/users" className="icon-users" />}
      {[ADMIN].includes(userInfo.role) && <NavLink exact to="/housings" className="icon-home" />}
    </Container>
  );
};

export default Sidebar;
