import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Dimensions, Fonts, Regexp } from 'fowlit';
import { useLazyQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { Input } from '../Forms';
import { addErr } from '../../Reducers/Error/actions';

const Container = styled.div`
  background: ${props => props.theme.body};
  color: ${props => props.theme.color};
  border: 2px solid ${props => props.theme.border};
  border-radius: ${Dimensions.radius};
  display: grid;
  padding: 30px 20px;
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-gap: 30px 0;
  align-items: flex-start;
`;

const InfoCell = styled.div`
  padding: 0 10px;
  i {
    font-family: ${Fonts.text};
    font-size: 0.8rem;
  }
`;

const Edit = styled.div`
  display: grid;
  grid-template-columns: 300px 300px;
  grid-template-rows: repeat(6, 50px);
  grid-gap: 10px 20px;
  grid-auto-flow: column;
`;

const GET_COMPANY = gql`
  query($organizationNr: String!) {
    getCompany(organizationNr: $organizationNr) {
      id
      name
      phone
      email
      lastPurchase
      address
      zipCode
      city
      country
      organizationNr
      reference
    }
  }
`;

const BookingInfo = ({ data, edit }) => {
  const [getCompany, { data: companyData, loading, error }] = useLazyQuery(GET_COMPANY);
  const dispatch = useDispatch();
  const name = data.customer.firstName
    ? `${data.customer.firstName.charAt(0).toUpperCase() +
        data.customer.firstName.slice(1)} ${data.customer.lastName
        .charAt(0)
        .toUpperCase()}${data.customer.lastName.slice(1)}`
    : data.customer.name.charAt(0).toUpperCase() + data.customer.name.slice(1);
  const customerAddress = `${data.customer.address.charAt(0).toUpperCase() + data.customer.address.slice(1)}`;
  const customerCity = `${data.customer.city.charAt(0).toUpperCase() + data.customer.city.slice(1)}`;
  const customerCountry = `${data.customer.country.charAt(0).toUpperCase() + data.customer.country.slice(1)}`;

  // Edit Mode
  const [org, setOrg] = useState(data.customer.organizationNr);

  // Person
  const [firstName, setFirstName] = useState(data.customer.firstName);
  const [lastName, setLastName] = useState(data.customer.lastName);

  // Company
  const [companyName, setCompanyName] = useState(data.customer.name);
  const [refrence, setReference] = useState(data.customer.reference);

  // Info
  const [email, setEmail] = useState(data.customer.email);
  const [phone, setPhone] = useState(data.customer.phone);
  const [comment, setComment] = useState(data.comment);

  // Adress
  const [address, setAddress] = useState(data.customer.address);
  const [zip, setZip] = useState(data.customer.zipCode);
  const [city, setCity] = useState(data.customer.city);
  const [country, setCountry] = useState(data.customer.country);

  useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(addErr(`Hittade inte företaget ${org}.`));
      } else if (companyData) {
        const c = companyData.getCompany;
        setCompanyName(c.name);
        setAddress(c.address);
        setZip(c.zipCode);
        setCity(c.city);
        setCountry(c.country);

        setEmail(c.email);
        setPhone(c.phone);
        setReference(c.reference);
      }
    }
  }, [companyData, dispatch, error, loading, org]);

  return (
    <Container>
      {edit ? (
        <Edit>
          {data.customer.firstName ? (
            <Input
              key="firstName"
              label="Förnamn"
              type="text"
              name="firstName"
              value={firstName}
              setValue={e => setFirstName(e)}
              required
              pattern={Regexp.name}
              minLength={1}
              maxLength={50}
            />
          ) : (
            <Input
              key="org"
              label="Organisationsnummer"
              type="text"
              name="organizationNr"
              value={org}
              setValue={e => setOrg(e)}
              required
              button={() =>
                getCompany({
                  variables: {
                    organizationNr: org
                  }
                })
              }
              pattern="^[0-9]+[-]+[0-9]+"
              minLength={1}
              maxLength={10}
            />
          )}
          {data.customer.firstName ? (
            <Input
              key="lastName"
              label="Efternamn"
              type="text"
              name="lastName"
              value={lastName}
              setValue={e => setLastName(e)}
              required
              pattern={Regexp.name}
              minLength={1}
              maxLength={50}
            />
          ) : (
            <Input
              key="companyName"
              label="Namn"
              type="text"
              name="companyName"
              value={companyName}
              setValue={e => setCompanyName(e)}
              pattern={Regexp.name}
              minLength={1}
              maxLength={50}
              required
            />
          )}
          {data.customer.name && (
            <Input
              key="reference"
              label="Referens"
              type="text"
              name="reference"
              value={refrence}
              setValue={e => setReference(e)}
              pattern={Regexp.name}
              minLength={1}
              maxLength={100}
              required
            />
          )}
          <Input label="E-Mail" type="email" name="email" value={email} setValue={e => setEmail(e)} required />
          <Input label="Telefon" type="phone" name="phone" value={phone} setValue={e => setPhone(e)} required />
          <Input
            label="Kommentar"
            type="text"
            name="comment"
            value={comment}
            setValue={e => setComment(e)}
            maxValue={300}
          />
          {data.customer.firstName && <div />}
          <Input label="Adress" type="text" name="address" value={address} setValue={e => setAddress(e)} required />
          <Input
            label="Postnummer"
            type="text"
            name="zipCode"
            value={zip}
            setValue={e => setZip(e)}
            minLength={1}
            maxLength={5}
            required
          />
          <Input
            label="Ort"
            type="text"
            name="city"
            value={city}
            setValue={e => setCity(e)}
            pattern={Regexp.name}
            required
          />
          <Input
            label="Land"
            type="text"
            name="country"
            value={country}
            setValue={e => setCountry(e)}
            pattern={Regexp.name}
            required
          />
        </Edit>
      ) : (
        <Info>
          <InfoCell>
            <i>Kund</i>
            <h6>{name}</h6>
            {data.customer.organizationNr && <h6>{data.customer.organizationNr.toUpperCase()}</h6>}
          </InfoCell>
          {data.customer.name && (
            <InfoCell>
              <i>Referens</i>
              <h6>{data.customer.reference}</h6>
            </InfoCell>
          )}
          <InfoCell>
            <i>Telefon</i>
            <h6>{data.customer.phone}</h6>
          </InfoCell>
          <InfoCell>
            <i>E-Mail</i>
            <h6>{data.customer.email}</h6>
          </InfoCell>
          {data.customer.firstName && <InfoCell />}
          <InfoCell>
            <i>Adress</i>
            <h6>{customerAddress}</h6>
            <h6>{`${data.customer.zipCode} ${customerCity}`}</h6>
            <h6>{customerCountry}</h6>
          </InfoCell>
          <InfoCell>
            <i>Kommentar</i>
            <h6>{data.comment}</h6>
          </InfoCell>
        </Info>
      )}
    </Container>
  );
};

BookingInfo.propTypes = {
  data: PropTypes.shape({
    comment: PropTypes.string,
    customer: PropTypes.shape({
      organizationNr: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      address: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      reference: PropTypes.string
    })
  }).isRequired,
  edit: PropTypes.bool.isRequired
};

export default BookingInfo;
