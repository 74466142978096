import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { Dimensions, Colors, Fonts } from 'fowlit';
import { rgba } from 'Library';
import Camping from 'Res/Assets/Svg/camping.svg';

const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Box = styled.div`
  background: ${props => props.theme.body};
  color: ${props => props.theme.color};
  border-radius: ${Dimensions.radius};
  border: 2px solid ${props => props.theme.border};
  width: 600px;
  height: 500px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  font-size: ${Fonts.sizeSuperTiny};
  font-family: ${Fonts.text};
  position: relative;
`;

const Icon = styled.i`
  color: ${props => props.color};
  font-size: 35px;
  background: ${props => rgba(0.3, props.color)};
  border-radius: 50%;
  border: 2px solid ${props => rgba(0.1, props.color)};
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 500ms ease-in-out;
  margin-bottom: 15px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 60px 0 0 0;
`;

const Button = styled(Link)`
  display: block;
  background: ${Colors.blueberry};
  margin: 0 20px;
  color: ${Colors.white};
  font-size: ${Fonts.sizeSuperTiny};
  padding: 10px 20px;
  border-radius: 5px;
`;

const Title = styled.h5`
  margin: 10px 0 5px 0;
`;

const SubTitle = styled.i``;

const Image = styled.div`
  background: url(${props => props.src}) no-repeat;
  background-size: cover;
  width: 300px;
  height: 100px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const Confirmation = () => {
  const { history } = useReactRouter();
  if (new URL(window.location).searchParams.get('refrenceID') === null) {
    history.push('/');
  }
  return (
    <Container>
      <Box>
        <Icon className="icon-check" color={Colors.kiwi} />
        <Title>Din bokning är nu slutförd!</Title>
        <SubTitle>
          {`Bokningsreferens ${new URL(window.location).searchParams.get('refrenceID').toUpperCase()}`}
        </SubTitle>
        <Buttons>
          <Button to="/">Gå till hem</Button>
          <Button to={`/bookingReference?referenceID=${new URL(window.location).searchParams.get('refrenceID')}`}>
            Se bokning
          </Button>
        </Buttons>
        <Image src={Camping} />
      </Box>
    </Container>
  );
};

export default Confirmation;
