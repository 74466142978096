export const INIT_TODAY_BOOKINGS = 'INIT_TODAY_BOOKINGS';
export const UPDATE_TODAY_BOOKING = 'UPDATE_TODAY_BOOKING';
export const ADD_TODAY_BOOKING = 'ADD_TODAY_BOOKING';
export const DEL_TODAY_BOOKING = 'DEL_TODAY_BOOKING';
export const ERR_TODAY_BOOKING = 'ERR_TODAY_BOOKING';
export const LOADING_TODAY_BOOKING = 'LOADING_TODAY_BOOKING';

export const errorTodayBooking = error => ({
  type: ERR_TODAY_BOOKING,
  payload: {
    error
  }
});

export const loadingTodayBooking = loading => ({
  type: LOADING_TODAY_BOOKING,
  payload: {
    loading
  }
});

export const initTodayBookings = bookings => ({
  type: INIT_TODAY_BOOKINGS,
  payload: {
    bookings
  }
});

export const updateTodayBooking = booking => ({
  type: UPDATE_TODAY_BOOKING,
  payload: {
    booking
  }
});

export const addTodayBooking = booking => ({
  type: ADD_TODAY_BOOKING,
  payload: {
    booking
  }
});

export const deleteTodayBooking = booking => ({
  type: DEL_TODAY_BOOKING,
  payload: {
    booking
  }
});
