import {
  INIT_LATEST_BOOKINGS,
  UPDATE_LATEST_BOOKING,
  ADD_LATEST_BOOKING,
  DEL_LATEST_BOOKING,
  ERR_LATEST_BOOKING,
  LOADING_LATEST_BOOKING
} from './actions';

const initValue = {
  bookings: [],
  loading: false,
  error: null
};

const latest = (state = initValue, action) => {
  switch (action.type) {
    case ERR_LATEST_BOOKING: {
      state.error = action.payload.error;
      return { ...state };
    }

    case LOADING_LATEST_BOOKING: {
      state.loading = action.payload.loading;
      return { ...state };
    }

    case INIT_LATEST_BOOKINGS: {
      const { bookings } = action.payload;
      state.bookings = bookings ? [...bookings] : [];
      return { ...state };
    }

    case UPDATE_LATEST_BOOKING: {
      const { booking } = action.payload;
      state.bookings = state.bookings.filter(e => e.referenceID !== booking.referenceID);
      state.bookings = [booking, ...state.bookings];
      return { ...state };
    }

    case ADD_LATEST_BOOKING: {
      const { booking } = action.payload;
      state.bookings = [booking, ...state.bookings];
      return { ...state };
    }

    case DEL_LATEST_BOOKING: {
      const { booking } = action.payload;
      state.bookings = state.bookings.filter(e => e.referenceID !== booking.referenceID);
      return { ...state };
    }

    default:
      return state;
  }
};

export default latest;
