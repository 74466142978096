import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { errorCurrentBooking, initCurrentBookings, loadingCurrentBooking } from './Reducers/Bookings/Current/actions';
import { errorTodayBooking, initTodayBookings, loadingTodayBooking } from './Reducers/Bookings/Today/actions';

const fragments = `
fragment PersonParts on Person {
  firstName
  lastName
  email
  phone
  address
  zipCode
  city
  country
  lastPurchase
}

fragment CompanyParts on Company {
  organizationNr
  reference
  email
  phone
  address
  zipCode
  city
  country
  lastPurchase
  name
}
`;

const bookingValues = `
referenceID
price
userID
date
discount
bookings {
  id
  name
  price
  discount
  itemID
  itemType
  startTime
  endTime
}
customer {
  ...PersonParts
  ...CompanyParts
}
`;

const GET_TODAYS_BOOKINGS = gql`
    ${fragments}

    {
        getTodaysNewBookings {
            ${bookingValues}
        }
    }
`;

const GET_CURRENT_BOOKINGS = gql`
    ${fragments}
     {
        getCurrentBookings {
            ${bookingValues}
        }
    }
`;

const Updater = () => {
  const [getCurrentBookings, gcb] = useLazyQuery(GET_CURRENT_BOOKINGS);
  const [getTodaysBookings, gtb] = useLazyQuery(GET_TODAYS_BOOKINGS);
  const { checkIn } = useSelector(state => state.settings.camping);
  const dispatch = useDispatch();

  const [updateCurrent, setUpdateCurrent] = useState(false);
  const [updateToday, setUpdateToday] = useState(false);

  useEffect(() => {
    const { data, loading, error } = gcb;
    if (loading) {
      dispatch(loadingCurrentBooking(true));
    } else if (error) {
      dispatch(errorCurrentBooking(error));
    } else if (data) {
      dispatch(loadingCurrentBooking(false));

      dispatch(initCurrentBookings(data.getCurrentBookings));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, gcb.data]);

  useEffect(() => {
    const { data, loading, error } = gtb;
    if (loading) {
      dispatch(loadingTodayBooking(true));
    } else if (error) {
      dispatch(errorTodayBooking(error));
    } else if (data) {
      dispatch(loadingTodayBooking(false));

      dispatch(initTodayBookings(data.getTodaysNewBookings));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, gtb.data]);

  useEffect(() => {
    const ci = checkIn.split(':').map(e => parseInt(e, 10));
    if (ci) {
      const now = new Date();
      let timeLeftToCheckIn = new Date(now.getFullYear(), now.getMonth(), now.getDate(), ci[0], ci[1], 0, 0) - now;
      if (timeLeftToCheckIn < 0) {
        const now2 = new Date();
        now.setDate(now.getDate() + 1);
        timeLeftToCheckIn = new Date(now.getFullYear(), now.getMonth(), now.getDate(), ci[0], ci[1], 0, 0) - now2;
      }
      setTimeout(() => {
        getCurrentBookings();
        setUpdateCurrent(e => !e);
      }, timeLeftToCheckIn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIn, updateCurrent]);

  useEffect(() => {
    const now = new Date();
    let timeLeftToNewDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0) - now;
    if (timeLeftToNewDay < 0) {
      const now2 = new Date();
      now.setDate(now.getDate() + 1);
      timeLeftToNewDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0) - now2;
    }
    setTimeout(() => {
      getTodaysBookings();
      setUpdateToday(e => !e);
    }, timeLeftToNewDay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateToday]);

  return <></>;
};

export default Updater;
