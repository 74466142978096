import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Colors, Dimensions, Fonts } from 'fowlit';
import { useDispatch } from 'react-redux';
import { addProduct, deleteProduct } from 'Reducers/Basket/actions';

const Container = styled.div`
  grid-column: span 5;
  grid-row: span 7;
  display: grid;
  grid-template-columns: repeat(auto-fill, 25px);
  grid-template-rows: repeat(auto-fill, 25px);
`;

const Background = styled.div`
  grid-column: 1 / auto-fill;
  grid-row: 1 / span 6;
  background-image: ${props => `url(${props.img})`}});
  background-repeat; no-repeat;
  background-size: cover;
  background-position: bottom;
  border-radius: ${Dimensions.radius} ${Dimensions.radius} 0 0;
  border: 2px solid ${props => props.theme.border};
`;

const Initials = styled.div`
  background: linear-gradient(to bottom left, ${Colors.blueberry}, ${Colors.blueberryDarker});
  color: ${Colors.white};
  grid-column: 1 / auto-fill;
  grid-row: 1 / span 6;
  border-radius: ${Dimensions.radius} ${Dimensions.radius} 0 0;
  border: 2px solid ${props => props.theme.border};
  text-transform: uppercase;
  font-family: ${Fonts.head};
  font-size: ${Fonts.sizeSmall};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Info = styled.div`
  grid-column: 1 / auto-fill;
  grid-row: 6 / span 6;
  border-radius: ${Dimensions.radius} ${Dimensions.radius} 5px 5px;
  margin-bottom: 3px;
  background: ${props => props.theme.body};
  border: 2px solid ${props => props.theme.border};
  padding: 15px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  transition: 500ms ease-out;

  hr {
    margin: 0 5px;
    opacity: 0.1;
  }

  span {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: baseline;
    p {
      font-size: 0.8rem;
      font-weight: ${Fonts.weightRegular};
      font-family: ${Fonts.text};
      color: ${props => props.theme.color};
    }

    i {
      margin-right: 15px;
      color: ${props => props.theme.color};
    }
  }
  &:hover {
    margin-top: -20px;
  }
`;

const Name = styled.h5`
  color: ${props => props.theme.color};
  margin-bottom: 5px;
`;

const Details = styled.div`
  grid-column: 1 / span 7;
  grid-row: span 2;
  border-radius: 5px 5px 5px ${Dimensions.radius};
  margin-right: 3px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 20px;
  background: ${props => props.theme.body};
  color: ${props => props.theme.color};
  border: 2px solid ${props => props.theme.border};
`;

const Add = styled.div`
  grid-column: span 2;
  grid-row: span 2;
  border-radius: 5px 5px ${Dimensions.radius} 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  background: ${props => props.theme.body};
  border: 2px solid ${props => props.theme.border};
  color: ${props => (!props.isAdded ? Colors.blueberry : Colors.strawberry)};
  cursor: pointer;
`;

const Row = ({ icon, label, value, unit }) => {
  return (
    <span>
      <p>
        <i className={icon} />
        {label}
      </p>
      <p>
        <b>{value}</b>
        {` ${unit}`}
      </p>
    </span>
  );
};

const Residence = ({ spec, info }) => {
  const dispatch = useDispatch();
  const [added, setAdded] = useState(false);
  const [id, setID] = useState(null);

  const GenerateDetails = () => {
    const details = [];
    if (spec.wifi) {
      details.push(<i key="wifi" className="icon-wifi" />);
    }
    if (spec.kitchen) {
      details.push(<i key="kitchen" className="icon-kitchen" />);
    }
    if (spec.electricity) {
      details.push(<i key="electricity" className="icon-electricity" />);
    }
    if (spec.tv) {
      details.push(<i key="tv" className="icon-tv" />);
    }
    return details;
  };

  const GenerateRow = () => {
    switch (spec.type) {
      case 'cabin':
        return <Row icon="icon-bed" label="Bäddar" value={spec.beds} unit="st" />;
      case 'hostel':
        return <Row icon="icon-bed" label="Bäddar" value={spec.beds} unit="st" />;
      case 'room':
        return <Row icon="icon-bed" label="Bäddar" value={spec.beds} unit="st" />;
      case 'caravan':
        return <Row icon="icon-home" label="Storlek" value={spec.size} unit={'m\u00b2'} />;
      case 'tent':
        return <Row icon="icon-home" label="Storlek" value={spec.size} unit={'m\u00b2'} />;
      default:
        return <></>;
    }
  };

  const addOrRemoveFromBasket = () => {
    if (!added) {
      dispatch(addProduct(info, setAdded, setID));
    } else {
      dispatch(deleteProduct(id, v => setAdded(!v), setID));
    }
  };

  return (
    <Container>
      {spec.image ? <Background img={spec.image} /> : <Initials />}
      <Info>
        <Name>{spec.name}</Name>
        <GenerateRow />
        <hr />
        <Row icon="icon-tags" label="Pris" value={parseInt(spec.price, 10) / 10000} unit="kr" />
      </Info>
      <Details>
        <GenerateDetails />
      </Details>
      <Add isAdded={added} className={!added ? 'icon-plus' : 'icon-minus'} onClick={() => addOrRemoveFromBasket()} />
    </Container>
  );
};

Row.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired
};

Residence.propTypes = {
  spec: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    beds: PropTypes.number,
    size: PropTypes.number,
    price: PropTypes.string.isRequired,
    wifi: PropTypes.bool,
    tv: PropTypes.bool,
    electricity: PropTypes.bool,
    kitchen: PropTypes.bool,
    image: PropTypes.string
  }).isRequired,
  info: PropTypes.shape({
    housingID: PropTypes.string.isRequired,
    startTime: PropTypes.objectOf(Date).isRequired,
    endTime: PropTypes.objectOf(Date).isRequired
  }).isRequired
};

export default Residence;
