import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Dimensions, Colors, Fonts, Regexp } from 'fowlit';
import { Input } from 'Components/Forms';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useDispatch } from 'react-redux';
import { addErr } from '../../Reducers/Error/actions';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 34px 1fr;
  grid-template-areas: 'tabs tabs' 'information address';
  background: ${props => props.theme.body};
  color: ${props => props.theme.color};
  border: 2px solid ${props => props.theme.border};
  border-radius: ${Dimensions.radius};
  padding: 30px 40px;
  grid-gap: 20px;
`;

const Tabs = styled.div`
  grid-area: tabs;
  display: flex;
  flex-flow: row;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: ${props => (props.active ? Colors.white : props.theme.color)};
  background: ${props => (props.active ? Colors.blueberry : 'transparent')}
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeSuperTiny};
  user-select: none;
  border-radius: 17px;
  cursor: pointer;
  transition: 300ms ease-out;
  margin-right: 15px;
`;

const Information = styled.div`
  grid-area: information;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px repeat(auto-fill, 50px);
  grid-gap: 10px;
`;

const Address = styled.div`
  grid-area: address;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px repeat(auto-fill, 50px);
  grid-gap: 10px;
`;

// TODO: Fixa regex för.

const GET_COMPANY = gql`
  query($organizationNr: String!) {
    getCompany(organizationNr: $organizationNr) {
      id
      name
      phone
      email
      lastPurchase
      address
      zipCode
      city
      country
      organizationNr
      reference
    }
  }
`;

const Form = () => {
  const [getCompany, { data, loading, error }] = useLazyQuery(GET_COMPANY);
  const [customerType, setCustomerType] = useState('person');
  const dispatch = useDispatch();

  const [org, setOrg] = useState('');

  // Person
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  // Company
  const [companyName, setCompanyName] = useState('');
  const [refrence, setReference] = useState('');

  // Info
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [comment, setComment] = useState('');

  // Adress
  const [address, setAddress] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');

  // Refs
  const firstNameEl = useRef('');
  const referenceEl = useRef('');
  const orgEl = useRef('');

  useEffect(() => {
    if (customerType === 'person') {
      firstNameEl.current.focus();
    } else if (customerType === 'company') {
      orgEl.current.focus();
    }
  }, [customerType]);

  useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(addErr(`Hittade inte företaget med org.nr ${orgEl.current.value}.`));
      } else if (data) {
        if (data.getCompany) {
          const c = data.getCompany;

          setCompanyName(c.name);
          setAddress(c.address);
          setZip(c.zipCode);
          setCity(c.city);
          setCountry(c.country);

          setEmail(c.email);
          setPhone(c.phone);
          setReference(c.reference);
          referenceEl.current.focus();
        } else {
          dispatch(addErr(`Hittade inte företaget med org.nr ${orgEl.current.value}.`));
        }
      }
    }
  }, [data, dispatch, error, loading]);

  return (
    <Container>
      <input type="text" name="customerType" value={customerType} onChange={e => setCustomerType(e)} hidden required />
      <Tabs>
        <Tab active={customerType === 'person'} type="button" onClick={() => setCustomerType('person')}>
          Privat person
        </Tab>
        <Tab active={customerType === 'company'} type="button" onClick={() => setCustomerType('company')}>
          Företag
        </Tab>
      </Tabs>
      <Information>
        <h6>Kontaktinformation</h6>
        {customerType === 'person' ? (
          <Input
            key="firstName"
            label="Förnamn"
            type="text"
            name="firstName"
            value={firstName}
            setValue={e => setFirstName(e)}
            required
            pattern={Regexp.name}
            minLength={1}
            maxLength={50}
            ref={firstNameEl}
          />
        ) : (
          <Input
            key="org"
            label="Organisationsnummer"
            type="text"
            name="organizationNr"
            value={org}
            setValue={e => setOrg(e)}
            required
            button={() =>
              getCompany({
                variables: {
                  organizationNr: org
                }
              })
            }
            pattern="^[0-9]+[-]+[0-9]+"
            minLength={11}
            maxLength={11}
            ref={orgEl}
          />
        )}
        {customerType === 'person' ? (
          <Input
            key="lastName"
            label="Efternamn"
            type="text"
            name="lastName"
            value={lastName}
            setValue={e => setLastName(e)}
            required
            pattern={Regexp.name}
            minLength={1}
            maxLength={50}
          />
        ) : (
          <Input
            key="companyName"
            label="Namn"
            type="text"
            name="companyName"
            value={companyName}
            setValue={e => setCompanyName(e)}
            pattern={Regexp.name}
            minLength={1}
            maxLength={50}
            required
          />
        )}
        {customerType === 'person' ? (
          <></>
        ) : (
          <Input
            key="reference"
            label="Referens"
            type="text"
            name="reference"
            value={refrence}
            setValue={e => setReference(e)}
            pattern={Regexp.name}
            minLength={1}
            maxLength={100}
            required
            ref={referenceEl}
          />
        )}
        <Input label="E-Mail" type="email" name="email" value={email} setValue={e => setEmail(e)} required />
        <Input label="Telefon" type="phone" name="phone" value={phone} setValue={e => setPhone(e)} required />
        <Input
          label="Kommentar"
          type="text"
          name="comment"
          value={comment}
          setValue={e => setComment(e)}
          maxLength={300}
        />
      </Information>
      <Address>
        <h6>Adress</h6>
        <Input label="Adress" type="text" name="address" value={address} setValue={e => setAddress(e)} required />
        <Input
          label="Postnummer"
          type="text"
          name="zipCode"
          value={zip}
          setValue={e => setZip(e)}
          minLength={1}
          maxLength={5}
          required
        />
        <Input
          label="Ort"
          type="text"
          name="city"
          value={city}
          setValue={e => setCity(e)}
          pattern={Regexp.name}
          required
        />
        <Input
          label="Land"
          type="text"
          name="country"
          value={country}
          setValue={e => setCountry(e)}
          pattern={Regexp.name}
          required
        />
      </Address>
    </Container>
  );
};

export default Form;
