import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rgba } from '../../Library';
import { Colors, Fonts, Dimensions } from 'Res/stylesheets/variables';

const Container = styled.div`
  grid-column: span 5;
  grid-row: span 5;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: auto 50px;
  grid-template-areas: 'info info' 'to from';
  &:hover {
    i {
      transform: scale(-1.2, 1.2);
    }
  }
`;

const Info = styled.div`
  background: white;
  grid-area: 'info';
  grid-column: 1 / auto-fill;
  margin-bottom: 3px;
  border-radius: ${Dimensions.radius} ${Dimensions.radius} 5px 5px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 25px 15px;

  span {
    text-align: center;
    color: ${Colors.black};
    p {
      font-size: 0.8rem;
      font-weight: ${Fonts.weightRegular};
      font-family: ${Fonts.text};
      color: ${Colors.gray};
    }
  }
`;

const Icon = styled.i`
  color: ${props => props.color};
  font-size: 25px;
  background: ${props => rgba(0.3, props.color)};
  border-radius: 50%;
  border: 2px solid ${rgba(0.2, Colors.white)};
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 500ms ease-in-out;
`;

const From = styled.div`
  background: white;
  grid-area: 'to';
  height: 50px;
  grid-column: span 2;
  margin-right: 1.5px;
  border-radius: 5px 5px 5px ${Dimensions.radius};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: ${Fonts.weightBold};
  font-family: ${Fonts.text};
  color: ${Colors.gray};
`;

const To = styled.div`
  background: white;
  grid-area: 'from';
  height: 50px;
  grid-column: span 2;
  margin-left: 1.5px;
  border-radius: 5px 5px ${Dimensions.radius} 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: ${Fonts.weightBold};
  font-family: ${Fonts.text};
  color: ${Colors.gray};
`;

const options = { day: 'numeric', month: 'short', year: 'numeric' };

const Stat = ({ icon, color, spec }) => {
  // eslint-disable-next-line no-unused-vars
  const [fromDate, setFromDate] = useState(new Date(spec.from).toLocaleDateString('sv-SV', options));
  // eslint-disable-next-line no-unused-vars
  const [toDate, setToDate] = useState(new Date(spec.to).toLocaleDateString('sv-SV', options));
  return (
    <Container>
      <Info>
        <Icon className={icon} color={color} />
        <span>
          <h3>{spec.value}</h3>
          <p>{spec.label}</p>
        </span>
      </Info>
      <From>{fromDate}</From>
      <To>{toDate}</To>
    </Container>
  );
};

Stat.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  spec: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string
  }).isRequired
};

export default Stat;
