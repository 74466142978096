import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Colors, Fonts, Dimensions } from 'Res/stylesheets/variables';

const Container = styled.div`
  grid-column: span 5;
  grid-row: span 4;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  background: ${props => props.theme.body};
  border-radius: ${Dimensions.radius};
  border: 2px solid ${props => props.theme.border};
`;

const Border = styled.div`
  border-radius: 50%;
  padding: 3px;
  transition: 500ms ease-in-out;
  background: ${`linear-gradient(to bottom, ${Colors.blueberry}, ${Colors.physalis}, ${Colors.strawberry});`};
  &:hover {
    background: ${`linear-gradient(to bottom, ${Colors.strawberry}, ${Colors.blueberry}, ${Colors.physalis});`};
    transform: scale(1.1, 1.1);
  }
`;

const Info = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background: ${props => props.theme.body};
  user-select: none;

  h3 {
    color: ${props => props.theme.color};
  }

  p {
    position: relative;
    z-index: 3;
    font-size: 0.8rem;
    font-weight: ${Fonts.weightRegular};
    font-family: ${Fonts.text};
    color: ${props => props.theme.color};
  }
`;

const Circle = ({ spec }) => {
  return (
    <Container>
      <Border>
        <Info>
          <h3>{spec.value}</h3>
          <p>{spec.label}</p>
        </Info>
      </Border>
    </Container>
  );
};

Circle.propTypes = {
  spec: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string
  }).isRequired
};

export default Circle;
