import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useDispatch } from 'react-redux';
import { Colors, Dimensions, Fonts } from 'fowlit';
import { Input, Select, Checkbox } from 'Components/Forms';
import { PopOverForm } from 'Components/PopOver';
import { addErr, addMsg } from '../../Reducers/Error/actions';

const Container = styled.div`
  display: grid;
  grid-template-columns: 100px 650px 250px;
  grid-template-rows: 90px;
  grid-template-areas: 'img info button';
  background: ${props => props.theme.body};
  border: 2px solid ${props => props.theme.border};
  border-radius: ${Dimensions.radius};
  margin-bottom: 10px;
  user-select: none;
  transition: 200ms ease-out;
  &:hover {
    box-shadow: 1px 5px 15px -10px ${Colors.blackDarker};
    margin-top: 5px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
`;

const ImageBox = styled(Box)`
  grid-area: img;
`;

const Initials = styled(Box)`
  background: linear-gradient(to bottom left, ${Colors.blueberry}, ${Colors.blueberryDarker});
  color: ${Colors.white};
  width: 60px;
  height: 60px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 50%;
  text-transform: uppercase;
  font-family: ${Fonts.head};
  font-size: ${Fonts.sizeSmall};
`;

const InfoBox = styled(Box)`
  grid-area: info;
  display: grid;
  grid-template-columns: 1.5fr repeat(4, 1fr);
`;

const Info = styled.div`
  color: ${props => props.theme.color};
  i {
    font-family: ${Fonts.text};
    font-size: 0.8rem;
  }
`;

const ButtonBox = styled(Box)`
  grid-area: button;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const Button = styled.button`
  height: 70%;
  font-size: 22px;
  border: none;
  appearance: none;
  outline: none;
  background: none;
  color: ${props => props.theme.color};
  cursor: pointer;
`;

const Edit = styled(Button)`
  &:hover {
    color: ${Colors.blueberry};
  }
`;

const Delete = styled(Button)`
  border-left: 1px solid ${props => props.theme.border};
  border-right: 1px solid ${props => props.theme.border};
  &:hover {
    color: ${Colors.strawberry};
  }
`;

const Lock = styled(Button)`
  color: ${props => (props.active ? Colors.kiwi : Colors.strawberry)};
  &:hover {
    color: ${Colors.blueberry};
  }
`;

const Image = styled.div`
  background: ${props => `url('${props.url}')`} no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 60px;
  width: 60px;
`;

const Detail = styled.h6`
  display: inline-block;
`;

const EDIT_HOUSING = gql`
  mutation($id: ID!, $housing: NewHousing!) {
    editHousing(id: $id, housing: $housing) {
      id
    }
  }
`;

const types = [
  { type: 'cabin', label: 'Stuga' },
  { type: 'hostel', label: 'Vandrarhem' },
  { type: 'caravan', label: 'Husvagn' },
  { type: 'tent', label: 'Tält' },
  { type: 'room', label: 'Rum' }
];

const Form = styled.form`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 250px 250px;
  grid-gap: 10px;
`;

const ManageHousingForm = ({ formID, formData, close }) => {
  const dispatch = useDispatch();
  const [editHousing, { data, loading, error }] = useMutation(EDIT_HOUSING);
  const [name, setName] = useState(formData.name);
  const [price, setPrice] = useState((parseInt(formData.price, 10) / 10000).toString());
  const [beds, setBeds] = useState(formData.beds ? formData.beds.toString() : '');
  const [type, setType] = useState(types.filter(e => e.type === formData.type)[0]);
  const [size, setSize] = useState(formData.size ? formData.size.toString() : '');
  const [wifi, setWifi] = useState(formData.wifi);
  const [kitchen, setKitchen] = useState(formData.kitchen);
  const [electricity, setElectricity] = useState(formData.electricity);
  const [tv, setTV] = useState(false);

  useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(addErr(`Kunde inte spara ändringar för ${name}.`));
      } else if (data) {
        close();
        dispatch(addMsg(`Sparade ändringar för ${name}.`));
      }
    }
  }, [close, dispatch, data, error, loading, name]);

  return (
    <Form
      id={formID}
      onSubmit={e => {
        e.preventDefault();
        editHousing({
          variables: {
            id: formData.id,
            housing: {
              type: type.type,
              name,
              beds: parseInt(beds, 10),
              size: parseFloat(size),
              price: (parseInt(price, 10) * 10000).toString(),
              wifi,
              kitchen,
              electricity,
              tv,
              active: formData.active
            }
          }
        });
      }}
    >
      <Input label="Namn" type="text" value={name} setValue={e => setName(e)} required minLength={1} maxLength={50} />
      <Input
        label="Pris"
        type="text"
        value={price}
        setValue={e => setPrice(e)}
        required
        pattern="[0-9]+"
        minLength={1}
        maxLength={50}
      />
      <Input
        label="Bäddar"
        type="text"
        value={beds}
        setValue={e => setBeds(e)}
        pattern="[0-9]+"
        minLength={1}
        maxLength={50}
      />
      <Input
        label="Storlek"
        type="text"
        value={size}
        setValue={e => setSize(e)}
        pattern="[0-9]+"
        minLength={1}
        maxLength={50}
      />
      <Select label="Typ" options={types} value={type} setValue={setType} />
      <div />
      <Checkbox name="wifi" label="Har boendet wifi?" value={wifi} setValue={setWifi} />
      <Checkbox name="kitchen" label="Har boendet kök?" value={kitchen} setValue={setKitchen} />
      <Checkbox name="electricity" label="Har boendet el?" value={electricity} setValue={setElectricity} />
      <Checkbox name="tv" label="Har boendet tv?" value={tv} setValue={setTV} />
    </Form>
  );
};

ManageHousingForm.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])).isRequired,
  close: PropTypes.func.isRequired
};

const RemoveHousingForm = ({ formID, formData, close }) => {
  const dispatch = useDispatch();
  const DELETE_HOUSING = gql`
    mutation($id: ID!) {
      deleteHousing(id: $id)
    }
  `;
  const [deleteHousing, { data, loading, error }] = useMutation(DELETE_HOUSING);

  useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(addErr('Kunde inte ansluta till servern.'));
      } else if (data) {
        close();
        dispatch(addMsg('Tog bort bostad.'));
      }
    }
  }, [close, dispatch, data, error, loading]);

  return (
    <form
      id={formID}
      onSubmit={e => {
        e.preventDefault();
        deleteHousing({ variables: { id: formData.id } });
      }}
    />
  );
};

RemoveHousingForm.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])).isRequired,
  close: PropTypes.func.isRequired
};

const Housing = ({ data }) => {
  const dispatch = useDispatch();
  const [editHousing, { data1, loading, error }] = useMutation(EDIT_HOUSING);
  const [removeHousing, setRemoveHousing] = useState(false);
  const [manageHousing, setManageHousing] = useState(false);
  const UPDATE_HOUSING_IMAGE = gql`
    mutation($image: String) {
      updateHousingImage(id: ${data.id}, image: $image) {
        image
      }
    }
  `;
  const DELETE_HOUSING_IMAGE = gql`
    mutation {
      deleteHousingImage(id: ${data.id}) {
        image
      }
    }
  `;

  const GenerateDetails = () => {
    const details = [];
    if (data.wifi) {
      details.push(<Detail key="wifi" className="icon-wifi" />);
    }
    if (data.kitchen) {
      details.push(<Detail key="kitchen" className="icon-kitchen" />);
    }
    if (data.electricity) {
      details.push(<Detail key="electricity" className="icon-electricity" />);
    }
    if (data.tv) {
      details.push(<Detail key="tv" className="icon-tv" />);
    }
    return details;
  };

  const GenerateType = () => {
    switch (data.type) {
      case 'cabin':
        return <i>Stuga</i>;
      case 'caravan':
        return <i>Husvagn</i>;
      case 'hostel':
        return <i>Vandrarhem</i>;
      case 'tent':
        return <i>Tält</i>;
      case 'room':
        return <i>Arena</i>;
      default:
        return <i />;
    }
  };

  useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(addErr(`Kunde inte inaktivera ${data.name}.`));
      } else if (data1) {
        dispatch(addMsg(`${data.name} inaktiverad.`));
      }
    }
  }, [data, data1, dispatch, editHousing, error, loading]);

  const lockHousing = () => {
    data.active = !data.active;
    editHousing({
      variables: {
        id: data.id,
        housing: {
          type: data.type,
          name: data.name,
          beds: data.beds,
          size: data.size,
          price: data.price,
          wifi: data.wifi,
          kitchen: data.kitchen,
          electricity: data.electricity,
          tv: data.tv,
          active: data.active
        }
      }
    });
  };

  return (
    <Container>
      <ImageBox>
        {data.image === undefined || data.image === null ? (
          <Initials type={data.type}>{data.name.charAt(0).toUpperCase()}</Initials>
        ) : (
          <Image url={data.image} />
        )}
      </ImageBox>
      <InfoBox>
        <Info>
          <h6>{data.name}</h6>
          <GenerateType />
        </Info>
        <Info>
          <h6>{`${(parseInt(data.price, 10) / 10000).toLocaleString('sv-SV')} kr`}</h6>
        </Info>
        <Info>
          <h6>{data.beds && `${data.beds} st`}</h6>
        </Info>
        <Info>
          <h6>{data.size && `${data.size} m\u00b2`}</h6>
        </Info>
        <Info>
          <GenerateDetails />
        </Info>
      </InfoBox>
      <ButtonBox>
        <Edit className="icon-edit" onClick={() => setManageHousing(true)} />
        <Delete className="icon-trash" onClick={() => setRemoveHousing(true)} />
        <Lock
          active={data.active}
          className={data.active ? 'icon-lock-open' : 'icon-lock'}
          onClick={() => lockHousing()}
        />
      </ButtonBox>
      {manageHousing && (
        <PopOverForm
          data={{
            id: data.id,
            name: data.name,
            image: data.image,
            initials: data.name.charAt(0),
            title: 'Redigerar bostad',
            text: `Dessa ändringar gäller endast för framtida bokningar. Aktiva bokningar innehållande ${data.name} kommer fortfarande gälla.`,
            submitLabel: 'Spara'
          }}
          Form={ManageHousingForm}
          formID="ManageHousingForm"
          formData={data}
          onCancel={() => setManageHousing(false)}
          editImage={UPDATE_HOUSING_IMAGE}
          deleteImage={DELETE_HOUSING_IMAGE}
        />
      )}
      {removeHousing && (
        <PopOverForm
          data={{
            id: data.id,
            name: data.name,
            image: data.image,
            initials: data.name.charAt(0),
            title: 'Vill du verkligen ta bort denna bostad?',
            text: `Aktiva bokningar innehållande ${data.name} kommer fortfarande gälla. Denna åtgärd går inte att ångra.`,
            submitLabel: 'Ta bort bostad'
          }}
          Form={RemoveHousingForm}
          formID="RemoveHousingForm"
          formData={data}
          onCancel={() => setRemoveHousing(false)}
        />
      )}
    </Container>
  );
};

Housing.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    beds: PropTypes.number,
    size: PropTypes.number,
    price: PropTypes.string.isRequired,
    wifi: PropTypes.bool.isRequired,
    kitchen: PropTypes.bool.isRequired,
    electricity: PropTypes.bool.isRequired,
    tv: PropTypes.bool.isRequired,
    image: PropTypes.string,
    active: PropTypes.bool.isRequired
  }).isRequired
};

Housing.defaultValues = {
  image: ''
};

export default Housing;
