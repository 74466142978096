import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Colors, Dimensions, Fonts, Media } from 'fowlit';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import { ISODateString } from 'Library';
import Select from './Select';
import DatePicker from './DatePicker';
import Quantity from './Quantity';
import { setImage } from '../../Library';

const types = [
  { type: 'cabin', label: 'Stuga' },
  { type: 'room', label: 'Arena' },
  { type: 'hostel', label: 'Vandrarhem' },
  { type: 'caravan', label: 'Husvagn' },
  { type: 'tent', label: 'Tält' }
];

const Container = styled.div`
  display: grid;
  grid-column: 1fr;
  grid-template-columns: 300px 325px 250px 60px;
  grid-template-rows: 70px;
  grid-template-areas: 'type date guest search';
  @media (max-width: ${Media.phone}) {
    grid-template-columns: 1fr;
    grid-template-rows: 60px;
    grid-template-areas: 'type' 'date' 'guest' 'search';
  }
`;

const Button = styled.button`
  color: ${props => props.theme.searchbarActive};
  background: ${props => props.theme.searchbarBackground};
  border: 2px solid ${props => props.theme.searchbarBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${Fonts.sizeTiny};
  border-top-right-radius: ${Dimensions.radius};
  border-bottom-right-radius: ${Dimensions.radius};
  outline: none;
  appearance: none;
  border-left: none;
  cursor: pointer;
  &:disabled {
    color: ${Colors.gray};
  }
`;

const Search = styled(Button)`
  grid-area: search;
`;

const GET_AVAILABLE_HOUSING = gql`
  query($startTime: String!, $endTime: String!, $type: String!, $nr: Int!) {
    getAvailableHousing(startTime: $startTime, endTime: $endTime, type: $type, nr: $nr) {
      rec {
        id
        type
        name
        image
        beds
        size
        price
        wifi
        kitchen
        electricity
        tv
        active
      }
      housing {
        id
        type
        name
        image
        beds
        size
        price
        wifi
        kitchen
        electricity
        tv
        active
      }
    }
  }
`;

const SearchBar = ({ setResult, setLoading, setError, setDates, dates }) => {
  const [type, setType] = useState({ type: '', label: '' });
  const [guests, setGuests] = useState(0);
  const [active, setActive] = useState(false);
  const button = useRef(null);
  const dispatch = useDispatch();
  const [getAvailableHousing, { data, loading, error }] = useLazyQuery(GET_AVAILABLE_HOUSING);

  useEffect(() => {
    if (!loading) {
      if (error) {
        setError('Kunde inte ansluta till servern.');
      } else if (data) {
        const val = data.getAvailableHousing;
        val.housing = val.housing && val.housing.map(setImage);
        val.rec = val.rec && val.rec.map(setImage);

        setResult(val);
      }
    }
  }, [data, dispatch, error, loading, setError, setResult]);

  const search = useCallback(
    async e => {
      e.preventDefault();
      setLoading(true);

      await getAvailableHousing({
        variables: {
          startTime: ISODateString(dates[0]),
          endTime: ISODateString(dates[1]),
          type: type.type,
          nr: guests
        }
      });

      setLoading(false);
    },
    [setLoading, getAvailableHousing, dates, type.type, guests]
  );

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      if (e.key === 'Enter') {
        search(e);
      }
    };
    if (type.type !== '' && dates.length !== 0 && guests !== 0) {
      button.current.removeAttribute('disabled');
      button.current.addEventListener('keydown', handler);
      button.current.focus();
      setActive(true);
    } else {
      button.current.setAttribute('disabled', true);
      button.current.removeEventListener('keydown', handler);
      setActive(false);
    }
  }, [search, type, dates, guests]);
  return (
    <Container>
      <Select label="Vilket typ av boende?" list={types} value={type} setValue={setType} />
      <DatePicker label="Datum?" value={dates} setValue={setDates} />
      <Quantity label="Antal gäster?" value={guests} setValue={setGuests} />
      <Search ref={button} active={active} className="icon-search" onClick={e => search(e)} tabIndex={0} />
    </Container>
  );
};

SearchBar.propTypes = {
  setResult: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setDates: PropTypes.func.isRequired,
  dates: PropTypes.arrayOf(PropTypes.objectOf(Date)).isRequired
};

export default SearchBar;

// <Clear active={viewPhrase} className="icon-cancel-circled" onClick={e => clear(e)} />
