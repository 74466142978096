import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { Fonts, Colors } from 'fowlit';
import { addErr, addMsg } from '../../Reducers/Error/actions';

const Container = styled.form`
  position: relative;
`;

const Icon = styled.label`
  position: absolute;
  color: ${props => props.theme.color};
  font-size: ${Fonts.sizeTiny};
  cursor: pointer;
`;

const Update = styled(Icon)`
  top: -25px;
  left: 3px;
  &:hover {
    color: ${Colors.blueberry};
  }
  input {
    display: none;
  }
`;

const Delete = styled(Icon)`
  top: 3px;
  left: -25px;
  &:hover {
    color: ${Colors.strawberry};
  }
`;

const readFileAsync = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
};

const ImagePicker = ({ Image, text, src, onUpdate, onDelete, onSuccess }) => {
  const dispatch = useDispatch();
  const [updateImage] = useMutation(onUpdate);
  const [deleteImage] = useMutation(onDelete);

  const deleteIt = async () => {
    const { data, loading, error } = await deleteImage();
    if (!loading) {
      if (error) {
        dispatch(addErr('Kunde inte ta bort bilden'));
      } else if (data) {
        dispatch(addMsg('Bilden togs bort'));
        if (onSuccess) {
          onSuccess(data);
        }
      }
    }
  };

  const updateIt = async file => {
    if (file !== null && file !== undefined) {
      if (file.size >= 2097152) {
        dispatch(addErr('Bildstorleken får inte överskrida 2MB'));
        return;
      }

      const f = await readFileAsync(file);
      const image = f.split(',').pop();

      const { data, loading, error } = await updateImage({
        variables: {
          image
        }
      });
      if (!loading) {
        if (error) {
          dispatch(addErr('Kunde inte uppdatera bilden'));
        } else if (data) {
          dispatch(addMsg('Uppdaterade bilden.'));
          if (onSuccess) {
            onSuccess(data);
          }
        }
      }
    }
  };

  return (
    <Container>
      <Update className="icon-edit">
        <input type="file" onChange={e => updateIt(e.target.files[0])} />
      </Update>
      {src && <Delete className="icon-trash" onClick={() => deleteIt()} />}
      {src !== null ? <Image src={src} /> : <Image>{text}</Image>}
    </Container>
  );
};

ImagePicker.propTypes = {
  Image: PropTypes.elementType.isRequired,
  text: PropTypes.string,
  src: PropTypes.string,
  onUpdate: PropTypes.shape({
    document: PropTypes.string,
    definitions: PropTypes.array,
    loc: PropTypes.object
  }).isRequired,
  onDelete: PropTypes.shape({
    document: PropTypes.string,
    definitions: PropTypes.array,
    loc: PropTypes.object
  }).isRequired,
  onSuccess: PropTypes.func
};

ImagePicker.defaultProps = {
  text: null,
  src: null,
  onSuccess: null
};

export default ImagePicker;
