import React, { useState, useEffect } from 'react';
import { Route, Redirect, Switch, BrowserRouter } from 'react-router-dom';

import { Landing } from 'Pages';
import { Loader } from 'fowlit';
import { useSelector } from 'react-redux';

const CallbackHandler = () => {
  const params = new URLSearchParams(window.location.search);
  const { theme } = useSelector(state => state.session);

  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      console.log('doin stuff');
      const resp = await fetch('/oauth2/code', {
        method: 'POST',
        body: JSON.stringify({
          code: new URLSearchParams(window.location.search).get('code')
        })
      });

      if (!resp.ok) {
        setErr(`Request failed with status ${resp.status}`);
      } else {
        const b = await resp.json();
        setData(b);
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loader background={theme.body} color={theme.color} active />;
  }
  if (err) {
    return <div>{err}</div>;
  }

  if (
    data.nonce === window.localStorage.getItem('nonce') &&
    params.get('state') === window.localStorage.getItem('state')
  ) {
    window.localStorage.setItem('sessionToken', data.sessionToken);
    window.localStorage.removeItem('state');
    window.localStorage.removeItem('nonce');
    window.location = '/';

    return <></>;
  }
  return <div>Login could not be verified</div>;
};

const UnauthRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/callback" component={CallbackHandler} />
        <Route exact path="/" component={Landing} />
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default UnauthRouter;
