import { css } from 'styled-components';
import { Colors, Fonts } from './variables';

const buttonHeight = '50px';

const buttonTemplate = css`
  display: inline-block;
  font-size: ${Fonts.sizeTiny};
  line-height: ${buttonHeight};
  padding: 0 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 1px 5px 5px -7px ${Colors.black};
  box-sizing: border-box;
  appearance: none;
  outline: none;
  border: none;
  cursor: pointer;
`;

// eslint-disable-next-line import/prefer-default-export
export const Buttons = {
  blueberry: css`
    ${buttonTemplate};
    color: ${Colors.white};
    background: ${`linear-gradient(to bottom right, ${Colors.blueberry}, ${Colors.blueberryLighter}, ${Colors.blueberry})`};
    &:hover,
    :focus {
      background: ${`linear-gradient(to bottom right, ${Colors.blueberryLighter}, ${Colors.blueberry}, ${Colors.blueberryLighter})`};
    }
  `,
  white: css`
    ${buttonTemplate};
    color: ${Colors.black};
    background-color: ${Colors.whiteDarker};
  `,
  icon: css`
    ${buttonTemplate};
    color: ${Colors.white};
    background-color: ${Colors.blueberry};
    padding: 0;
    width: ${buttonHeight};
    font-size: ${buttonHeight} / 2;
  `,
  iconRound: css`
    ${buttonTemplate};
    color: ${Colors.white};
    background-color: ${Colors.blueberry};
    padding: 0;
    width: ${buttonHeight};
    font-size: ${buttonHeight} / 2;
    border-radius: 50%;
  `
};
