import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { Loader } from 'Components';
import { addErr } from 'Reducers/Error/actions';
import { Colors, Dimensions, Fonts } from 'fowlit';
import qs from 'query-string';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1095px 1fr;
  grid-template-rows: 200px 1fr 60px;
  grid-template-areas: 'manage manage manage' '. results .' '. buttons .';
  padding: 0 calc(10vw + ${Dimensions.navHeight}) 5vh 5vw;
`;

const Manage = styled.div`
  grid-area: manage;
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr 1095px 1fr;
  grid-template-rows: 1fr 20px;
  position: sticky;
  top: ${Dimensions.navHeight};
  background: ${props => props.theme.main};
  color: ${props => props.theme.color};
  padding: 50px 0;
  border: 2px solid ${props => props.theme.main};
`;

const Results = styled.div`
  grid-area: results;
  display: grid;
`;

const Ref = styled.h6`
  font-family: ${Fonts.text};
`;

const BookingContainer = styled.div`
  background: ${props => props.theme.body};
  border: 2px solid ${props => props.theme.border};
  border-radius: ${Dimensions.radius};
  margin-bottom: 10px;
  user-select: none;
  transition: 200ms ease-out;
  display: grid;
  grid-template-columns: 225px repeat(5, 150px) 50px;
  grid-template-rows: 90px;
  align-items: center;
  padding: 0 30px;
  cursor: pointer;

  &:hover {
    box-shadow: 1px 5px 15px -10px ${Colors.blackDarker};
    /*transform: translateY(-5px); Förstör för popup*/
    margin-top: 5px;
  }
`;

const BookingCell = styled.div`
  color: ${props => props.theme.color};
  padding: 0 10px;
  i {
    font-family: ${Fonts.text};
    font-size: 0.8rem;
  }
`;

const Button = styled.div`
  color: ${Colors.blueberry};
  font-size: ${Fonts.sizeSmall};
`;

const SEARCH_BOOKINGS = gql`
  fragment PersonParts on Person {
    firstName
    lastName
    email
    phone
    address
    zipCode
    city
    country
    lastPurchase
  }

  fragment CompanyParts on Company {
    organizationNr
    reference
    email
    phone
    address
    zipCode
    city
    country
    lastPurchase
    name
  }

  query($searchString: String!) {
    searchBookingReferences(searchString: $searchString) {
      referenceID
      price
      userID
      bookings {
        id
      }
      customer {
        ...PersonParts
        ...CompanyParts
      }
    }
  }
`;

const GET_USER = gql`
  query($id: ID!) {
    getUser(id: $id) {
      firstName
      lastName
    }
  }
`;

const Booking = ({ bookingsSize, referenceID, price, userID, customer }) => {
  const { data } = useQuery(GET_USER, { variables: { id: userID } });
  const { history } = useReactRouter();

  const firstName = data.getUser && data.getUser.firstName.charAt(0).toUpperCase() + data.getUser.firstName.slice(1);
  const lastName = data.getUser && data.getUser.lastName.charAt(0).toUpperCase() + data.getUser.lastName.slice(1);

  const name = customer.firstName
    ? `${customer.firstName.charAt(0).toUpperCase() + customer.firstName.slice(1)} ${customer.lastName
        .charAt(0)
        .toUpperCase()}${customer.lastName.slice(1)}`
    : `${customer.name.charAt(0).toUpperCase() + customer.name.slice(1).toLowerCase()}`;
  return (
    <BookingContainer
      onClick={() => {
        history.push(`/bookingReference?referenceID=${referenceID}`);
      }}
    >
      <BookingCell>
        <h6>{name}</h6>
        <i>{customer.email}</i>
      </BookingCell>
      <BookingCell>
        <i>Referens</i>
        <Ref>{referenceID.toUpperCase()}</Ref>
      </BookingCell>
      <BookingCell>
        <i>Telefon</i>
        <h6>{customer.phone}</h6>
      </BookingCell>
      <BookingCell>
        <i>Antal</i>
        <h6>{bookingsSize}</h6>
      </BookingCell>
      <BookingCell>
        <i>Pris</i>
        <h6>{`${parseInt(price, 10) / 10000} kr`}</h6>
      </BookingCell>
      <BookingCell>
        <i>Bokad av</i>
        <h6>{`${firstName} ${lastName}`}</h6>
      </BookingCell>
      <Button className="icon-angle-right" />
    </BookingContainer>
  );
};

Booking.propTypes = {
  referenceID: PropTypes.string.isRequired,
  bookingsSize: PropTypes.number.isRequired,
  customer: PropTypes.oneOfType([
    PropTypes.shape(
      PropTypes.string.isRequired,
      PropTypes.string.isRequired,
      PropTypes.string.isRequired,
      PropTypes.string.isRequired
    )
  ]).isRequired,
  price: PropTypes.string.isRequired,
  userID: PropTypes.number.isRequired
};

// TODO: SET LOADING AND ERROR

const Wrapper = () => {
  const { location } = useReactRouter();
  const { searchString } = qs.parse(location.search);
  if (!searchString || searchString.length === 0) {
    return (
      <main>
        <Container>
          <Manage>
            <div />
            <h4>Inkorrekt sökargument</h4>
            <div />
          </Manage>
        </Container>
      </main>
    );
  }
  return <Search />;
};

const Search = () => {
  const dispatch = useDispatch();
  const searchString = new URL(window.location).searchParams.get('searchString');
  const { data, loading, error } = useQuery(SEARCH_BOOKINGS, {
    variables: {
      searchString
    }
  });

  useEffect(() => {
    if (error) {
      dispatch(addErr('Kunde inte söka efter bokningar.'));
    }
  }, [dispatch, error]);

  return (
    <main>
      {(() => {
        if (loading) {
          return <Loader active />;
        }
        if (error) {
          dispatch(addErr('Kunde inte ansluta till servern.'));
        }
        if (!data.searchBookingReferences) {
          return (
            <Container>
              <Manage>
                <div />
                <h4>{`Hittade inget resultat för: ${searchString}`}</h4>
                <div />
              </Manage>
            </Container>
          );
        }
        return (
          <Container>
            <Manage>
              <div />
              <h4>{`Hittade ${data.searchBookingReferences.length} bokningar för: ${searchString}`}</h4>
              <div />
            </Manage>
            <Results>
              {!loading &&
                error !== null &&
                data.searchBookingReferences.map(e => (
                  <Booking bookingsSize={e.bookings ? e.bookings.length : 0} key={e.referenceID} {...e} />
                ))}
            </Results>
          </Container>
        );
      })()}
    </main>
  );
};

export default Wrapper;
