import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { popErr } from 'Reducers/Error/actions';
import { Colors, Fonts } from 'fowlit';

const MyError = styled.div`
  position: fixed;
  bottom: -50px;
  left: calc(50vw - 150px);
  transition: all 700ms ease-out;
  width: 300px;
  box-sizing: border-box;
  opacity: 0;
  border-top: 4px solid ${props => (props.type === 'error' ? Colors.strawberry : Colors.kiwi)};
  z-index: 100001;
  background: ${Colors.blackDarker};
  color: ${Colors.white};
  min-height: 50px;
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeSuperTiny};
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade 5s;
  box-shadow: 1px 5px 15px -10px ${Colors.blackDarker};
  @keyframes fade {
    0% {
      opacity: 0;
      bottom: -50px;
    }

    20% {
      opacity: 1;
      bottom: 50px;
    }

    80% {
      opacity: 1;
      bottom: 50px;
    }

    100% {
      opacity: 0;
      bottom: -50px;
    }
  }
`;

const Errors = () => {
  const dispatch = useDispatch();
  const errors = useSelector(state => state.errors);
  if (errors.length === 0) {
    return <></>;
  }
  const err = errors[0];
  return (
    <MyError type={err.type} key={err.id} onAnimationEnd={() => dispatch(popErr())}>
      {err.text}
    </MyError>
  );
};

export default Errors;
