import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import ApolloClient from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { ApolloLink, split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';

import introspectionQueryResultData from './fragmentTypes';
import { logout } from '../Library';

const sessionToken = localStorage.getItem('sessionToken');

const wsLink = new WebSocketLink({
  uri: `wss://boka.campigge.se/graphql`,
  //uri: `wss://campigge.fowlit.com/graphql`,
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        authorization: `Bearer ${sessionToken}`
      }
    }
  }
});

const httpLink = new HttpLink({
  uri: '/graphql',
  headers: {
    authorization: `Bearer ${sessionToken}`
  }
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

const logoutLink = onError(err => {
  console.log(err);
  const { networkError } = err;
  if (networkError !== undefined) {
    try {
      if (networkError.result.errors[0].extensions.code === 'UNAUTHENTICATED') {
        logout();
      }
    } catch (_) {}
  }
});

const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);
const link = ApolloLink.from([terminatingLink]);

const cache = new InMemoryCache({ fragmentMatcher });

const client = new ApolloClient({
  link: logoutLink.concat(link),
  cache,
  defaultOptions
});

export default client;
