import React from 'react';
import styled from 'styled-components';
import { Fonts, Media } from 'Res/stylesheets/variables';

const Container = styled.footer`
  padding: 0 20px;

  div {
    justify-content: space-between;
    display: flex;
    padding: 15px;
    span {
      i,
      a {
        margin: 0 8px;
        font-family: ${Fonts.text};
        font-weight: ${Fonts.weightLight};
        font-size: ${Fonts.sizeSuperTiny};
      }
    }
    @media only screen and (max-width: ${Media.phone}) {
      flex-flow: column;
      text-align: center;
      span {
        i,
        a {
          display: block;
          padding: 10px 0;
          width: 100%;
          font-family: ${Fonts.text};
          font-weight: ${Fonts.weightLight};
          font-size: ${Fonts.sizeSuperTiny};
        }
      }
    }
  }
`;

const Footer = () => {
  return (
    <Container>
      <hr />
      <div>
        <span>
          <i className="icon-fowlit"> 2019 Fowlit. All Rights Reserved.</i>
        </span>
        <span>
          <a href="https://fowlit.com">Support</a>
          <a href="https://fowlit.com">Regler och villkor</a>
        </span>
      </div>
    </Container>
  );
};

export default Footer;
