import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Colors, Dimensions, Fonts, Regexp } from 'fowlit';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { addErr, addMsg } from 'Reducers/Error/actions';
import { Input, Select } from 'Components/Forms';

const roles = [{ type: 'admin', label: 'Administratör' }, { type: 'worker', label: 'Arbetare' }];

const Container = styled.div`
  display: grid;
  grid-template-columns: 940px;
  grid-template-rows: 30px 1fr 60px;
  grid-gap: 20px;
  box-shadow: 1px 5px 15px -10px ${Colors.blackDarker};
  border-radius: ${Dimensions.radius};
  border: 2px solid ${props => props.theme.border};
  background: ${props => props.theme.body};
  padding: 30px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.color};
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 60px 1fr 1fr 1fr;
  grid-gap: 20px;
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 60px);
  align-items: center;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  appearance: none;
  border: none;
`;

const Close = styled(Button)`
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  &:focus {
    background: ${Colors.strawberry};
    color: ${Colors.white};
  }
`;

const SaveBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Save = styled.button`
  color: ${Colors.white};
  font-family: ${Fonts.text};
  font-size: 0.8rem;
  outline: none;
  appearance: none;
  border: none;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  padding: 0 30px;
  background: ${Colors.blueberry};
  &:hover {
    background: ${Colors.blueberryDarker};
  }
  &:focus {
    background: ${Colors.blueberryDarker};
  }
`;

const Initials = styled.div`
  background: linear-gradient(
    to bottom left,
    ${Colors.blueberryLighter},
    ${Colors.blueberry},
    ${Colors.blueberryDarker}
  );
  color: ${Colors.white};
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: ${Fonts.head};
  font-size: ${Fonts.sizeSmall};
  border: 1px solid ${props => props.theme.border};
`;

const CREATE_USER = gql`
  mutation($username: String!, $password: String!, $firstName: String!, $lastName: String!, $role: String!) {
    createUser(
      user: { username: $username, password: $password, firstName: $firstName, lastName: $lastName, role: $role }
    ) {
      id
    }
  }
`;

const CreateUser = ({ setActive }) => {
  const dispatch = useDispatch();
  const [createUser, { data, loading, error }] = useMutation(CREATE_USER);
  const firstNameEl = useRef('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [role, setRole] = useState({ type: '', label: '' });
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [initials, setInitials] = useState('');

  useEffect(() => {
    firstNameEl.current.focus();
  }, []);

  useEffect(() => {
    if (firstName.length > 0 || lastName.length > 0) {
      setInitials(firstName.charAt(0) + lastName.charAt(0));
    }
  }, [firstName, lastName]);

  useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(addErr('Kunde inte skapa ny användare.'));
      } else if (data) {
        setActive(false);
        dispatch(addMsg('Skapade ny användare.'));
      }
    }
  }, [setActive, dispatch, data, error, loading]);

  const onButtonClick = async e => {
    e.preventDefault();
    if (password !== '' && confirmPassword !== '' && password === confirmPassword) {
      await createUser({
        variables: {
          username,
          password,
          firstName,
          lastName,
          role: role.type
        }
      });
    } else {
      dispatch(addErr('Det nya lösenordet stämmer inte överens det bekräftade.'));
    }
  };

  return (
    <Container>
      <Header>
        <h6>Skapar ny användare</h6>
        <Close className="icon-cancel-circled" onClick={() => setActive(false)} />
      </Header>
      <Form id="form" onSubmit={onButtonClick}>
        <Initials>{initials}</Initials>
        <Box>
          <Input
            ref={firstNameEl}
            type="text"
            label="Förnamn"
            value={firstName}
            setValue={e => setFirstName(e)}
            required
            pattern={Regexp.name}
            minLength={1}
            maxLength={50}
          />
          <Input
            type="text"
            label="Efternamn"
            value={lastName}
            setValue={e => setLastName(e)}
            required
            pattern={Regexp.name}
            minLength={1}
            maxLength={50}
          />
          <Select label="Roll" options={roles} value={role} setValue={setRole} />
        </Box>
        <Box>
          <Input
            type="text"
            label="Användarnamn"
            value={username}
            required
            setValue={e => setUsername(e)}
            pattern={Regexp.username}
            minLength={5}
            maxLength={30}
          />
          <Input
            type="password"
            label="Nytt lösenord"
            value={password}
            setValue={e => setPassword(e)}
            required
            pattern={Regexp.password}
            minLength={8}
            maxLength={256}
          />
          <Input
            type="password"
            label="Bekräfta nytt lösenord"
            value={confirmPassword}
            setValue={e => setConfirmPassword(e)}
            required
            pattern={Regexp.password}
            minLength={8}
            maxLength={256}
          />
        </Box>
      </Form>
      <SaveBox>
        <Save type="submit" form="form">
          Spara
        </Save>
      </SaveBox>
    </Container>
  );
};

CreateUser.propTypes = {
  setActive: PropTypes.func.isRequired
};
export default CreateUser;
