import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { Loader } from 'Components';
import { addErr } from 'Reducers/Error/actions';
import { Colors, Dimensions, Fonts } from 'fowlit';
import PageNotFound from './PageNotFound';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1095px 1fr;
  grid-template-rows: 200px 1fr 60px;
  grid-template-areas: 'manage manage manage' '. results .' '. buttons .';
  padding: 0 calc(10vw + ${Dimensions.navHeight}) 5vh 5vw;
`;

const Manage = styled.div`
  grid-area: manage;
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr 1095px 1fr;
  grid-template-rows: 1fr 20px;
  position: sticky;
  top: ${Dimensions.navHeight};
  background: ${props => props.theme.main};
  color: ${props => props.theme.color};
  padding: 50px 0;
  border: 2px solid ${props => props.theme.main};

  i {
    font-family: ${Fonts.text};
    font-size: 0.8rem;
  }
`;

const Results = styled.div`
  grid-area: results;
  display: grid;
  overflow-y: auto;
`;

const BookingContainer = styled.div`
  background: ${props => props.theme.body};
  border: 2px solid ${props => props.theme.border};
  border-radius: ${Dimensions.radius};
  margin-bottom: 10px;
  user-select: none;
  transition: 200ms ease-out;
  display: grid;
  grid-template-columns: 225px repeat(5, 150px) 50px;
  grid-template-rows: 90px;
  align-items: center;
  padding: 0 30px;
  cursor: pointer;

  &:hover {
    box-shadow: 1px 5px 15px -10px ${Colors.blackDarker};
    /*transform: translateY(-5px); Förstör för popup*/
    margin-top: 5px;
  }
`;

const BookingCell = styled.div`
  color: ${props => props.theme.color};
  padding: 0 10px;
  i {
    font-family: ${Fonts.text};
    font-size: 0.8rem;
  }
`;

const Angle = styled.div`
  color: ${Colors.blueberry};
  font-size: ${Fonts.sizeSmall};
`;

const Buttons = styled.div`
  grid-area: buttons;
  display: grid;
  grid-template-columns: 120px 1fr 120px;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  color: ${Colors.white};
  font-family: ${Fonts.text};
  font-size: 0.8rem;
  outline: none;
  appearance: none;
  border: none;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  padding: 0 30px;
`;

const Previous = styled(Button)`
  background: ${Colors.blueberry};
  &:hover {
    background: ${Colors.blueberryDarker};
  }
`;

const Next = styled(Button)`
  background: ${Colors.blueberry};
  &:hover {
    background: ${Colors.blueberryDarker};
  }
`;

const Ref = styled.h6`
  font-family: ${Fonts.text};
`;

const GET_BOOKINGS = gql`
  fragment PersonParts on Person {
    firstName
    lastName
    email
    phone
    address
    zipCode
    city
    country
    lastPurchase
  }

  fragment CompanyParts on Company {
    organizationNr
    reference
    email
    phone
    address
    zipCode
    city
    country
    lastPurchase
    name
  }

  query($nr: Int!, $offset: Int!) {
    getBookingReferences(nr: $nr, offset: $offset) {
      referenceID
      price
      userID
      bookings {
        id
      }
      customer {
        ...PersonParts
        ...CompanyParts
      }
    }
  }
`;

const GET_SINGLE_BOOKING = gql`
  query($nr: Int!, $offset: Int!) {
    getBookingReferences(nr: $nr, offset: $offset) {
      referenceID
    }
  }
`;

const GET_USER = gql`
  query($id: ID!) {
    getUser(id: $id) {
      firstName
      lastName
    }
  }
`;

const Booking = ({ bookingsSize, referenceID, price, userID, customer }) => {
  const { history } = useReactRouter();
  const { data } = useQuery(GET_USER, { variables: { id: userID } });
  const firstName = data.getUser && data.getUser.firstName.charAt(0).toUpperCase() + data.getUser.firstName.slice(1);
  const lastName = data.getUser && data.getUser.lastName.charAt(0).toUpperCase() + data.getUser.lastName.slice(1);
  const name = customer.firstName
    ? `${customer.firstName.charAt(0).toUpperCase() + customer.firstName.slice(1)} ${customer.lastName
        .charAt(0)
        .toUpperCase()}${customer.lastName.slice(1)}`
    : customer.name;
  return (
    <BookingContainer
      onClick={() => {
        history.push(`/bookingReference?referenceID=${referenceID}`);
      }}
    >
      <BookingCell>
        <h6>{name}</h6>
        <i>{customer.email}</i>
      </BookingCell>
      <BookingCell>
        <i>Referens</i>
        <Ref>{referenceID.toUpperCase()}</Ref>
      </BookingCell>
      <BookingCell>
        <i>Telefon</i>
        <h6>{customer.phone}</h6>
      </BookingCell>
      <BookingCell>
        <i>Antal</i>
        <h6>{bookingsSize}</h6>
      </BookingCell>
      <BookingCell>
        <i>Pris</i>
        <h6>{`${parseInt(price, 10) / 10000} kr`}</h6>
      </BookingCell>
      <BookingCell>
        <i>Bokad av</i>
        <h6>{`${firstName} ${lastName}`}</h6>
      </BookingCell>
      <Angle className="icon-angle-right" />
    </BookingContainer>
  );
};

Booking.propTypes = {
  referenceID: PropTypes.string.isRequired,
  bookingsSize: PropTypes.number.isRequired,
  customer: PropTypes.oneOfType([
    PropTypes.shape(
      PropTypes.string.isRequired,
      PropTypes.string.isRequired,
      PropTypes.string.isRequired,
      PropTypes.string.isRequired
    )
  ]).isRequired,
  price: PropTypes.string.isRequired,
  userID: PropTypes.number.isRequired
};

const Wrapper = () => {
  const { location } = useReactRouter();
  if (!parseInt(qs.parse(location.search).page, 10)) {
    return (
      <main>
        <Container>
          <Manage>
            <div />
            <h4>Inkorrekt sidnummer</h4>
            <div />
          </Manage>
        </Container>
      </main>
    );
  }

  return <Bookings />;
};

const Bookings = () => {
  const { history, location } = useReactRouter();
  const [pageNr, setPageNr] = useState(parseInt(qs.parse(location.search).page, 10));

  useEffect(() => {
    setPageNr(parseInt(qs.parse(location.search).page, 10));
  }, [location]);
  const pageSize = 10;

  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GET_BOOKINGS, {
    variables: { nr: pageSize, offset: (pageNr - 1) * pageSize }
  });

  const [getAfterBooking, gab] = useLazyQuery(GET_SINGLE_BOOKING, {
    variables: { nr: 1, offset: pageNr * pageSize }
  });

  useEffect(() => {
    getAfterBooking({ variables: { nr: 1, offset: pageNr * pageSize } });
  }, [getAfterBooking, pageNr]);

  useEffect(() => {
    if (error) {
      dispatch(addErr('Kunde inte ansluta till servern.'));
    }
  }, [dispatch, error]);

  return (
    <>
      {(() => {
        if (loading) {
          return (
            <main>
              <Loader active />
            </main>
          );
        }
        if (error) {
          dispatch(addErr('Kunde inte hämta bokningar.'));
          return <Loader active />;
        }
        if (!data.getBookingReferences) {
          return <PageNotFound />;
        }
        return (
          <main>
            <Container>
              <Manage>
                <div />
                <div>
                  <h4>Bokningar</h4>
                  <i>{`Sida ${pageNr}`}</i>
                </div>
                <div />
              </Manage>
              <Results>
                {!loading &&
                  error !== null &&
                  data.getBookingReferences.map(e => (
                    <Booking bookingsSize={e.bookings ? e.bookings.length : 0} key={e.referenceID} {...e} />
                  ))}
              </Results>
              <Buttons>
                {pageNr > 1 ? (
                  <Previous onClick={() => history.push({ search: `?page=${pageNr - 1}` })}>Tillbaka</Previous>
                ) : (
                  <div />
                )}
                <div />
                {gab.data &&
                  (gab.data.getBookingReferences ? (
                    <Next onClick={() => history.push({ search: `?page=${pageNr + 1}` })}>Nästa</Next>
                  ) : (
                    <div />
                  ))}
              </Buttons>
            </Container>
          </main>
        );
      })()}
    </>
  );
};

export default Wrapper;
