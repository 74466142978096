import {
  FETCH_PROFILE_ERROR,
  FETCH_PROFILE_LOADING,
  FETCH_PROFILE_SUCCESS,
  UPDATE_PROFILE
} from './actions';

const initialState = {
  pending: false,
  userInfo: null,
  error: null
};

function profile(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROFILE_LOADING:
      return {
        ...state,
        pending: true
      };
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        pending: false,
        userInfo: action.payload.userInfo
      };
    case FETCH_PROFILE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload.userInfo }
      };
    default:
      return state;
  }
}

export default profile;
