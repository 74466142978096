import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { addUser, initUsers, updateUser, deleteUser, errorUsers, loadingUsers } from '../Reducers/Users/actions';

const GET_USERS = gql`
  {
    getUsers {
      id
      username
      changePassword
      firstName
      image
      lastName
      role
      createdAt
      lastLogin
    }
  }
`;

const USER_ADDED = gql`
  subscription {
    userAdded {
      id
      username
      changePassword
      firstName
      image
      lastName
      role
      createdAt
      lastLogin
    }
  }
`;

const USER_EDITED = gql`
  subscription {
    userEdited {
      id
      username
      changePassword
      firstName
      image
      lastName
      role
      createdAt
      lastLogin
    }
  }
`;

const USER_DELETED = gql`
  subscription {
    userDeleted {
      id
    }
  }
`;

const setImage = e => {
  if (e.image && e.image.substring(0, 5) !== 'data:') {
    e.image = e.image.length === 0 ? '' : `data:image;base64,${e.image}`;
  }
  return e;
};

const Users = () => {
  const dispatch = useDispatch();

  const userAdded = useSubscription(USER_ADDED);
  const userEdited = useSubscription(USER_EDITED);
  const userDeleted = useSubscription(USER_DELETED);
  const getUsers = useQuery(GET_USERS);

  useEffect(() => {
    const { data, loading, error } = getUsers;
    if (loading) {
      dispatch(loadingUsers(true));
    } else if (error) {
      dispatch(errorUsers(error));
    } else if (data) {
      dispatch(loadingUsers(false));
      if (data.getUsers) {
        dispatch(initUsers(data.getUsers.map(setImage)));
      }
    }
  }, [dispatch, getUsers]);

  useEffect(() => {
    if (userAdded.data) {
      dispatch(addUser(setImage(userAdded.data.userAdded)));
    }
  }, [dispatch, userAdded.data]);

  useEffect(() => {
    if (userEdited.data) {
      dispatch(updateUser(setImage(userEdited.data.userEdited)));
    }
  }, [dispatch, userEdited.data]);

  useEffect(() => {
    if (userDeleted.data) {
      dispatch(deleteUser(userDeleted.data.userDeleted));
    }
  }, [dispatch, userDeleted.data]);

  return <></>;
};

export default Users;
