import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Colors, Fonts, Dimensions } from 'Res/stylesheets/variables';

const Container = styled.div`
  grid-column: span 10;
  grid-row: span 5;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: auto 50px;
  grid-template-areas: 'data' 'to from';
  position: relative;
`;

const Data = styled.div`
  background: white;
  grid-area: 'info';
  grid-column: 1 / auto-fill;
  margin-bottom: 3px;
  border-radius: ${Dimensions.radius} ${Dimensions.radius} 5px 5px;
  padding: 15px;
`;

const Label = styled.h5`
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 0.8rem;
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  color: ${Colors.black};
`;

const Value = styled.h5`
  position: absolute;
  top: 35px;
  left: 15px;
  font-weight: ${Fonts.weightBold};
  font-family: ${Fonts.head};
  color: ${Colors.black};
  i {
    font-size: 0.8rem;
    font-weight: ${Fonts.weightRegular};
    font-family: ${Fonts.text};
  }
`;

const Percentage = styled.h5`
  position: absolute;
  top: 35px;
  right: 15px;
  font-weight: ${Fonts.weightBold};
  font-family: ${Fonts.head};
  color: ${Colors.kiwi};
  i {
    font-size: 0.8rem;
    font-weight: ${Fonts.weightRegular};
    font-family: ${Fonts.text};
  }
`;

const From = styled.div`
  background: white;
  grid-area: 'to';
  height: 50px;
  grid-column: span 2;
  margin-right: 1.5px;
  border-radius: 5px 5px 5px ${Dimensions.radius};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: ${Fonts.weightBold};
  font-family: ${Fonts.text};
  color: ${Colors.gray};
`;

const To = styled.div`
  background: white;
  grid-area: 'from';
  height: 50px;
  grid-column: span 2;
  margin-left: 1.5px;
  border-radius: 5px 5px ${Dimensions.radius} 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: ${Fonts.weightBold};
  font-family: ${Fonts.text};
  color: ${Colors.gray};
`;

const options = { day: 'numeric', month: 'short', year: 'numeric' };

const Graph = ({ spec }) => {
  // eslint-disable-next-line no-unused-vars
  const [fromDate, setFromDate] = useState(
    new Date(spec.from).toLocaleDateString('sv-SV', options)
  );
  // eslint-disable-next-line no-unused-vars
  const [toDate, setToDate] = useState(new Date(spec.to).toLocaleDateString('sv-SV', options));
  return (
    <Container>
      <Label>{spec.label}</Label>
      <Value>
        21 000
        <i> kr</i>
      </Value>
      <Percentage>
        7.5
        <i> %</i>
      </Percentage>
      <Data />
      <From>{fromDate}</From>
      <To>{toDate}</To>
    </Container>
  );
};

Graph.propTypes = {
  spec: PropTypes.shape({
    label: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

export default Graph;
