import React from 'react';
import styled from 'styled-components';
import useReactRouter from 'use-react-router';
import { NavLink } from 'react-router-dom';
import { Dimensions, Fonts } from 'fowlit';
import Search from './Search';
import User from './User';
import Icon from '../../Basket/Icon';

const Container = styled.div`
  z-index: 10002;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${Dimensions.navHeight};
  border-bottom: 2px solid ${props => props.theme.navBorder};
  background: ${props => props.theme.navBackground};
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

const TopLeft = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const Logo = styled.div`
  width: ${Dimensions.navHeight};
  text-align: center;
  a {
    display: block;
    font-size: 35px;
    line-height: ${Dimensions.navHeight};
    color: ${props => props.theme.navLogo};
  }
`;

const Drawer = styled.i`
  display: block;
  padding: 0 5px 0 15px;
  font-size: 20px;
  color: ${props => props.theme.navColor};
  pointer-events: none;
`;

const Current = styled.i`
  display: block;
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeSuperTiny};
  font-weight: ${Fonts.sizeRegular};
  color: ${props => props.theme.navColor};
  pointer-events: none;
`;

const TopRight = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const Topbar = () => {
  const { location } = useReactRouter();
  const getLocation = () => {
    const loc = location.pathname.slice(1);
    switch (loc) {
      case '':
        return 'Hem';
      case 'bookingReference':
        return 'Bokning';
      case 'search':
        return 'Sök';
      case 'checkout':
        return 'Kassa';
      case 'housings':
        return 'Bostäder';
      case 'basket':
        return 'Varukorg';
      case 'profile':
        return 'Profil';
      case 'settings':
        return 'Inställningar';
      case 'bookings':
        return 'Bokningar';
      case 'users':
        return 'Användare';
      default:
        return loc.charAt(0).toUpperCase() + loc.slice(1);
    }
  };

  return (
    <Container>
      <TopLeft>
        <Logo>
          <NavLink exact to="/" className="icon-fowlit" />
        </Logo>
        <Drawer className="icon-angle-right" />
        <Current>{getLocation()}</Current>
      </TopLeft>
      <TopRight>
        <Icon />
        <Search />
        <User />
      </TopRight>
    </Container>
  );
};

export default Topbar;
