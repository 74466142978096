import { combineReducers } from 'redux';
import errors from './Error';
import profile from './Profile';
import session from './Session';
import basket from './Basket';
import bookings from './Bookings';
import users from './Users';
import housings from './Housings';
import settings from './Settings';

export default combineReducers({ settings, errors, housings, users, bookings, profile, session, basket });
