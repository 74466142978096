import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useReactRouter from 'use-react-router';
import { Colors, Fonts, Dimensions } from 'Res/stylesheets/variables';
import { rgba } from '../../Library';

const Container = styled.div`
  grid-column: span 5;
  grid-row: span ${props => props.length + 3};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 1fr;
  background: ${props => props.theme.body};
  color: ${props => props.theme.color};
  border-radius: ${Dimensions.radius};
  border: 2px solid ${props => props.theme.border};
  padding: 20px;
`;

const Label = styled.i`
  font-size: 0.8rem;
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
`;

const Items = styled.div`
  display: flex;
  flex-flow: column;
`;

const Value = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  cursor: pointer;

  div {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    padding-left: 20px;
  }
`;

const Name = styled.i`
  font-size: 0.8rem;
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
`;

const Reference = styled.i`
  font-size: 0.6rem;
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  color: ${props => props.theme.color};
`;

const Icon = styled.i`
  color: ${props => props.color};
  font-size: 20px;
  background: ${props => rgba(0.3, props.color)};
  border-radius: 50%;
  border: 2px solid ${props => rgba(0.1, props.color)};
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 500ms ease-in-out;
`;

const List = ({ spec }) => {
  const { history } = useReactRouter();
  const getIcon = type => {
    switch (type) {
      case 'camp':
        return 'icon-camp';
      case 'conf':
        return 'icon-fowlit';
      case 'field':
        return 'icon-fowlit';
      default:
        return 'icon-fowlit';
    }
  };

  const getIconColor = type => {
    switch (type) {
      case 'camp':
        return Colors.kiwi;
      case 'conf':
        return Colors.blueberry;
      case 'field':
        return Colors.physalis;
      default:
        return Colors.blueberry;
    }
  };
  return (
    <Container length={spec.bookings.length}>
      <Label>{spec.label}</Label>
      <Items length={spec.bookings.length}>
        {spec.bookings.map(b => (
          <Value
            key={Math.random()}
            onClick={() => {
              history.push(`/bookingReference?referenceID=${b.referenceID}`);
            }}
          >
            <Icon className={getIcon(b.type)} color={getIconColor(b.type)} />
            <div>
              <Name>
                {b.customer.firstName &&
                  `${b.customer.firstName.charAt(0).toUpperCase() +
                    b.customer.firstName.slice(1)} ${b.customer.lastName.charAt(0).toUpperCase() +
                    b.customer.lastName.slice(1)}`}
                {b.customer.name && b.customer.name.charAt(0).toUpperCase() + b.customer.name.slice(1)}
              </Name>
              <Reference>{b.referenceID.toUpperCase()}</Reference>
            </div>
          </Value>
        ))}
      </Items>
    </Container>
  );
};

List.propTypes = {
  spec: PropTypes.shape({
    label: PropTypes.string,
    bookings: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(['camp', 'conf', 'field']),
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        referenceID: PropTypes.string
      })
    ).isRequired
  }).isRequired
};

export default List;
