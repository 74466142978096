import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Colors, Dimensions, Fonts, Media, Regexp } from 'fowlit';
import { useDispatch, useSelector } from 'react-redux';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Input } from 'Components/Forms';
import { addErr, addMsg } from '../../Reducers/Error/actions';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(34, 34, 34, 0.7);
  z-index: 100000;
  display: ${props => (props.active ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.color};
`;

const Box = styled.div`
  width: ${Media.phone};
  height: 500px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 0.3fr;
  grid-template-areas: 'info' 'form';

  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    width: 100%;
    height: 100%;
  }
`;

const Information = styled.div`
  grid-area: info;
  background: red;
  border-radius: ${Dimensions.radius} ${Dimensions.radius} 0 0;
  background: ${props => props.theme.body};
  border: 1px solid ${props => props.theme.border};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h6 {
    font-size: ${Fonts.sizeTiny};
    margin-bottom: 10px;
  }

  i {
    font-family: ${Fonts.text};
    font-size: ${Fonts.sizeSuperTiny};
    margin-bottom: 20px;
  }

  p {
    font-family: ${Fonts.text};
    font-size: ${Fonts.sizeSuperTiny};
    margin: 0 50px;
  }

  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    border-radius: 0;
  }
`;

const Form = styled.form`
  padding: 30px;
  box-sizing: border-box;
  grid-area: form;
  border-radius: 0 0 ${Dimensions.radius} ${Dimensions.radius};
  background: ${props => props.theme.body};
  border: 1px solid ${props => props.theme.border};
  border-top: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  grid-gap: 30px;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    grid-template-columns: 1fr;
    grid-template-rows: 50px 50px 50px;
    border-radius: 0;
    justify-items: flex-end;
    box-sizing: border-box;

    input {
      width: calc(100vw - 60px);
    }
  }
`;

const Image = styled.div`
  background: ${props => `url('${props.url}')`} no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  position: relative;
  z-index: 2;
  margin-bottom: 10px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
  background: ${Colors.blueberry};
  color: ${Colors.white};
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  appearance: none;
  border: none;

  &:disabled {
    background: ${Colors.gray};
  }
`;

const Initials = styled.div`
  background: linear-gradient(
    to bottom left,
    ${Colors.blueberryLighter},
    ${Colors.blueberry},
    ${Colors.blueberryDarker}
  );
  border-radius: 50%;
  height: 100px;
  width: 100px;
  position: relative;
  z-index: 2;
  margin-bottom: 10px;
  color: ${Colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: ${Fonts.head};
  font-size: ${Fonts.sizeSmall};
`;

const CHANGE_PASSWORD = gql`
  mutation($password: String!) {
    changePassword(newPassword: $password)
  }
`;

const ForcePassword = ({ active, setActive }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(state => state.profile);
  const [changePassword, { data, error, loading }] = useMutation(CHANGE_PASSWORD);
  let passRef = null;
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const initials = userInfo.firstName.charAt(0) + userInfo.lastName.charAt(0);
  const name = `${userInfo.firstName.charAt(0).toUpperCase() + userInfo.firstName.slice(1)} ${userInfo.lastName
    .charAt(0)
    .toUpperCase()}${userInfo.lastName.slice(1)}`;

  useEffect(() => {
    if (passRef !== null) {
      passRef.focus();
    }
  }, [passRef]);

  useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(addErr('Kunde inte ansluta till servern.'));
      } else if (data) {
        setActive(false);
        dispatch(addMsg('Lösenordet uppdaterades.'));
      }
    }
  }, [data, dispatch, error, loading, setActive]);

  return (
    <Container active={active}>
      <Box>
        <Information>
          {userInfo.image === null ? <Initials>{initials}</Initials> : <Image url={userInfo.image} />}
          <i>{name}</i>
          <h6>Du behöver byta lösenord!</h6>
          <p>Denna åtgärd går inte att avbryta. Kontakta en administratör om du har några frågor angående detta.</p>
        </Information>
        <Form
          onSubmit={async e => {
            e.preventDefault();
            if (newPassword === confirmPassword) {
              await changePassword({ variables: { password: newPassword } });
            } else {
              dispatch(addErr('Kontrollera lösenorden.'));
            }
          }}
        >
          <Input
            ref={e => {
              passRef = e;
            }}
            type="password"
            label="Nytt lösenord"
            value={newPassword}
            setValue={e => setNewPassword(e)}
            reqiured
            pattern={Regexp.password}
            minLength={8}
            maxLength={256}
          />
          <Input
            type="password"
            label="Nytt lösenord"
            value={confirmPassword}
            setValue={e => setConfirmPassword(e)}
            reqiured
            pattern={Regexp.password}
            minLength={8}
            maxLength={256}
          />
          <Button type="submit" className="icon-save" disabled={newPassword === '' || confirmPassword === ''} />
        </Form>
      </Box>
    </Container>
  );
};

ForcePassword.propTypes = {
  active: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired
};

export default ForcePassword;
