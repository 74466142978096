import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Colors, Fonts, Media } from 'fowlit';
import { useComponentVisible } from 'Hooks';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';

const Container = styled.div`
  background: ${props => props.theme.background};
  border: 1px solid ${props => props.theme.border};
  border-radius: 5px;
  line-height: 40px;
  margin: 0 10px;
  width: 260px;
  outline: none;

  @media (max-width: ${Media.phone}) {
    display: none;
  }
`;

const Input = styled.input`
  width: 200px;
  appearance: none;
  border: none;
  background: transparent;
  padding: 0 10px 0 0px;
  font-size: ${Fonts.sizeSuperTiny};
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  outline: none;
  color: ${props => props.theme.color};
`;

const Icon = styled.i`
  color: ${props => (props.active ? Colors.blueberry : Colors.gray)};
  padding: 0 10px 0 10px;
  display: inline-block;
  transform: ${props => (props.active ? 'scaleX(-1)' : 'scaleX(1)')};
  transition: 200ms ease-out;
`;

const Items = styled.div`
  margin: 3px 0;
  position: absolute;
  width: 260px;
  box-shadow: 1px 5px 5px -7px ${Colors.black};
  border-radius: 5px;
  background: ${props => props.theme.background};
  border: 1px solid ${props => (props.active ? props.theme.border : 'transparent')};
`;

const Item = styled.div`
  cursor: pointer;
  display: block;
  font-size: ${Fonts.sizeSuperTiny};
  font-family: ${Fonts.text};
  padding: 0 10px;
  line-height: 40px;
  transition: 200ms ease-out;
  border-radius: 5px;
  color: ${props => (props.active ? Colors.blueberry : props.theme.color)};

  &:hover {
    color: ${Colors.blueberry};
  }
`;

const Search = () => {
  const { history } = useReactRouter();
  const [searchString, setSearchString] = useState('');
  const input = useRef(null);
  const latest = useSelector(state => state.bookings.latest);
  const searchables = useRef(null);
  const [ind, setInd] = useState(-1);
  const [bookings, setBookings] = useState([]);
  const { ref, visible, setVisible } = useComponentVisible(false);
  const [vars, setVars] = useState(null);

  useEffect(() => {
    if (!visible && ind === -1 && vars) {
      input.current.blur();
      history.push(`${vars.path}?${vars.argName}=${vars.arg}`);
    }
  }, [visible, ind, vars, history]);

  useEffect(() => {
    if (latest.length !== 0) {
      setBookings(
        latest.bookings.map(e => {
          if (e.customer.firstName) {
            return {
              referenceID: e.referenceID,
              name: `${e.customer.firstName} ${e.customer.lastName}`,
              email: e.customer.email,
              phone: e.customer.phone
            };
          }
          if (e.customer.name) {
            return {
              referenceID: e.referenceID,
              name: e.customer.name,
              email: e.customer.email,
              phone: e.customer.phone
            };
          }
          alert("This is real bad since consumer isn't implemented yet in search");
          return null;
        })
      );
    }
  }, [latest]);

  const search = useCallback(
    (path, argName, arg) => {
      setVisible(false);
      setVars({ path, argName, arg });
      setInd(() => -1);
    },
    [setVisible]
  );

  const handleUserKeyPress = useCallback(
    event => {
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          setInd(prev => (prev > -1 ? prev - 1 : prev));
          break;
        case 'ArrowDown':
          event.preventDefault();
          setInd(prev => (prev < searchables.current.length - 1 ? prev + 1 : prev));
          break;
        case 'Enter':
          event.preventDefault();

          setInd(prev => {
            if (prev > -1) {
              search('/bookingReference', 'referenceID', searchables.current[prev].referenceID);
            } else if (input.current.value !== '') {
              search(
                '/search',
                'searchString',
                encodeURIComponent(
                  input.current.value
                    .toLowerCase()
                    .trim()
                    .replace(/\s+/g, ' ')
                )
              );
              setVisible(false);
            }
            return prev;
          });

          break;
        default:
          break;
      }
    },
    [search, setVisible]
  );

  useEffect(() => {
    if (input.current) {
      input.current.addEventListener('keydown', handleUserKeyPress);
      return () => {
        input.current.removeEventListener('keydown', handleUserKeyPress);
      };
    }
    return () => {};
  });

  const onChange = e => {
    setSearchString(
      e.target.value
        .toLowerCase()
        .trim()
        .replace(/\s+/g, ' ')
    );
    setInd(-1);
  };

  const stringMatches = e => {
    return (
      e.name.toLowerCase().includes(searchString) ||
      e.referenceID.includes(searchString) ||
      e.email.includes(searchString) ||
      e.phone.includes(searchString)
    );
  };

  const getItems = () => {
    if (searchString === '') {
      return <></>;
    }
    searchables.current = bookings.filter(e => stringMatches(e)).slice(0, 5);
    if (searchables.current.length === 0) {
      return (
        <Item onClick={() => search('/search', 'searchString', encodeURIComponent(searchString))}>
          <i>Sök på fowlit</i>
        </Item>
      );
    }
    return searchables.current.map((e, i) => (
      <Item
        key={e.referenceID}
        active={ind === i}
        onClick={() => search('/bookingReference', 'referenceID', e.referenceID)}
      >
        <i className={ind === i ? 'icon-angle-right' : ''}>{e.name}</i>
      </Item>
    ));
  };

  return (
    <Container ref={ref} onFocus={() => setVisible(true)}>
      <Icon
        active={visible}
        onClick={() => search('/search', 'searchString', encodeURIComponent(searchString))}
        className="icon-search"
      />

      <Input
        placeholder="Sök bland bokningar..."
        type="text"
        ref={e => {
          input.current = e;
        }}
        onChange={e => onChange(e)}
      />
      <Items active={searchString !== '' && visible}>{visible && getItems()}</Items>
    </Container>
  );
};

export default Search;
