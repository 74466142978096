import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fonts, Colors } from 'fowlit';

const Container = styled.div`
  display: grid;
  grid-template-columns: 55px 1fr;
  grid-template-rows: 55px;
  align-items: center;
  color: ${props => props.theme.color};
  i {
    font-family: ${Fonts.text};
    font-size: 0.7rem;
  }
`;

const Image = styled.div`
  background: ${props => `url('${props.url}')`} no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 40px;
  width: 40px;
`;

const Initials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom left, ${Colors.blueberry}, ${Colors.blueberryDarker});
  color: ${Colors.white};
  width: 40px;
  height: 40px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 50%;
  text-transform: uppercase;
  font-family: ${Fonts.head};
  font-size: ${Fonts.sizeTiny};
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
`;

const options = { day: 'numeric', month: 'short', year: 'numeric' };

const Item = ({ data }) => {
  return (
    <Container>
      {data.image ? <Image url={data.image} /> : <Initials>{data.name.charAt(0).toUpperCase()}</Initials>}
      <div>
        <h6>{data.name}</h6>
        <Details>
          <i>{new Date(data.endTime).toLocaleDateString('sv-SV', options)}</i>
          <i>
            {`${(parseInt(data.price, 10) / 10000 - parseInt(data.discount, 10) / 10000).toLocaleString('sv-SV')} kr`}
          </i>
        </Details>
      </div>
    </Container>
  );
};

Item.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.string,
    discount: PropTypes.string,
    housingID: PropTypes.string,
    startTime: PropTypes.instanceOf(Date),
    endTime: PropTypes.instanceOf(Date),
    userID: PropTypes.string,
    image: PropTypes.string
  }).isRequired
};

export default Item;
