import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fonts, Colors } from 'fowlit';

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${props => (props.button ? '1fr' : '1fr 50px')}
  grid-template-rows: 50px;
  border-radius: 5px;
  background: ${props => props.theme.body};
  border: 1px solid ${props => props.theme.border};
  color: ${props => props.theme.color};
  height: 50px;
`;

const Enter = styled.input`
  position: relative;
  appearance: none;
  outline-color: none;
  border: none;
  background: none;
  line-height: 50px;
  padding: 8px 40px 0 10px;
  font-size: ${Fonts.sizeSuperTiny};
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  z-index: 1;
  color: ${props => props.theme.color};

  &:focus {
    outline: none;
  }
  /* Implement later
    &:valid + i::before {}
    &:invalid + i::before {}
  */
`;

const Header = styled.i`
  position: absolute;
  top: ${props => (props.active ? '0px' : '-13px')};
  left: 10px;
  font-size: ${props => (props.active ? Fonts.sizeSuperTiny : '0.7rem')};
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  transition: 300ms ease-out;
  line-height: 50px;
  z-index: 0;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.blueberry}
  font-size: ${Fonts.sizeSuperTiny};
  cursor: pointer;
`;

const Input = forwardRef(
  ({ label, type, name, value, setValue, required, pattern, minLength, maxLength, button }, ref) => {
    const [isEmpty, setIsEmpty] = useState(true);

    return (
      <Container button={button === null}>
        <Header active={value === '' && isEmpty}>{label}</Header>
        {value !== '' || value !== null || setValue !== PropTypes.func ? (
          <Enter
            ref={ref}
            type={type}
            name={name}
            value={value}
            onChange={e => setValue(e.target.value)}
            required={required}
            pattern={pattern}
            minLength={minLength}
            maxLength={maxLength}
            onFocus={() => setIsEmpty(false)}
            onBlur={e => setIsEmpty(e.target.value === '')}
          />
        ) : (
          <Enter
            ref={ref}
            type={type}
            name={name}
            required={required}
            pattern={pattern}
            minLength={minLength}
            maxLength={maxLength}
            onFocus={() => setIsEmpty(false)}
            onBlur={e => setIsEmpty(e.target.value === '')}
          />
        )}
        {button && (
          <Button onClick={button}>
            <i className="icon-search" />
          </Button>
        )}
      </Container>
    );
  }
);

Input.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  pattern: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  button: PropTypes.func
};

Input.defaultProps = {
  name: null,
  value: '',
  setValue: PropTypes.func,
  required: false,
  pattern: null,
  minLength: 0,
  maxLength: PropTypes.value,
  button: null
};

export default Input;
