import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fonts } from 'fowlit';
import { useDispatch } from 'react-redux';
import { setGuests as setReduxGuests } from '../../Reducers/Basket/actions';

const Container = styled.div`
  display: grid;
  grid-template-columns: ${props => `minmax(20px, ${props.length * 10}px)`} 20px;
  grid-gap: 3px;
  align-items: center;

  input {
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeSuperTiny};
    background: none;
    color: ${props => props.theme.color};
    width: 100%;
    appearance: none;
    border: none;
    overflow-x: hidden;
  }
`;

const Guests = ({ value, id }) => {
  const dispatch = useDispatch();

  const [guests, setGuests] = useState(parseInt(value, 10).toString());
  const el = useRef('');

  return (
    <Container length={guests.length}>
      <input
        ref={el}
        type="text"
        value={guests}
        onChange={e => setGuests(e.target.value)}
        onBlur={async () => {
          if (!isNaN(guests)) {
            dispatch(
              setReduxGuests({
                id,
                nr: guests ? parseInt(guests, 10) : 0
              })
            );
          }
        }}
        pattern="[0-9]+"
      />
      <h6>st</h6>
    </Container>
  );
};

Guests.propTypes = {
  value: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired
};

export default Guests;
