import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dimensions } from 'fowlit';
import Camping from '../../Res/Assets/Svg/camping.svg';

const Container = styled.div`
  grid-column: span 18;
  grid-row: span 3;
  background: ${props => props.theme.body};
  color: ${props => props.theme.color};
  border-radius: ${Dimensions.radius};
  border: 2px solid ${props => props.theme.border};
  display: grid;
  grid-template-columns: 1fr 300px;
`;

const Left = styled.div`
  padding: 20px;
`;

const Header = styled.h5``;

const Text = styled.p``;

const Right = styled.div`
  padding-top: 15px;
  padding-right: 15px;
`;

const Image = styled.div`
  background: url(${props => props.src}) no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
`;

const Welcome = ({ header, text }) => {
  return (
    <Container>
      <Left>
        <Header>{header}</Header>
        <Text>{text}</Text>
      </Left>
      <Right>
        <Image src={Camping} />
      </Right>
    </Container>
  );
};

Welcome.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default Welcome;
