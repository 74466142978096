import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'fowlit';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import {
  Basket as BasketPage,
  Checkout,
  Camping,
  BookingReference,
  Home,
  PageNotFound,
  Profile,
  Users,
  Housings,
  Search,
  Settings,
  Confirmation,
  Bookings
} from 'Pages';
import { ForcePassword, NavBar } from 'Components';
import GraphQLState from './Apollo/GraphQLState';
import { fetchProfileError, fetchProfileSuccess } from './Reducers/Profile/actions';
import { initBasket } from './Reducers/Basket/actions';
import { setSettings } from './Reducers/Settings/actions';
import Updater from './Updater';
import { setImage } from './Library';

const ADMIN = 'admin';
const WORKER = 'worker';

const links = [
  { path: '/', title: 'Hem', component: Home, access: [ADMIN, WORKER] },
  { path: '/basket', title: 'Varukorg', component: BasketPage, access: [ADMIN, WORKER] },
  { path: '/checkout', title: 'Kassa', component: Checkout, access: [ADMIN, WORKER] },
  { path: '/camping', title: 'Camping', component: Camping, access: [ADMIN, WORKER] },
  { path: '/profile', title: 'Profil', component: Profile, access: [ADMIN, WORKER] },
  { path: '/users', title: 'Användare', component: Users, access: [ADMIN] },
  { path: '/settings', title: 'Inställningar', component: Settings, access: [ADMIN] },
  { path: '/housings', title: 'Bostäder', component: Housings, access: [ADMIN] },
  { path: '/bookings', title: 'Bokningar', component: Bookings, access: [ADMIN, WORKER] },
  { path: '/search', title: 'Sök', component: Search, access: [ADMIN, WORKER] },
  { path: '/bookingReference', title: 'Bokningsreferens', component: BookingReference, access: [ADMIN, WORKER] },
  { path: '/confirmation', title: 'Bekräftelse', component: Confirmation, access: [ADMIN, WORKER] }
];

const GET_INFO_BASKET = gql`
  query {
    getUserInfo {
      username
      firstName
      lastName
      role
      changePassword
      createdAt
      image
    }

    getBasket {
      id
      name
      price
      discount
      image
      itemID
      itemType
      startTime
      endTime
      guests
    }

    getSettings {
      general {
        basketTime
      }
      camping {
        checkIn
        checkOut
      }
    }
  }
`;

const App = () => {
  const dispatch = useDispatch();
  const { theme } = useSelector(state => state.session);
  const { data, loading, error } = useQuery(GET_INFO_BASKET);
  const [active, setActive] = useState(false);
  const [finished, setFinished] = useState(false);
  useEffect(() => {
    if (!loading && !error) {
      const { getUserInfo } = data;
      dispatch(fetchProfileSuccess(setImage(getUserInfo)));
      if (data.getBasket) {
        dispatch(initBasket(data.getBasket.map(setImage)));
      }
      dispatch(setSettings(data.getSettings));
      setActive(getUserInfo.changePassword);
      setFinished(true);
    }
  }, [data, dispatch, error, loading]);

  if (loading) {
    return <Loader background={theme.body} color={theme.color} active />;
  }

  if (error) {
    if (error.networkError.result.errors[0].extensions.code === 'UNAUTHENTICATED') {
      return <p>Du loggas ut...</p>;
    }
    dispatch(fetchProfileError('Kunde inte ansluta till servern'));

    return JSON.stringify(error);
  }
  if (!finished) {
    return null;
  }
  return (
    <BrowserRouter>
      <Updater />
      <GraphQLState />
      <NavBar />
      <Switch>
        {links
          .filter(e => e.access.includes(data.getUserInfo.role))
          .map(e => (
            <Route exact path={e.path} key={e.title} component={e.component} />
          ))}
        <Route component={PageNotFound} />
      </Switch>
      <ForcePassword active={active} setActive={setActive} />
    </BrowserRouter>
  );
};

export default App;
