/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { Link } from 'react-router-dom';
import { Colors, Fonts, Dimensions, Media, Buttons } from 'fowlit';

const Container = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    align-items: flex-start;
  }
`;

const Box = styled.div`
  width: ${Media.phone};
  height: 500px;
  background: ${props => props.theme.body};
  color: ${props => props.theme.color};
  border: 1px solid ${props => props.theme.border};
  border-radius: ${Dimensions.radius};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  text-align: center;
  padding: 30px;
  box-sizing: border-box;
  h6 {
    margin-bottom: 5px;
  }
  p {
    font-size: ${Fonts.sizeSuperTiny};
    margin-bottom: 30px;
  }
  a {
    color: ${Colors.blueberry};
    font-size: ${Fonts.sizeSuperTiny};
    margin-bottom: 30px;
  }
  @media (max-width: ${Media.phone}), (max-height: ${Media.phone}) {
    width: 70vw;
    margin-top: 30px;
  }
`;

const Path = styled.i`
  font-size: 0.7rem;
  font-family: ${Fonts.text};
  margin-bottom: 50px;
`;

const Icon = styled.i`
  color: ${props => props.color};
  font-size: 30px;
  background: ${props => transparentize(0.7, props.color)};
  border-radius: 50%;
  border: 2px solid ${transparentize(0.8, Colors.white)};
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 500ms ease-in-out;
  margin-bottom: 20px;
`;

const Button = styled.div`
  ${Buttons.blueberry}
  a {
    color: ${Colors.white};
    font-size: ${Fonts.sizeSuperTiny};
  }
`;

const PageNotFound = () => {
  return (
    <Container>
      <Box>
        <Icon className="icon-trash" color={Colors.strawberry} />
        <h6>Sidan du sökte kunde inte hittas!</h6>
        <Path>{window.location.href}</Path>
        <p>
          Om du har frågor eller funderingar maila vår <a href="mailto:support@fowlit.com">Support</a>.
        </p>
        <Button>
          <Link to="/">Gå till Hem</Link>
        </Button>
      </Box>
    </Container>
  );
};

export default PageNotFound;
