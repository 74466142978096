import React from 'react';
import styled from 'styled-components';
import { Fonts } from 'fowlit';
import PropTypes from 'prop-types';

const Phrase = styled.div`
  user-select: none;
  grid-area: phrase;
  display: ${props => (props.active ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.searchbarColor};
  margin-top: 5px;
`;

const Loading = styled.i`
  font-size: ${Fonts.sizeRegular};
  animation: load 700ms ease infinite;
  @keyframes load {
    0% {
      transform: rotateZ(0);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`;

const Loader = ({ active }) => (
  <Phrase active={active}>
    <Loading className="icon-fowlit" />
  </Phrase>
);

Loader.propTypes = {
  active: PropTypes.bool.isRequired
};

export default Loader;
