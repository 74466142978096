import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors, Dimensions, Fonts } from 'fowlit';
import { useSelector } from 'react-redux';
import { CreateHousing, Loader, Housing } from '../Components';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const Menu = styled.div`
  position: sticky;
  top: ${props => (props.active ? Dimensions.navHeight : '-30px')};
  width: 100%;
  z-index: 1;
  margin-bottom: 5px;
  background: ${props => props.theme.main};
  display: grid;
  grid-template-columns: 1fr 501px 501px 1fr;
  grid-template-rows: 100px 1fr 50px;
  grid-template-areas: '. search createButton .' '. createUser createUser .' '. label label .';
`;

const Labels = styled.div`
  grid-area: label;
  display: grid;
  grid-template-columns: 100px 650px 250px;
  grid-template-rows: 50px;
  i {
    color: ${props => props.theme.color};
    font-family: ${Fonts.text};
    font-size: 0.6rem;
    font-weight: ${Fonts.weightBold};
    text-transform: uppercase;
  }
`;

const ListHousings = styled.div`
  grid-area: list;
  position: relative;
`;

const Box = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const InfoBox = styled(Box)`
  display: grid;
  grid-template-columns: 1.5fr repeat(4, 1fr);
`;

const SearchBox = styled(Box)`
  grid-area: search;
  align-items: flex-end;
`;

const Search = styled.div`
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  width: 70%;
  height: 40px;
  margin-left: 5px;
  border-radius: 20px;
  border: 2px solid ${props => props.theme.border};
  display: grid;
  grid-template-columns: 40px 1fr;
  i {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.blueberry};
  }
  input {
    outline: none;
    appearance: none;
    border: none;
    border-radius: 20px;
    background: none;
    font-family: ${Fonts.text};
    font-size: 0.8rem;
    color: ${props => props.theme.color};
  }
`;

const CreateButtonBox = styled(Box)`
  grid-area: createButton;
  align-items: flex-end;
  justify-content: flex-end;
`;

const CreateButton = styled.button`
  background: ${Colors.blueberry};
  color: ${Colors.white};
  font-family: ${Fonts.text};
  font-size: 0.8rem;
  outline: none;
  appearance: none;
  border: none;
  height: 40px;
  margin-right: 5px;
  border-radius: 20px;
  padding: 0 25px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.border};
`;

const CreateHousingBox = styled(Box)`
  grid-area: createUser;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
`;

// TODO: SET LOADING AND ERROR
const Housings = () => {
  const { housings, loading, error } = useSelector(state => state.housings);
  const [active, setActive] = useState(false);
  const [search, setSearch] = useState('');

  return (
    <main>
      {(() => {
        if (loading) {
          return <Loader active />;
        }
        if (error) {
          return <p>Ett fel har uppstått, ganska konstigt va</p>;
        }
        if (!housings) {
          return <p>Jag kunde inte hitta något hehe</p>;
        }
        return (
          <Container>
            <Menu active={active}>
              <SearchBox>
                <Search>
                  <i className="icon-search" />
                  <input
                    type="text"
                    placeholder="Sök efter bostad..."
                    onChange={e => setSearch(e.target.value.trim().replace(/\s+/g, ' '))}
                  />
                </Search>
              </SearchBox>
              <CreateButtonBox>
                <CreateButton onClick={() => setActive(true)}>Skapa bostad</CreateButton>
              </CreateButtonBox>
              {active && (
                <CreateHousingBox>
                  <CreateHousing setActive={setActive} />
                </CreateHousingBox>
              )}
              <Labels>
                <Box />
                <InfoBox>
                  <i>Namn</i>
                  <i>Pris</i>
                  <i>Bäddar</i>
                  <i>Storlek</i>
                  <i>Egenskaper</i>
                </InfoBox>
                <Box>
                  <i>Hantera</i>
                </Box>
              </Labels>
            </Menu>
            {(() => {
              if (loading) {
                return <Loader active />;
              }
              return (
                <ListHousings>
                  {housings
                    .filter(d => `${d.name.toLowerCase()}`.includes(search.toLowerCase()))
                    .sort((e, e1) => parseInt(e.id, 10) > parseInt(e1.id, 10))
                    .map(d => (
                      <Housing key={d.id} data={d} />
                    ))}
                </ListHousings>
              );
            })()}
          </Container>
        );
      })()}
    </main>
  );
};

export default Housings;
