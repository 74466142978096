import { ERR_USERS, LOADING_USERS, INIT_USERS, UPDATE_USER, ADD_USER, DEL_USER } from './actions';

const initValue = {
  users: [],
  loading: false,
  error: null
};

const users = (state = initValue, action) => {
  switch (action.type) {
    case ERR_USERS: {
      state.error = action.payload.error;
      return { ...state };
    }

    case LOADING_USERS: {
      state.loading = action.payload.loading;
      return { ...state };
    }

    case INIT_USERS: {
      state.users = action.payload.users ? [...state.users, ...action.payload.users] : [...state.users];
      return { ...state };
    }

    case UPDATE_USER: {
      const { user } = action.payload;

      state.users = state.users.map(e => (e.id === user.id ? user : e));
      return { ...state };
    }

    case ADD_USER: {
      const { user } = action.payload;
      // Add if it is not in interval
      state.users = [...state.users, user];
      return { ...state };
    }

    case DEL_USER: {
      const { user } = action.payload;
      state.users = state.users.filter(e => e.id !== user.id);
      return { ...state };
    }

    default:
      return state;
  }
};

export default users;


