export const ADD_ERR = 'ADD_ERR';
export const POP_ERR = 'POP_ERR';

let id = 0;

export const addErr = text => {
  id += 1;
  return {
    type: ADD_ERR,
    payload: { text, id, type: 'error' }
  };
};

export const addMsg = text => {
  id += 1;
  return {
    type: ADD_ERR,
    payload: { text, id, type: 'notification' }
  };
};

export const popErr = () => ({ type: POP_ERR });
