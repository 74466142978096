import React from 'react';
import styled from 'styled-components';
import TopBar from './TopBar';
import SideBar from './SideBar';

const Container = styled.div`
  z-index: 10000;
`;

const NavBar = () => {
  return (
    <Container>
      <TopBar />
      <SideBar />
    </Container>
  );
};

export default NavBar;
