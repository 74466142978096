import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import useReactRouter from 'use-react-router';
import { useMutation } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { Dimensions, Colors, Fonts } from 'fowlit';
import Item from '../Components/Basket/Item';
import Form from '../Components/Checkout/Form';
import { addErr, addMsg } from '../Reducers/Error/actions';
import { deleteBasket } from '../Reducers/Basket/actions';

const Container = styled.form`
  display: grid;
  grid-template-columns: 1fr 1000px 1fr 370px;
  grid-template-rows: calc(100vh - ${Dimensions.navHeight});
  grid-template-areas: '. left . right';
`;

const Left = styled.div`
  grid-area: left;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr;
  color: ${props => props.theme.color};
  border-bottom: 2px solid ${props => props.theme.border};
  padding: 50px;
`;

const Right = styled.div`
  grid-area: right;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 1fr 80px repeat(3, 40px) repeat(2, 70px);
  background: ${props => props.theme.body};
  border-left: 2px solid ${props => props.theme.border};
  padding: 50px 0;
  color: ${props => props.theme.color};
`;

const Cart = styled.h5`
  padding: 0 50px;
`;

const Summary = styled.h5`
  padding: 0 50px;
  display: flex;
  align-items: flex-end;
  border-top: 2px solid ${props => props.theme.border};
  padding-bottom: 15px;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.body};
  color: ${props => props.theme.color};
  padding: 0 50px;
  i {
    font-family: ${Fonts.text};
    font-size: 0.8rem;
  }
  b {
    font-family: ${Fonts.text};
    font-size: ${Fonts.sizeTiny};
  }
`;

const Items = styled.div`
  overflow-y: auto;
  display: flex;
  flex-flow: column;
  margin-bottom: 15px;
  padding: 0 50px;
`;

const Button = styled.button`
  background: ${Colors.blueberry};
  color: ${Colors.white};
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeSuperTiny};
  outline: none;
  appearance: none;
  border: none;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
`;

const Discount = styled.div`
  display: grid;
  grid-template-columns: ${props => `minmax(20px, ${props.length * 10}px)`} 20px;
  grid-gap: 4px;
  align-items: center;

  input {
    text-align: right;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeSuperTiny};
    background: none;
    color: ${props => props.theme.color};
    appearance: none;
    border: none;
    width: 100%;
    margin: 0;

    &::placeholder {
      color: ${props => props.theme.color};
    }
  }
`;

const CREATE_BOOKING = gql`
  mutation($discount: String!, $person: NewPerson, $company: NewCompany, $comment: String!) {
    createBookingReference(discount: $discount, person: $person, company: $company, comment: $comment) {
      referenceID
    }
  }
`;

// TODO: SET LOADING AND ERROR
const Checkout = () => {
  const basket = useSelector(state => state.basket);
  const [discount, setDiscount] = useState('0');
  const discountEl = useRef('');
  const dispatch = useDispatch();
  const [createBookingReference, { data, loading, error }] = useMutation(CREATE_BOOKING);
  const { history } = useReactRouter();
  const price = basket.map(e => parseInt(e.price, 10) - parseInt(e.discount, 10)).reduce((a, b) => a + b, 0) / 10000;

  useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(addErr(`Kunde inte skapa bokningen.`));
      } else if (data) {
        dispatch(addMsg(`Skapade bokning ${data.createBookingReference.referenceID.toUpperCase()}.`));
        dispatch(deleteBasket());
        history.push(`/bookingReference?referenceID=${data.createBookingReference.referenceID}`);
      }
    }
  }, [data, loading, error, dispatch, history]);

  const onSubmitHandler = async e => {
    e.preventDefault();
    if (parseInt(discount, 10) > price) {
      dispatch(addErr(`Rabatten kan inte vara högre än ${price}.`));
      discountEl.current.focus();
      discountEl.current.select();
    } else if (parseInt(discount, 10) < 0) {
      dispatch(addErr(`Rabatten kan inte vara mindre än ${0}.`));
      discountEl.current.focus();
      discountEl.current.select();
    } else {
      const formData = new FormData(e.target);
      const variables = {
        discount: (discount.length !== 0 ? parseInt(discount, 10) * 10000 : 0).toString(),
        comment: formData.get('comment')
      };
      if (formData.get('customerType') === 'person') {
        variables.person = {
          firstName: formData.get('firstName'),
          lastName: formData.get('lastName'),
          email: formData.get('email'),
          phone: formData.get('phone'),
          address: formData.get('address'),
          zipCode: formData.get('zipCode'),
          city: formData.get('city'),
          country: formData.get('country')
        };
      } else {
        variables.company = {
          email: formData.get('email'),
          name: formData.get('companyName'),
          phone: formData.get('phone'),
          address: formData.get('address'),
          zipCode: formData.get('zipCode'),
          city: formData.get('city'),
          country: formData.get('country'),
          organizationNr: formData.get('organizationNr'),
          reference: formData.get('reference')
        };
      }
      await createBookingReference({ variables });
    }
  };

  return (
    <main>
      {(() => {
        if (basket.length === 0) {
          history.push('/basket');
        }
        return (
          <Container onSubmit={onSubmitHandler} autoComplete="off">
            <Left>
              <h5>Bokningsinformation</h5>
              <Form />
            </Left>
            <Right>
              <Cart>Varukorg</Cart>
              <Items>
                {basket.map(d => (
                  <Item key={d.id} data={d} />
                ))}
              </Items>
              <Summary>Sammanställning</Summary>
              <Row>
                <i>Antal</i>
                <h6>{basket.length}</h6>
              </Row>
              <Row>
                <i>Rabatt</i>
                <Discount length={discount.length}>
                  <input
                    ref={discountEl}
                    type="text"
                    value={discount}
                    onChange={e => setDiscount(e.target.value)}
                    pattern="[0-9]+"
                    placeholder="0"
                  />
                  <h6>kr</h6>
                </Discount>
              </Row>
              <Row>
                <i>Moms</i>
                <h6>12 %</h6>
              </Row>
              <Row>
                <b>Totalt</b>
                <h5>
                  {`${basket.map(e => parseInt(e.price, 10) - parseInt(e.discount, 10)).reduce((a, b) => a + b, 0) /
                    10000 -
                    discount} kr`}
                </h5>
              </Row>
              <Row>
                <Button type="submit">Slutför bokning</Button>
              </Row>
            </Right>
          </Container>
        );
      })()}
    </main>
  );
};

export default Checkout;
