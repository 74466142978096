import { ADD_PRODUCT, SET_GUESTS, SET_DISCOUNT, DELETE_BASKET, DEL_PRODUCT, INIT_BASKET } from './actions';

const basket = (state = [], action) => {
  switch (action.type) {
    case INIT_BASKET: {
      return action.payload.basket;
    }

    case ADD_PRODUCT: {
      // Add if it is not in interval
      return [...state, action.payload];
    }

    case SET_GUESTS: {
      return state.map(e => {
        if (e.id === action.payload.id) {
          e.guests = action.payload.nr;
        }
        return e;
      });
    }

    case SET_DISCOUNT: {
      return state.map(e => {
        if (e.id === action.payload.id) {
          e.discount = action.payload.discount;
        }
        return e;
      });
    }

    case DEL_PRODUCT:
      return state.length > 0 ? state.filter(e => e.id !== action.payload.id) : state;

    case DELETE_BASKET:
      return [];
    default:
      return state;
  }
};

export default basket;
