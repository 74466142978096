export const INIT_CURRENT_BOOKINGS = 'INIT_CURRENT_BOOKINGS';
export const UPDATE_CURRENT_BOOKING = 'UPDATE_CURRENT_BOOKING';
export const ADD_CURRENT_BOOKING = 'ADD_CURRENT_BOOKING';
export const DEL_CURRENT_BOOKING = 'DEL_CURRENT_BOOKING';
export const ERR_CURRENT_BOOKING = 'ERR_CURRENT_BOOKING';
export const LOADING_CURRENT_BOOKING = 'LOADING_CURRENT_BOOKING';

export const errorCurrentBooking = error => ({
  type: ERR_CURRENT_BOOKING,
  payload: {
    error
  }
});

export const loadingCurrentBooking = loading => ({
  type: LOADING_CURRENT_BOOKING,
  payload: {
    loading
  }
});

export const initCurrentBookings = bookings => ({
  type: INIT_CURRENT_BOOKINGS,
  payload: {
    bookings
  }
});

export const updateCurrentBooking = booking => ({
  type: UPDATE_CURRENT_BOOKING,
  payload: {
    booking
  }
});

export const addCurrentBooking = booking => ({
  type: ADD_CURRENT_BOOKING,
  payload: {
    booking
  }
});

export const deleteCurrentBooking = booking => ({
  type: DEL_CURRENT_BOOKING,
  payload: {
    booking
  }
});
