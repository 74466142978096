export const FETCH_PROFILE_LOADING = 'FETCH_PROFILE_LOADING';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const fetchProfileLoading = () => ({
  type: FETCH_PROFILE_LOADING
});

export const fetchProfileSuccess = userInfo => ({
  type: FETCH_PROFILE_SUCCESS,
  payload: { userInfo }
});

export const fetchProfileError = error => ({
  type: FETCH_PROFILE_ERROR,
  error
});

export const updateProfile = userInfo => ({
  type: UPDATE_PROFILE,
  payload: { userInfo }
});
