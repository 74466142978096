const isArray = value => {
  return value && typeof value === 'object' && value.constructor === Array;
};

export const encodeURL = (url, params) => {
  const createdParams = Object.keys(params)
    .map(key => {
      const param = params[key];

      return `${key}=${isArray(param) ? param.join(' ') : encodeURIComponent(param)}`;
    })
    .join('&');

  return `${url}?${createdParams}`;
};

export const getRandomString = len => {
  let res = '';
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = chars.length;

  for (let i = 0; i < len; i += 1) {
    res += chars.charAt(Math.floor(Math.random() * charactersLength));
  }
  return res;
};

export const logout = () => {
  (async () => {
    await fetch('/session/logout', {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
    });
    localStorage.removeItem('sessionToken');
    window.location.reload();
  })();
};

export const ISODateString = d => {
  const pad = n => (n < 10 ? `0${n}` : n);

  return `${d.getUTCFullYear()}-${pad(d.getUTCMonth() + 1)}-${pad(d.getUTCDate())}T${pad(d.getUTCHours())}:${pad(
    d.getUTCMinutes()
  )}:${pad(d.getUTCSeconds())}Z`;
};

export const rgba = (o, h) =>
  `rgba(${parseInt(h.slice(1, 3), 16)},${parseInt(h.slice(3, 5), 16)},${parseInt(h.slice(5, 7), 16)},${o})`;

export const setImage = e => {
  if (e.image && e.image.substring(0, 5) !== 'data:') {
    e.image = e.image.length === 0 ? '' : `data:image;base64,${e.image}`;
  }
  return e;
};