import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import {
  addHousing,
  deleteHousing,
  errorHousings,
  initHousings,
  loadingHousings,
  updateHousing
} from '../Reducers/Housings/actions';
import { setImage } from "../Library";

const GET_HOUSINGS = gql`
  {
    getHousings {
      userID
      id
      image
      type
      name
      beds
      size
      price
      wifi
      kitchen
      electricity
      tv
      active
    }
  }
`;

const HOUSING_ADDED = gql`
  subscription {
    housingAdded {
      userID
      id
      image
      type
      name
      beds
      size
      price
      wifi
      kitchen
      electricity
      tv
      active
    }
  }
`;

const HOUSING_EDITED = gql`
  subscription {
    housingEdited {
      userID
      id
      image
      type
      name
      beds
      size
      price
      wifi
      kitchen
      electricity
      tv
      active
    }
  }
`;

const HOUSING_DELETED = gql`
  subscription {
    housingDeleted
  }
`;


const Housings = () => {
  const dispatch = useDispatch();

  const housingAdded = useSubscription(HOUSING_ADDED);
  const housingEdited = useSubscription(HOUSING_EDITED);
  const housingDeleted = useSubscription(HOUSING_DELETED);
  const getHousings = useQuery(GET_HOUSINGS);

  useEffect(() => {
    const { data, loading, error } = getHousings;
    if (loading) {
      dispatch(loadingHousings(true));
    } else if (error) {
      dispatch(errorHousings(error));
    } else if (data) {
      dispatch(loadingHousings(false));
      dispatch(initHousings(data.getHousings.map(setImage)));
    }
  }, [dispatch, getHousings]);

  useEffect(() => {
    if (housingAdded.data) {
      dispatch(addHousing(setImage(housingAdded.data.housingAdded)));
    }
  }, [dispatch, housingAdded.data]);

  useEffect(() => {
    if (housingEdited.data) {
      dispatch(updateHousing(setImage(housingEdited.data.housingEdited)));
    }
  }, [dispatch, housingEdited.data]);

  useEffect(() => {
    if (housingDeleted.data) {
      dispatch(deleteHousing(housingDeleted.data.housingDeleted));
    }
  }, [dispatch, housingDeleted.data]);

  return <></>;
};

export default Housings;
