export const INIT_LATEST_BOOKINGS = 'INIT_LATEST_BOOKINGS';
export const UPDATE_LATEST_BOOKING = 'UPDATE_LATEST_BOOKING';
export const ADD_LATEST_BOOKING = 'ADD_LATEST_BOOKING';
export const DEL_LATEST_BOOKING = 'DEL_LATEST_BOOKING';
export const ERR_LATEST_BOOKING = 'ERR_LATEST_BOOKING';
export const LOADING_LATEST_BOOKING = 'LOADING_LATEST_BOOKING';

export const errorLatestBooking = error => ({
  type: ERR_LATEST_BOOKING,
  payload: {
    error
  }
});

export const loadingLatestBooking = loading => ({
  type: LOADING_LATEST_BOOKING,
  payload: {
    loading
  }
});

export const initLatestBookings = bookings => ({
  type: INIT_LATEST_BOOKINGS,
  payload: {
    bookings
  }
});

export const updateLatestBooking = booking => ({
  type: UPDATE_LATEST_BOOKING,
  payload: {
    booking
  }
});

export const addLatestBooking = booking => ({
  type: ADD_LATEST_BOOKING,
  payload: {
    booking
  }
});

export const deleteLatestBooking = booking => ({
  type: DEL_LATEST_BOOKING,
  payload: {
    booking
  }
});
