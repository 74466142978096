import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BigItem } from 'Components';
import { useSelector } from 'react-redux';
import { Dimensions, Colors, Fonts } from 'fowlit';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1000px 1fr 370px;
  grid-template-rows: calc(100vh - ${Dimensions.navHeight});
  grid-template-areas: '. left . right';
`;

const Left = styled.div`
  grid-area: left;
  padding: 50px 50px 0 50px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
  color: ${props => props.theme.color};
`;

const Right = styled.div`
  grid-area: right;
  display: ${props => (props.hidden ? 'none' : 'grid')};
  grid-template-columns: 1fr;
  grid-template-rows: 50px repeat(3, 40px) repeat(2, 70px);
  background: ${props => props.theme.body};
  border-left: 2px solid ${props => props.theme.border};
  padding: 50px;
  color: ${props => props.theme.color};
`;

const Row = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.body};
  color: ${props => props.theme.color};
  i {
    font-family: ${Fonts.text};
    font-size: 0.8rem;
  }
  b {
    font-family: ${Fonts.text};
    font-size: ${Fonts.sizeTiny};
  }
`;

const Items = styled.div`
  overflow-y: auto;
  display: flex;
  flex-flow: column;
`;

const Button = styled(Link)`
  background: ${Colors.blueberry};
  color: ${Colors.white};
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeSuperTiny};
  outline: none;
  appearance: none;
  border: none;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// TODO: SET LOADING AND ERROR
const Basket = () => {
  const basket = useSelector(state => state.basket);
  return (
    <main>
      <Container>
        <Left>
          <h5>Varukorg</h5>
          {(() => {
            if (basket.length === 0) {
              return <p>Din varukorg är tom.</p>;
            }
            return (
              <Items>
                {basket.map(d => (
                  <BigItem key={d.id} data={d} />
                ))}
              </Items>
            );
          })()}
        </Left>
        <Right hidden={basket.length === 0}>
          <h5>Sammanställning</h5>
          <Row>
            <i>Antal</i>
            <h6>{basket.length}</h6>
          </Row>
          <Row>
            <i>Moms</i>
            <h6>12 %</h6>
          </Row>
          <Row>
            <b>Totalt</b>
            <h5>
              {`${basket.map(e => parseInt(e.price, 10) - parseInt(e.discount, 10)).reduce((a, b) => a + b, 0) /
                10000} kr`}
            </h5>
          </Row>
          <Row>
            <Button to="/checkout">Boka nu</Button>
          </Row>
        </Right>
      </Container>
    </main>
  );
};

export default Basket;

/**
 * <Container>
 <Menu />

 {(() => {
   return (
     <ListBasketItems>
              {basket.map(d => (
                <BasketItem key={d.id} data={d} />
              ))}
            </ListBasketItems>
          );
        })()}
 </Container>
 <Checkout
 count={basket.length}
 price={basket
          .map(e => Math.abs((e.startTime.getTime() - e.endTime.getTime()) / (oneDay * 10000)) * parseInt(e.price, 10))
          .reduce((a, b) => a + b, 0)}
 />
 */

/**
 * <Checkout
 count={basket.length}
 price={basket
            .map(
              e => Math.abs((e.startTime.getTime() - e.endTime.getTime()) / (oneDay * 10000)) * parseInt(e.price, 10)
            )
            .reduce((a, b) => a + b, 0)}
 />
 */
