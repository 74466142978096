import gql from 'graphql-tag';

import client from '../../Apollo';
import { addErr } from '../Error/actions';

export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DEL_PRODUCT = 'DEL_PRODUCT';
export const SET_DISCOUNT = 'SET_DISCOUNT';
export const SET_GUESTS = 'SET_GUESTS';
export const INIT_BASKET = 'INIT_BASKET';
export const DELETE_BASKET = 'DELETE_BASKET';

const setImage = e => {
  if (e.image !== null && e.image.substring(0, 5) !== 'data:') {
    e.image = e.image.length === 0 ? '' : `data:image;base64,${e.image}`;
  }
  return e;
};

export const addProduct = (product, setSuccess, setID) => {
  return async dispatch => {
    try {
      const ADD_TO_BASKET = gql`
        mutation($itemID: ID!, $itemType: ItemType!, $startTime: String!, $endTime: String!) {
          addToBasket(item: { itemID: $itemID, itemType: $itemType, startTime: $startTime, endTime: $endTime }) {
            id
            name
            price
            discount
            image
            itemID
            itemType
            startTime
            endTime
            guests
          }
        }
      `;

      const resp = await client.mutate({
        mutation: ADD_TO_BASKET,
        variables: {
          itemID: product.housingID,
          itemType: 'HOUSING',
          startTime: product.startTime,
          endTime: product.endTime
        }
      });

      setID(resp.data.addToBasket.id);
      const e1 = product;
      e1.startTime = new Date(Date.parse(product.startTime));
      e1.endTime = new Date(Date.parse(product.endTime));
      setSuccess(true);
      return dispatch({
        type: ADD_PRODUCT,
        payload: { ...setImage(resp.data.addToBasket) }
      });
    } catch (e) {
      console.log(e);
      dispatch(addErr('Kunde inte ansluta till servern.'));
      return null;
    }
  };
};

export const setDiscount = variables => {
  return async dispatch => {
    try {
      const SET_DISCOUNT_GQL = gql`
        mutation($id: ID!, $discount: String!) {
          setDiscount(id: $id, discount: $discount)
        }
      `;
      await client.mutate({
        mutation: SET_DISCOUNT_GQL,
        variables
      });

      return dispatch({
        type: SET_DISCOUNT,
        payload: variables
      });
    } catch (e) {
      console.log(e);
      dispatch(addErr('Kunde inte ansluta till servern.'));
      return null;
    }
  };
};

export const setGuests = variables => {
  return async dispatch => {
    try {
      const SET_GUESTS_GQL = gql`
        mutation($id: ID!, $nr: Int!) {
          setGuests(id: $id, nr: $nr)
        }
      `;
      await client.mutate({
        mutation: SET_GUESTS_GQL,
        variables
      });

      return dispatch({
        type: SET_GUESTS,
        payload: variables
      });
    } catch (e) {
      console.log(e);
      dispatch(addErr('Kunde inte ansluta till servern.'));
      return null;
    }
  };
};

export const deleteProduct = (id, setSuccess, setID) => {
  return async dispatch => {
    try {
      const REMOVE_FROM_BASKET = gql`
        mutation($id: ID!) {
          removeFromBasket(id: $id)
        }
      `;
      await client.mutate({
        mutation: REMOVE_FROM_BASKET,
        variables: {
          id
        }
      });
      if (setSuccess) {
        setSuccess(true);
      }
      if (setID) {
        setID(id);
      }

      return dispatch({
        type: DEL_PRODUCT,
        payload: { id }
      });
    } catch (e) {
      console.log(e);
      dispatch(addErr('Kunde inte ansluta till servern.'));
      return null;
    }
  };
};

export const initBasket = b => {
  if (b) {
    return {
      type: INIT_BASKET,
      payload: {
        basket: b.map(e => {
          const e1 = e;
          e1.startTime = new Date(Date.parse(e.startTime));
          e1.endTime = new Date(Date.parse(e.endTime));
          return e1;
        })
      }
    };
  }
  return {
    type: INIT_BASKET,
    payload: {
      basket: []
    }
  };
};

export const deleteBasket = () => ({
  type: DELETE_BASKET
});
