import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Colors, Dimensions, Fonts, Media, Regexp } from 'fowlit';
import gql from 'graphql-tag';
import { Input } from 'Components/Forms';
import { useMutation } from '@apollo/react-hooks';
import { ImagePicker } from 'Components';
import { updateProfile } from '../Reducers/Profile/actions';
import { addErr, addMsg } from '../Reducers/Error/actions';
import { setImage } from '../Library';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0;
  color: ${props => props.theme.color};
  user-select: none;
`;

const Module = styled.div`
  display: grid;
  grid-template-columns: ${Media.phone};
  grid-template-rows: 200px 100px 100px 1fr;
  grid-template-areas: 'top' 'mid' 'bot' 'edit';
  @media (max-width: ${Media.phone}) {
    grid-template-columns: calc(100vw - ${Dimensions.navHeight} - ${Dimensions.navHeight});
  }
`;

const template = styled.div`
  background: ${props => props.theme.background};
  border: 2px solid ${props => props.theme.border};
`;

const Top = styled.div`
  grid-area: top;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
  border-radius: ${Dimensions.radius} ${Dimensions.radius} 0 0;
`;

const TopTop = styled.div`
  grid-row: 1;
  display: flex;
  justify-content: center;
`;

const Image = styled.div`
  background: ${props => `url('${props.src}')`} no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 125px;
  width: 125px;
  position: relative;
  z-index: 2;
  border: 2px solid ${props => props.theme.border};
`;

const Initials = styled.div`
  background: linear-gradient(
    to bottom left,
    ${Colors.blueberryLighter},
    ${Colors.blueberry},
    ${Colors.blueberryDarker}
  );
  color: ${Colors.white};
  border-radius: 50%;
  height: 125px;
  width: 125px;
  z-index: 2;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: ${Fonts.head};
  font-size: ${Fonts.sizeRegular};
  position: relative;
  border: 2px solid ${props => props.theme.border};
`;

const TopBot = styled(template)`
  grid-row: 2 / auto-fill;
  border-radius: ${Dimensions.radius} ${Dimensions.radius} 0 0;
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 20px;
  i {
    color: ${props => props.theme.subColor};
    font-family: ${Fonts.text};
    font-size: 0.8rem;
  }
`;

const Mid = styled(template)`
  grid-area: mid;
  padding: 0 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  align-items: center;
  border-top-color: transparent;
  border-bottom-color: transparent;
`;

const Data = styled.div`
  display: flex;
  flex-flow: column;

  i {
    color: ${props => props.theme.subColor};
    font-family: ${Fonts.text};
    font-size: 0.8rem;
  }
`;

const Bot = styled(template)`
  grid-area: bot;
  border-radius: ${props => (props.active ? 0 : `0 0 ${Dimensions.radius} ${Dimensions.radius}`)};
  border-bottom-color: ${props => (props.active ? 'transparent' : props.theme.border)};
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h6 {
    margin-right: 20px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  appearance: none;
  border: none;
`;

const Close = styled(Button)`
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  &:focus {
    background: ${Colors.strawberry};
    color: ${Colors.white};
  }
`;

const Save = styled.button`
  color: ${Colors.white};
  font-family: ${Fonts.text};
  font-size: 0.8rem;
  outline: none;
  appearance: none;
  border: none;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  padding: 0 30px;
  background: ${Colors.blueberry};
  &:hover {
    background: ${Colors.blueberryDarker};
  }
  &:focus {
    background: ${Colors.blueberryDarker};
  }
`;

const Edit = styled(Button)`
  background: ${Colors.blueberry};
  color: ${Colors.white};
`;

const EditBox = styled.form`
  position: relative;
  grid-area: edit;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 50px;
  grid-template-areas: 'information password' 'save save';
  grid-gap: 30px;
  align-items: center;
  border-radius: 0 0 ${Dimensions.radius} ${Dimensions.radius};
  border-top-color: transparent;
  background: ${props => props.theme.background};
  border: 2px solid ${props => props.theme.border};
  input {
    width: 100%;
  }
  @media (max-width: ${Media.phone}) {
    grid-template-areas: 'information' 'password' 'button';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 50px;
    grid-gap: 0;
  }
`;

const ChangeInformation = styled.div`
  grid-area: information;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: 30px repeat(3, 1fr);
`;

const ChangePassword = styled.div`
  grid-area: password;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: 30px repeat(3, 1fr);
`;

const SaveBox = styled.div`
  grid-area: save;
  display: flex;
  justify-content: flex-end;
`;

const CHANGE_INFO = gql`
  mutation($firstName: String!, $lastName: String!) {
    editUserInfo(firstName: $firstName, lastName: $lastName) {
      id
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation($password: String!, $newPassword: String!) {
    changePassword(password: $password, newPassword: $newPassword)
  }
`;

const UPDATE_USER_IMAGE = gql`
  mutation($image: String) {
    updateUserImage(image: $image) {
      image
    }
  }
`;

const DELETE_USER_IMAGE = gql`
  mutation {
    deleteUserImage {
      image
    }
  }
`;

const Profile = () => {
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const { userInfo } = useSelector(state => state.profile);
  const [firstName, setFirstName] = useState(
    `${userInfo.firstName.charAt(0).toUpperCase() + userInfo.firstName.slice(1)}`
  );

  const [changeInfo, ciprops] = useMutation(CHANGE_INFO);
  const [changePassword, cpprops] = useMutation(CHANGE_PASSWORD);

  const [lastName, setLastName] = useState(`${userInfo.lastName.charAt(0).toUpperCase() + userInfo.lastName.slice(1)}`);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const initials = userInfo.firstName.charAt(0) + userInfo.lastName.charAt(0);
  const name = `${userInfo.firstName.charAt(0).toUpperCase() + userInfo.firstName.slice(1)} ${userInfo.lastName
    .charAt(0)
    .toUpperCase()}${userInfo.lastName.slice(1)}`;

  const getRole = () => {
    switch (userInfo.role) {
      case 'admin':
        return 'Admin';
      case 'worker':
        return 'Arbetare';
      default:
        return 'Not yet implemented';
    }
  };

  useEffect(() => {
    if (!ciprops.loading) {
      if (ciprops.error) {
        dispatch(addErr('Kunde inte ansluta till servern.'));
      } else if (ciprops.data) {
        dispatch(
          updateProfile({
            ...(firstName.length !== 0 && { firstName }),
            ...(lastName.length !== 0 && { lastName })
          })
        );
        if (currentPassword === '' && newPassword === '' && confirmPassword === '') {
          setActive(false);
        }
        dispatch(addMsg('Uppdaterade användarinformationen!'));
      }
    }
  }, [dispatch, ciprops.error, ciprops.loading, ciprops.data]);

  useEffect(() => {
    if (!cpprops.loading) {
      if (cpprops.error) {
        dispatch(addErr('Kunde inte ansluta till servern.'));
      } else if (cpprops.data) {
        dispatch(addMsg('Uppdaterade lösenordet!'));
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setActive(false);
      }
    }
  }, [dispatch, cpprops.error, cpprops.loading, cpprops.data]);

  const onFormSubmit = async e => {
    e.preventDefault();
    if (
      firstName.toLowerCase() !== userInfo.firstName.toLowerCase() ||
      lastName.toLowerCase() !== userInfo.lastName.toLowerCase()
    ) {
      await changeInfo({
        variables: {
          firstName: firstName.length === 0 ? userInfo.firstName : firstName,
          lastName: lastName.length === 0 ? userInfo.lastName : lastName
        }
      });
    }
    if (currentPassword !== '' && newPassword !== '' && confirmPassword !== '' && newPassword === confirmPassword) {
      await changePassword({
        variables: {
          password: currentPassword,
          newPassword
        }
      });
    }
    if (newPassword !== confirmPassword) {
      dispatch(addErr('Det nya lösenordet stämmer inte överens det bekräftade.'));
      setActive(true);
    }
  };

  return (
    <main>
      <Container>
        <Module>
          <Top>
            <TopTop>
              {(() => {
                if (userInfo.image === null) {
                  return (
                    <ImagePicker
                      Image={Initials}
                      text={initials}
                      onUpdate={UPDATE_USER_IMAGE}
                      onDelete={DELETE_USER_IMAGE}
                      onSuccess={e =>
                        dispatch(
                          updateProfile(
                            setImage({
                              image: e.updateUserImage ? e.updateUserImage.image : e.deleteUserImage.image
                            })
                          )
                        )
                      }
                    />
                  );
                }
                return (
                  <ImagePicker
                    Image={Image}
                    src={userInfo.image}
                    onUpdate={UPDATE_USER_IMAGE}
                    onDelete={DELETE_USER_IMAGE}
                    onSuccess={e =>
                      dispatch(
                        updateProfile(
                          setImage({
                            image: e.updateUserImage ? e.updateUserImage.image : e.deleteUserImage.image
                          })
                        )
                      )
                    }
                  />
                );
              })()}
            </TopTop>
            <TopBot>
              <h6>{name}</h6>
              <i>{userInfo.username}</i>
            </TopBot>
          </Top>
          <Mid>
            <Data>
              <i>Roll</i>
              <h6>{getRole()}</h6>
            </Data>
            <Data>
              <i>Skapad</i>
              <h6>
                {new Date(userInfo.createdAt).toLocaleDateString('sv-SV', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                })}
              </h6>
            </Data>
          </Mid>
          <Bot active={active}>
            <h6 hidden={!active}>Redigerar profil</h6>
            {!active ? (
              <Edit className="icon-edit" onClick={() => setActive(a => !a)} />
            ) : (
              <Close className="icon-cancel-circled" onClick={() => setActive(a => !a)} />
            )}
          </Bot>
          {!active ? (
            <></>
          ) : (
            <EditBox id="form" onSubmit={onFormSubmit}>
              <ChangeInformation>
                <h6>Användarinformation</h6>
                <Input
                  type="text"
                  label="Förnamn"
                  value={firstName}
                  setValue={e => setFirstName(e)}
                  pattern={Regexp.name}
                  minLength={1}
                  maxLength={50}
                />
                <Input
                  type="text"
                  label="Efternamn"
                  value={lastName}
                  setValue={e => setLastName(e)}
                  pattern={Regexp.name}
                  minLength={1}
                  maxLength={50}
                />
              </ChangeInformation>
              <ChangePassword>
                <h6>Byt lösenord</h6>
                <Input
                  type="password"
                  label="Nuvarande lösenord"
                  value={currentPassword}
                  setValue={e => setCurrentPassword(e)}
                  pattern={Regexp.password}
                  minLength={8}
                  maxLength={256}
                />
                <Input
                  type="password"
                  label="Nytt lösenord"
                  value={newPassword}
                  setValue={e => setNewPassword(e)}
                  pattern={Regexp.password}
                  minLength={8}
                  maxLength={256}
                />
                <Input
                  type="password"
                  label="Bekräfta nytt lösenord"
                  value={confirmPassword}
                  setValue={e => setConfirmPassword(e)}
                  pattern={Regexp.password}
                  minLength={8}
                  maxLength={256}
                />
              </ChangePassword>
              <SaveBox>
                <Save type="submit" form="form">
                  Spara
                </Save>
              </SaveBox>
            </EditBox>
          )}
        </Module>
      </Container>
    </main>
  );
};

export default Profile;
