import React, { forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fonts } from 'fowlit';

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 50px;
  border-radius: 5px;
  color: ${props => props.theme.color};
  height: 50px;
  align-items: center;
  padding: 0 10px;
`;

const Check = styled.input`
  outline-color: none;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  font-size: ${Fonts.sizeSuperTiny};
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  color: ${props => props.theme.color};
`;

const Label = styled.label`
  font-size: ${Fonts.sizeSuperTiny};
  font-weight: ${Fonts.weightRegular};
  font-family: ${Fonts.text};
  transition: 300ms ease-out;
  line-height: 50px;
`;

const Checkbox = forwardRef(({ label, name, value, setValue }, ref) => {
  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <Check ref={ref} type="checkbox" name={name} checked={value} onChange={() => setValue(e => !e)} />
    </Container>
  );
});

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired
};

export default Checkbox;
