import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Colors, Dimensions, Fonts } from 'fowlit';
import { useDispatch, useSelector } from 'react-redux';

import { CreateUser, Loader, User } from 'Components';
import { addErr } from 'Reducers/Error/actions';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const Menu = styled.div`
  position: sticky;
  top: ${props => (props.active ? Dimensions.navHeight : '-30px')};
  width: 100%;
  z-index: 1;
  margin-bottom: 5px;
  background: ${props => props.theme.main};
  display: grid;
  grid-template-columns: 1fr 501px 501px 1fr;
  grid-template-rows: 100px 1fr 50px;
  grid-template-areas: '. search createButton .' '. createUser createUser .' '. label label .';
`;

const Labels = styled.div`
  grid-area: label;
  display: grid;
  grid-template-columns: 100px 650px 250px;
  grid-template-rows: 50px;
  i {
    color: ${props => props.theme.color};
    font-family: ${Fonts.text};
    font-size: 0.7rem;
    font-weight: ${Fonts.weightBold};
    text-transform: uppercase;
  }
`;

const ListUsers = styled.div`
  grid-area: list;
  position: relative;
`;

const Box = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const InfoBox = styled(Box)`
  display: grid;
  grid-template-columns: 1.3fr 0.85fr 0.85fr;
`;

const SearchBox = styled(Box)`
  grid-area: search;
  align-items: flex-end;
`;

const Search = styled.div`
  background: ${props => props.theme.background};
  width: 70%;
  height: 40px;
  margin-left: 5px;
  border-radius: 20px;
  border: 2px solid ${props => props.theme.border};
  display: grid;
  grid-template-columns: 40px 1fr;
  i {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.blueberry};
  }
  input {
    outline: none;
    appearance: none;
    border: none;
    border-radius: 20px;
    background: none;
    font-family: ${Fonts.text};
    font-size: 0.8rem;
    color: ${props => props.theme.color};
  }
`;

const CreateButtonBox = styled(Box)`
  grid-area: createButton;
  align-items: flex-end;
  justify-content: flex-end;
`;

const CreateButton = styled.button`
  background: ${Colors.blueberry};
  color: ${Colors.white};
  font-family: ${Fonts.text};
  font-size: 0.8rem;
  outline: none;
  appearance: none;
  height: 40px;
  margin-right: 5px;
  border-radius: 20px;
  padding: 0 25px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.border};
`;

const CreateUserBox = styled(Box)`
  grid-area: createUser;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
`;

const Users = () => {
  const { users, loading, error } = useSelector(state => state.users);
  const [createUserIsShowing, setCreateUserIsShowing] = useState(false);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch(addErr('Kunde inte ansluta till servern'));
    }
  }, [dispatch, error]);

  return (
    <main>
      <Container>
        <Menu active={createUserIsShowing}>
          <SearchBox>
            <Search>
              <i className="icon-search" />
              <input
                type="text"
                placeholder="Sök efter användare..."
                onChange={e => setSearch(e.target.value.trim().replace(/\s+/g, ' '))}
              />
            </Search>
          </SearchBox>
          <CreateButtonBox>
            <CreateButton onClick={() => setCreateUserIsShowing(true)}>Skapa användare</CreateButton>
          </CreateButtonBox>
          {createUserIsShowing && (
            <CreateUserBox>
              <CreateUser setActive={setCreateUserIsShowing} />
            </CreateUserBox>
          )}
          <Labels>
            <Box />
            <InfoBox>
              <i>Användare</i>
              <i>Roll</i>
              <i>Senast inloggad</i>
            </InfoBox>
            <Box>
              <i>Hantera</i>
            </Box>
          </Labels>
        </Menu>
        {(() => {
          if (loading) {
            return <Loader active />;
          }
          return (
            <ListUsers>
              {users
                .filter(d => `${d.firstName} ${d.lastName}`.includes(search) || d.username.includes(search))
                .map(d => (
                  <User key={d.id} data={d} />
                ))}
            </ListUsers>
          );
        })()}
      </Container>
    </main>
  );
};

export default Users;
