import React, { useState, useEffect, forwardRef, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Loader, PopOverForm, BookingInfo, BookedItem } from 'Components';
import { useDispatch, useSelector } from 'react-redux';
import { Dimensions, Colors, Fonts } from 'fowlit';
import useReactRouter from 'use-react-router';
import { ISODateString } from 'Library';
import { addErr, addMsg } from '../Reducers/Error/actions';
import { setImage } from '../Library';
import { BigItem } from '../Components';
import { deleteBasket } from '../Reducers/Basket/actions';
import PageNotFound from './PageNotFound';

const Container = styled.form`
  display: grid;
  grid-template-columns: minmax(800px, 1fr) 370px;
  grid-template-rows: calc(100vh - ${Dimensions.navHeight});
`;

const Left = styled.div`
  padding: 50px 50px 0 50px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr 1.5fr;
  grid-template-areas: 'information' 'items';
  color: ${props => props.theme.color};
`;

const Right = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1.5fr repeat(3, 1fr) 3fr;
  background: ${props => props.theme.body};
  border-left: 2px solid ${props => props.theme.border};
`;

const Information = styled.div`
  grid-area: information;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr 50px;
  grid-gap: 20px;
`;

const Manage = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: flex-end;
  padding: 0 5px;
  h5 {
    display: block;
    width: 100%;
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

const Button = styled.button`
  color: ${Colors.white};
  font-family: ${Fonts.text};
  font-size: 0.8rem;
  outline: none;
  appearance: none;
  border: none;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  padding: 0 30px;
`;

const Edit = styled(Button)`
  background: ${Colors.blueberry};
  &:hover {
    background: ${Colors.blueberryDarker};
  }
`;

const AddFromBasket = styled(Button)`
  background: ${Colors.blueberry};
  &:hover {
    background: ${Colors.blueberryDarker};
  }
`;

const Delete = styled(Button)`
  background: ${Colors.strawberry};
  &:hover {
    background: ${Colors.strawberryDarker};
  }
`;

const Cancel = styled(Button)`
  background: ${Colors.strawberry};
  &:hover {
    background: ${Colors.strawberryDarker};
  }
`;

const Items = styled.div`
  grid-area: items;
  overflow-y: auto;
  display: flex;
  flex-flow: column;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  background: ${props => props.theme.body};
  color: ${props => props.theme.color};
  i {
    font-family: ${Fonts.text};
    font-size: 0.8rem;
  }
  b {
    font-family: ${Fonts.text};
    font-size: ${Fonts.sizeTiny};
  }
`;

const BigRow = styled(Row)`
  border-top: 2px solid ${props => props.theme.border};
`;

const Summary = styled.div`
  border-top: 2px solid ${props => props.theme.border};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, 1fr);
  padding: 50px 0;
`;

const Gradient = styled.div`
  padding: 70px 50px 0 50px;
  color: ${props => props.theme.color};
  /*background: ${`linear-gradient(to bottom right, ${Colors.blueberryDarker}, ${Colors.blueberry}, ${Colors.blueberryDarker});`};*/
`;

const Ref = styled.i`
  font-family: ${Fonts.text};
`;

const SumRef = styled.h6`
  font-family: ${Fonts.text};
`;

const Discount = styled.div`
  display: grid;
  grid-template-columns: ${props => `minmax(20px, ${props.length * 10}px)`} 20px;
  grid-gap: 3px;
  align-items: center;
  input {
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeSuperTiny};
    background: none;
    color: ${props => props.theme.color};
    width: 100%;
    appearance: none;
    border: none;
    overflow-x: hidden;
  }
`;

const RemoveBookingForm = ({ formID, formData, close }) => {
  const dispatch = useDispatch();
  const { history } = useReactRouter();
  const DELETE_BOOKING_REFERENCE = gql`
    mutation($referenceID: ID!) {
      deleteBookingReference(referenceID: $referenceID) {
        referenceID
      }
    }
  `;
  const [deleteBookingReference, { data, loading, error }] = useMutation(DELETE_BOOKING_REFERENCE);

  useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(addErr(`Kunde inte ta bort ${formData.referenceID.toUpperCase()}.`));
      } else if (data) {
        close();
        dispatch(addMsg(`Tog bort bokning ${formData.referenceID.toUpperCase()}.`));
        history.push('/');
      }
    }
  }, [close, dispatch, data, error, loading, formData.id, formData.referenceID, history]);

  return (
    <form
      id={formID}
      onSubmit={e => {
        e.preventDefault();
        deleteBookingReference({ variables: { referenceID: formData.referenceID } });
      }}
    />
  );
};

RemoveBookingForm.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array, PropTypes.object])
  ).isRequired,
  close: PropTypes.func.isRequired
};

const GET_BOOKING = gql`
  fragment PersonParts on Person {
    firstName
    lastName
    email
    phone
    address
    zipCode
    city
    country
    lastPurchase
  }

  fragment CompanyParts on Company {
    organizationNr
    reference
    email
    phone
    address
    zipCode
    city
    country
    lastPurchase
    name
  }

  query($referenceID: String!) {
    getBookingReference(referenceID: $referenceID) {
      bookings {
        id
        itemID
        image
        itemType
        startTime
        endTime
        price
        name
        discount
        addition
        guests
      }
      referenceID
      price
      discount
      date
      userID
      comment
      customer {
        ...PersonParts
        ...CompanyParts
      }
    }
  }
`;

const EDIT_BOOKING_REFERENCE = gql`
  fragment PersonParts on Person {
    firstName
    lastName
    email
    phone
    address
    zipCode
    city
    country
    lastPurchase
  }

  fragment CompanyParts on Company {
    organizationNr
    reference
    email
    phone
    address
    zipCode
    city
    country
    lastPurchase
    name
  }

  mutation($referenceID: ID!, $discount: String!, $person: NewPerson, $company: NewCompany, $comment: String!) {
    editBookingReference(
      referenceID: $referenceID
      discount: $discount
      person: $person
      company: $company
      comment: $comment
    ) {
      bookings {
        id
        itemID
        image
        itemType
        startTime
        endTime
        price
        name
        discount
        addition
        guests
      }
      referenceID
      price
      discount
      date
      userID
      comment
      customer {
        ...PersonParts
        ...CompanyParts
      }
    }
  }
`;

const EDIT_BOOKED_ITEM = gql`
  fragment PersonParts on Person {
    firstName
    lastName
    email
    phone
    address
    zipCode
    city
    country
    lastPurchase
  }

  fragment CompanyParts on Company {
    organizationNr
    reference
    email
    phone
    address
    zipCode
    city
    country
    lastPurchase
    name
  }

  mutation(
    $id: ID!
    $referenceID: ID!
    $startTime: String!
    $endTime: String!
    $discount: String!
    $guests: Int!
    $addition: String!
  ) {
    editBookedItem(
      id: $id
      referenceID: $referenceID
      startTime: $startTime
      endTime: $endTime
      discount: $discount
      guests: $guests
      addition: $addition
    ) {
      bookings {
        id
        itemID
        image
        itemType
        startTime
        endTime
        price
        name
        discount
        addition
        guests
      }
      referenceID
      price
      discount
      date
      userID
      customer {
        ...PersonParts
        ...CompanyParts
      }
    }
  }
`;

const ADD_BOOKED_ITEM = gql`
  fragment PersonParts on Person {
    firstName
    lastName
    email
    phone
    address
    zipCode
    city
    country
    lastPurchase
  }

  fragment CompanyParts on Company {
    organizationNr
    reference
    email
    phone
    address
    zipCode
    city
    country
    lastPurchase
    name
  }

  mutation($referenceID: ID!) {
    addBookedItem(referenceID: $referenceID) {
      bookings {
        id
        itemID
        image
        itemType
        startTime
        endTime
        price
        name
        discount
        addition
        guests
      }
      referenceID
      price
      discount
      date
      userID
      customer {
        ...PersonParts
        ...CompanyParts
      }
    }
  }
`;

const DELETE_BOOKED_ITEM = gql`
  fragment PersonParts on Person {
    firstName
    lastName
    email
    phone
    address
    zipCode
    city
    country
    lastPurchase
  }

  fragment CompanyParts on Company {
    organizationNr
    reference
    email
    phone
    address
    zipCode
    city
    country
    lastPurchase
    name
  }

  mutation($id: ID!) {
    deleteBookedItem(id: $id) {
      bookings {
        id
        itemID
        image
        itemType
        startTime
        endTime
        price
        name
        discount
        addition
        guests
      }
      referenceID
      price
      discount
      date
      userID
      customer {
        ...PersonParts
        ...CompanyParts
      }
    }
  }
`;

const GET_USER = gql`
  query($id: ID!) {
    getUser(id: $id) {
      firstName
      lastName
    }
  }
`;

const EditDiscount = forwardRef(({ value }, ref) => {
  const [discount, setDiscount] = useState((parseInt(value, 10) / 10000).toString());
  return (
    <Discount length={discount.length}>
      <input
        ref={ref}
        type="text"
        name="totalDiscount"
        value={discount}
        onChange={e => setDiscount(e.target.value)}
        pattern="[0-9]+"
      />
      <h6>kr</h6>
    </Discount>
  );
});

EditDiscount.propTypes = {
  value: PropTypes.string.isRequired
};

const BookingReference = () => {
  const basket = useSelector(state => state.basket);
  const [basketAdded, setBasketAdded] = useState(false);
  const [addBookedItem] = useMutation(ADD_BOOKED_ITEM);

  const { data: data2, loading, error } = useQuery(GET_BOOKING, {
    variables: { referenceID: decodeURIComponent(new URL(window.location).searchParams.get('referenceID')) }
  });

  let zIndex = 10000;
  let shouldRemove = [];

  const [editBookingReference, { data: editData, loading: editLoading, error: editError }] = useMutation(
    EDIT_BOOKING_REFERENCE
  );

  const [editBookedItem, { data: editItemData, loading: editItemLoading, error: editItemError }] = useMutation(
    EDIT_BOOKED_ITEM
  );

  const [deleteBookedItem] = useMutation(DELETE_BOOKED_ITEM);

  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const [removeBooking, setRemoveBooking] = useState(false);
  const [edit, setEdit] = useState(false);
  const discountEl = useRef('');

  const [getUser, gud] = useLazyQuery(GET_USER);

  const [name, setName] = useState('');

  useEffect(() => {
    if (data2 && data2.getBookingReference) {
      getUser({ variables: { id: data2.getBookingReference.userID } });
    }
  }, [data2]);

  useEffect(() => {
    if (gud.data && gud.data.getUser) {
      const firstName =
        gud.data.getUser && gud.data.getUser.firstName.charAt(0).toUpperCase() + gud.data.getUser.firstName.slice(1);
      const lastName =
        gud.data.getUser && gud.data.getUser.lastName.charAt(0).toUpperCase() + gud.data.getUser.lastName.slice(1);
      setName(`${firstName} ${lastName}`);
    }
  }, [gud]);

  useEffect(() => {
    if (error) {
      dispatch(
        addErr(
          `Kunde inte hämta bokning ${decodeURIComponent(
            new URL(window.location).searchParams.get('referenceID').toUpperCase()
          )}.`
        )
      );
    }
  }, [dispatch, error]);

  useEffect(() => {
    setData(data2.getBookingReference);
  }, [data2]);

  useEffect(() => {
    if (editData) {
      if (!editLoading && !editItemLoading) {
        if (editError || editItemError) {
          dispatch(
            addErr(
              `Kunde inte spara ändringarna för bokning ${editData.editBookingReference.referenceID.toUpperCase()}.`
            )
          );
        }
      }
    }
  }, [editLoading, editItemLoading, editError, editItemError, editData, editItemData, dispatch]);

  const onSubmit = async e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const totalPrice = parseInt(data.price, 10) / 10000;

    if (parseInt(formData.get('totalDiscount'), 10) > totalPrice) {
      dispatch(addErr(`Rabatten kan inte vara högre än ${totalPrice}.`));
      discountEl.current.focus();
      discountEl.current.select();
    } else if (parseInt(formData.get('totalDiscount'), 10) < 0) {
      dispatch(addErr(`Rabatten kan inte vara mindre än ${0}.`));
      discountEl.current.focus();
      discountEl.current.select();
    } else {
      let variables = null;
      if (formData.get('totalDiscount').toString().length === 0) {
        variables = {
          referenceID: data.referenceID,
          discount: '0'
        };
      } else {
        variables = {
          referenceID: data.referenceID,
          discount: (parseInt(formData.get('totalDiscount').toString(), 10) * 10000).toString(),
          comment: formData.get('comment')
        };
      }

      if (data.customer.firstName) {
        variables.person = {
          firstName: formData.get('firstName'),
          lastName: formData.get('lastName'),
          email: formData.get('email'),
          phone: formData.get('phone'),
          address: formData.get('address'),
          zipCode: formData.get('zipCode'),
          city: formData.get('city'),
          country: formData.get('country')
        };
      } else {
        variables.company = {
          email: formData.get('email'),
          name: formData.get('companyName'),
          phone: formData.get('phone'),
          address: formData.get('address'),
          zipCode: formData.get('zipCode'),
          city: formData.get('city'),
          country: formData.get('country'),
          organizationNr: formData.get('organizationNr'),
          reference: formData.get('reference')
        };
      }
      try {
        const resp = data.bookings.map(i => {
          return editBookedItem({
            variables: {
              id: i.id,
              referenceID: data.referenceID,
              startTime: ISODateString(new Date(formData.get(`${i.id}startTime`).toString())),
              endTime: ISODateString(new Date(formData.get(`${i.id}endTime`).toString())),
              discount: (parseInt(formData.get(`${i.id}discount`).toString(), 10) * 10000).toString(),
              addition: (parseInt(formData.get(`${i.id}addition`).toString(), 10) * 10000).toString(),
              guests: parseInt(formData.get(`${i.id}guests`).toString(), 10)
            }
          });
        });

        await Promise.all(resp).then(e1 => setData(e1.pop().data.editBookedItem));
        if (basket.length > 0) {
          const resp2 = await addBookedItem({
            variables: {
              referenceID: data.referenceID
            }
          });
          dispatch(deleteBasket());
          setData(resp2.data.addBookedItem);
        }

        shouldRemove.map(async e1 => {
          const resp3 = await deleteBookedItem({
            variables: {
              id: e1.id
            }
          });
          setData(resp3.data.deleteBookedItem);
        });
        const resp2 = await editBookingReference({ variables });
        setData(resp2.data.editBookingReference);
        setBasketAdded(false);
        setEdit(v => !v);
        dispatch(addMsg(`Sparade ändringar för bokning ${data.referenceID.toUpperCase()}.`));
      } catch (_) {
        dispatch(
          addErr(`Kunde inte spara ändringarna för bokning ${data2.getBookingReference.referenceID.toUpperCase()}.`)
        );
      }
    }
  };

  if (loading) {
    return (
      <main>
        <Loader active />
      </main>
    );
  }
  if (error) {
    return (
      <main>
        <Loader active />
      </main>
    );
  }
  const result = data;

  if (!result) {
    return <PageNotFound />;
  }

  return (
    <main>
      <Container onSubmit={onSubmit}>
        <Left>
          <Information>
            <Manage>
              <h5>
                {`Bokning `}
                <Ref>{result.referenceID.toUpperCase()}</Ref>
              </h5>
              <Buttons>
                {edit && (
                  <Cancel
                    onClick={e => {
                      e.preventDefault();
                      setEdit(v => !v);
                    }}
                  >
                    Avbryt
                  </Cancel>
                )}
                {edit && <Edit type="submit">Spara</Edit>}
                {!edit && (
                  <Edit
                    onClick={e => {
                      e.preventDefault();
                      setEdit(v => !v);
                    }}
                  >
                    Redigera
                  </Edit>
                )}
                {!edit && (
                  <Delete
                    onClick={e => {
                      e.preventDefault();
                      setRemoveBooking(true);
                    }}
                  >
                    Radera
                  </Delete>
                )}
              </Buttons>
            </Manage>
            <BookingInfo data={result} edit={edit} />
            <Manage>
              <h5>Bokade objekt</h5>
              <Buttons>
                <div />
                {edit &&
                  basket.length > 0 &&
                  (basketAdded ? (
                    <Delete
                      onClick={e => {
                        e.preventDefault();
                        setBasketAdded(e1 => !e1);
                      }}
                    >
                      Ta bort varukorgen
                    </Delete>
                  ) : (
                    <AddFromBasket
                      onClick={e => {
                        e.preventDefault();
                        setBasketAdded(e1 => !e1);
                      }}
                    >
                      Lägg till från varukorgen
                    </AddFromBasket>
                  ))}
              </Buttons>
            </Manage>
          </Information>
          <Items>
            {result.bookings
              .sort((e, e1) => parseInt(e.id, 10) < parseInt(e1.id, 10))
              .map(e => (
                <BookedItem
                  referenceID={result.referenceID}
                  // eslint-disable-next-line no-plusplus
                  key={zIndex--}
                  zIndex={zIndex}
                  data={setImage(e)}
                  edit={edit}
                  addItem={e1 => {
                    shouldRemove.push(e1);
                  }}
                  removeItem={e2 => {
                    shouldRemove = shouldRemove.filter(e1 => e1.id !== e2.id);
                  }}
                />
              ))}
            {basketAdded && basket.map(e => <BigItem data={e} />)}
          </Items>
        </Left>
        <Right>
          <Gradient>
            <h5>Sammanfattning</h5>
          </Gradient>
          <BigRow>
            <i>Bokningsreferens</i>
            <SumRef>{result.referenceID.toUpperCase()}</SumRef>
          </BigRow>
          <BigRow>
            <i>Datum</i>
            <h6>{new Date(result.date).toLocaleDateString('sv-SE')}</h6>
          </BigRow>
          <BigRow>
            <i>Vår referens</i>
            <h6>{name}</h6>
          </BigRow>
          <Summary>
            <Row>
              <i>Antal</i>
              <h6>{`${parseInt(result.bookings.length, 10)} st`}</h6>
            </Row>
            <Row>
              <i>Gäster</i>
              <h6>{`${result.bookings.map(e => e.guests).reduce((a, b) => a + b, 0)} st`}</h6>
            </Row>
            <Row>
              <i>Rabatt</i>
              {edit ? (
                <EditDiscount ref={discountEl} value={result.discount} />
              ) : (
                <h6>{`${parseInt(result.discount, 10) / 10000} kr`}</h6>
              )}
            </Row>
            <Row>
              <i>Moms</i>
              <h6>12 %</h6>
            </Row>
            <Row>
              <b>Totalt</b>
              <h5>{`${(parseInt(result.price, 10) - parseInt(result.discount, 10)) / 10000}  kr`}</h5>
            </Row>
          </Summary>
        </Right>
      </Container>
      {removeBooking && (
        <PopOverForm
          data={{
            id: result.referenceID,
            name: result.referenceID.toUpperCase(),
            image: null,
            initials: 'B',
            title: 'Vill du verkligen ta bort denna bokning?',
            text: `Denna åtgärd går inte att ångra.`,
            submitLabel: 'Ta bort bokning'
          }}
          Form={RemoveBookingForm}
          formID="RemoveBookingForm"
          formData={result}
          onCancel={() => setRemoveBooking(false)}
        />
      )}
    </main>
  );
};

export default BookingReference;
