import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dimensions, Fonts, Colors } from 'fowlit';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { addErr, addMsg } from '../Reducers/Error/actions';
import { setSettings } from '../Reducers/Settings/actions';

const Container = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: calc(100vh - ${Dimensions.navHeight});
`;

const Box = styled.div`
  overflow-y: auto;
  border-right: 2px solid ${props => props.theme.border};
  background: ${props => props.theme.body};
`;

const Menu = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fit, 60px);
  grid-auto-rows: 50px;
`;

const Title = styled.h6`
  padding: 0 20px;
  border-bottom: 2px solid ${props => props.theme.border};
  display: flex;
  align-items: center;
  color: ${props => props.theme.color};
  user-select: none;
`;

const Item = styled.div`
  padding: 0 20px;
  border-bottom: 2px solid ${props => props.theme.border};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.color};
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeSuperTiny};
  cursor: pointer;
  user-select: none;

  &:hover {
    background: ${props => props.theme.main};
  }
`;

const Label = styled.i`
  font-size: ${Fonts.sizeSuperTiny};
`;

const Icon = styled.i`
  font-size: ${Fonts.sizeTiny};
`;

const Edit = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr;
  grid-auto-rows: 60px;
`;

const List = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fit, 60px);
  grid-auto-rows: 60px;
`;

const Setting = styled.div`
  padding: 0 20px;
  border-bottom: 2px solid ${props => props.theme.border};
  display: grid;
  grid-template-columns: 300px 1fr 3fr;
  align-items: center;
  color: ${props => props.theme.color};
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeSuperTiny};
  cursor: pointer;
  user-select: none;
  input {
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeSuperTiny};
    background: none;
    color: ${props => props.theme.color};
    width: 100%;
    height: 100%;
    appearance: none;
    border: none;
    padding: 0 20px;
  }
`;

const Button = styled.button`
  margin: 0 10px;
  height: 40px;
  padding: 0 20px;
  border: none;
  color: ${Colors.white};
  border-radius: ${Dimensions.radius};
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeSuperTiny};
  cursor: pointer;
  outline: none;
  position: fixed;
  right: ${Dimensions.navHeight};
  bottom: ${Dimensions.navHeight};
`;

const Submit = styled(Button)`
  background: ${Colors.blueberry};
  &:hover {
    background: ${Colors.blueberryDarker};
  }
`;

const General = () => {
  const general = useSelector(state => state.settings.general);
  return (
    <>
      <Setting>
        Tid i varukorg (min)
        <input
          type="text"
          name="basketTime"
          defaultValue={general.basketTime}
          placeholder="Hur många minuter?"
          pattern="^[0-9]+"
          required
        />
      </Setting>
    </>
  );
};

const Camping = () => {
  const camping = useSelector(state => state.settings.camping);
  return (
    <>
      <Setting>
        Incheckning (HH:MM)
        <input
          type="text"
          name="checkIn"
          defaultValue={camping.checkIn}
          placeholder="Vilken tid är incheckning?"
          pattern="^([0-9][0-9])+[:]+([0-9][0-9])"
          required
        />
      </Setting>
      <Setting>
        Utcheckning (HH:MM)
        <input
          type="text"
          name="checkOut"
          defaultValue={camping.checkOut}
          placeholder="Vilken tid är utcheckning?"
          pattern="^([0-9][0-9])+[:]+([0-9][0-9])"
          required
        />
      </Setting>
    </>
  );
};

const items = [
  { type: 'general', label: 'Allmänt', Component: General },
  { type: 'camping', label: 'Camping', Component: Camping }
];

const UPDATE_SETTINGS = gql`
  mutation($settings: NewSettings!) {
    updateSettings(settings: $settings) {
      general {
        basketTime
      }
      camping {
        checkIn
        checkOut
      }
    }
  }
`;

const Settings = () => {
  const dispatch = useDispatch();
  const [updateSettings, { data, loading, error }] = useMutation(UPDATE_SETTINGS);
  const [edit, setEdit] = useState(items[0]);

  useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(addErr('Kunde inte spara ändringar.'));
      } else if (data) {
        dispatch(setSettings(data.updateSettings));
        dispatch(addMsg('Ändringarna har sparats.'));
      }
    }
  }, [data, dispatch, error, loading]);

  const onSubmit = async e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const settings = {};
    if (edit.type === 'general') {
      settings.general = {
        basketTime: formData.get('basketTime')
      };
    } else if (edit.type === 'camping') {
      settings.camping = {
        checkIn: formData.get('checkIn'),
        checkOut: formData.get('checkOut')
      };
    }
    await updateSettings({
      variables: {
        settings
      }
    });
  };

  return (
    <main>
      <Container>
        <Menu>
          <Title>Inställningar</Title>
          {items.map(i => (
            <Item key={i.type} onClick={() => setEdit(i)}>
              <Label>{i.label}</Label>
              <Icon className="icon-angle-right" />
            </Item>
          ))}
        </Menu>
        <Edit>
          <Title>{edit.label}</Title>
          <List onSubmit={onSubmit}>
            <edit.Component />
            <Submit type="submit">Spara</Submit>
          </List>
        </Edit>
      </Container>
    </main>
  );
};

export default Settings;
