export const INIT_HOUSINGS = 'INIT_HOUSINGS';
export const UPDATE_HOUSING = 'UPDATE_HOUSING';
export const ADD_HOUSING = 'ADD_HOUSING';
export const DEL_HOUSING = 'DEL_HOUSING';
export const ERR_HOUSINGS = 'ERR_HOUSINGS';
export const LOADING_HOUSINGS = 'LOADING_HOUSINGS';

export const errorHousings = error => ({
  type: ERR_HOUSINGS,
  payload: {
    error
  }
});

export const loadingHousings = loading => ({
  type: LOADING_HOUSINGS,
  payload: {
    loading
  }
});

export const initHousings = housings => ({
  type: INIT_HOUSINGS,
  payload: {
    housings
  }
});

export const updateHousing = housing => ({
  type: UPDATE_HOUSING,
  payload: {
    housing
  }
});

export const addHousing = housing => ({
  type: ADD_HOUSING,
  payload: {
    housing
  }
});

export const deleteHousing = housing => ({
  type: DEL_HOUSING,
  payload: {
    housing
  }
});
