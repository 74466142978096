import {
  INIT_CURRENT_BOOKINGS,
  UPDATE_CURRENT_BOOKING,
  ADD_CURRENT_BOOKING,
  DEL_CURRENT_BOOKING,
  ERR_CURRENT_BOOKING,
  LOADING_CURRENT_BOOKING
} from './actions';

const initValue = {
  bookings: [],
  loading: false,
  error: null
};

const current = (state = initValue, action) => {
  switch (action.type) {
    case ERR_CURRENT_BOOKING: {
      state.error = action.payload.error;
      return { ...state };
    }

    case LOADING_CURRENT_BOOKING: {
      state.loading = action.payload.loading;
      return { ...state };
    }

    case INIT_CURRENT_BOOKINGS: {
      const { bookings } = action.payload;
      state.bookings = bookings ? [...bookings] : [];
      return { ...state };
    }

    case UPDATE_CURRENT_BOOKING: {
      const { booking } = action.payload;
      state.bookings = state.bookings.filter(e => e.referenceID !== booking.referenceID);
      state.bookings = [booking, ...state.bookings];
      return { ...state };
    }

    case ADD_CURRENT_BOOKING: {
      const { booking } = action.payload;
      state.bookings = [booking, ...state.bookings];
      return { ...state };
    }

    case DEL_CURRENT_BOOKING: {
      const { booking } = action.payload;
      state.bookings = state.bookings.filter(e => e.referenceID !== booking.referenceID);
      return { ...state };
    }

    default:
      return state;
  }
};

export default current;
