import { INIT_HOUSINGS, UPDATE_HOUSING, ADD_HOUSING, DEL_HOUSING, ERR_HOUSINGS, LOADING_HOUSINGS } from './actions';

const initValue = {
  housings: [],
  loading: false,
  error: null
};

const housings = (state = initValue, action) => {
  switch (action.type) {
    case ERR_HOUSINGS: {
      state.error = action.payload.error;
      return { ...state };
    }

    case LOADING_HOUSINGS: {
      state.loading = action.payload.loading;
      return { ...state };
    }

    case INIT_HOUSINGS: {
      state.housings = [...action.payload.housings];
      return { ...state };
    }

    case UPDATE_HOUSING: {
      const { housing } = action.payload;
      state.housings = state.housings.map(e => (e.id === housing.id ? housing : e));
      return { ...state };
    }

    case ADD_HOUSING: {
      const { housing } = action.payload;
      state.housings = [...state.housings, housing];
      return { ...state };
    }

    case DEL_HOUSING: {
      const { housing } = action.payload;
      state.housings = state.housings.filter(e => e.id !== housing.id);
      return { ...state };
    }

    default:
      return state;
  }
};

export default housings;
