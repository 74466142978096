import { createGlobalStyle } from 'styled-components';
import { Fonts, Media, Colors, Dimensions } from './variables';

import '../fonts/fontello/css/fontello.css';

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700|Source+Code+Pro:300,400,700|Montserrat+Alternates:300,400,700');

body {
    margin: 0;
    padding: 0;
    background: ${props => props.theme.body};
}

i {
    font-style: normal;
}

p {
    margin: 0;
    font-family: ${Fonts.text};
    font-weight: ${Fonts.weightRegular};
    font-size: ${Fonts.sizeSuperTiny};
}

h1 {
    margin: 0;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeXLarge};
}

h2 {
    margin: 0;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeRegular};
}

h3 {
    margin: 0;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeRegular};
}

h4 {
    margin: 0;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeSmall};
}

h5 {
    margin: 0;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.sizeRegular};
    font-size: ${Fonts.sizeTiny};
}

h6 {
    margin: 0;
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeSuperTiny};
}

a {
    text-decoration: none;
    margin: 0;
    font-family: ${Fonts.text};
    font-weight: ${Fonts.weightRegular};
    font-size: ${Fonts.sizeTiny};
    color: ${Colors.black};
}

hr {
    margin: 0;
    opacity: 0.3;
}

section {
    max-width: 1500px;
    margin: auto;
    padding: 10vh 10vw;
    background: ${Colors.white};

    @media only screen and (max-width: ${Media.phone}) {
        padding: 10vh 5vw;
    }
}

main {
    padding: ${Dimensions.navHeight} 0 0 ${Dimensions.navHeight};
    min-height: calc(100vh - ${Dimensions.navHeight});
    background: ${props => props.theme.main};
}
`;

export default GlobalStyle;
