import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dimensions, Colors, Fonts } from 'fowlit';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { addErr } from 'Reducers/Error/actions';
import DatePicker from './DatePicker';

const Container = styled.div`
  background: ${props => props.theme.body};
  color: ${props => props.theme.color};
  border: 2px solid ${props => props.theme.border};
  border-radius: ${Dimensions.radius};
  margin: 5px 0;
  user-select: none;
  transition: 200ms ease-out;
  padding: 0 20px;
  position: relative;
  z-index: ${props => props.zIndex};
  &:hover {
    box-shadow: 1px 5px 15px -10px ${Colors.blackDarker};
    margin-top: 10px;
  }
`;

const InfoItem = styled.div`
  display: grid;
  grid-template-columns: 80px repeat(8, minmax(100px, 1fr));
  grid-template-rows: 100px;
  align-items: center;
  overflow-x: auto;
`;

const EditItem = styled.div`
  display: grid;
  grid-template-columns: 80px minmax(100px, 1fr) 250px 250px repeat(5, minmax(100px, 1fr)) 60px;
  grid-template-rows: 100px;
  align-items: center;
`;

const Cell = styled.div`
  padding: 0 10px;
  i {
    font-family: ${Fonts.text};
    font-size: 0.8rem;
  }
`;

const Image = styled.div`
  background: ${props => `url('${props.url}')`} no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 60px;
  width: 60px;
`;

const Initials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom left, ${Colors.blueberry}, ${Colors.blueberryDarker});
  color: ${Colors.white};
  border-radius: 50%;
  height: 60px;
  width: 60px;
  text-transform: uppercase;
  font-family: ${Fonts.head};
  font-size: ${Fonts.sizeSmall};
`;

const Button = styled.button`
  height: 70%;
  font-size: 22px;
  border: none;
  appearance: none;
  outline: none;
  background: none;
  color: ${props => props.theme.color};
  cursor: pointer;
`;

const Delete = styled(Button)`
  &:hover {
    color: ${Colors.strawberry};
  }
`;

const GET_OCCUPIED_DATES = gql`
  query($id: ID!, $itemType: ItemType!, $referenceID: ID) {
    getOccupiedDates(id: $id, itemType: $itemType, referenceID: $referenceID) {
      startTime
      endTime
    }
  }
`;

const Discount = styled.div`
  display: grid;
  grid-template-columns: ${props => `minmax(20px, ${props.length * 10}px)`} 20px;
  grid-gap: 3px;
  align-items: center;
  input {
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeSuperTiny};
    background: none;
    color: ${props => props.theme.color};
    width: 100%;
    appearance: none;
    border: none;
    overflow-x: hidden;
  }
`;

const BookedItem = ({ zIndex, data, edit, addItem, removeItem, referenceID }) => {
  const dispatch = useDispatch();
  const getOccupiedDates = useQuery(GET_OCCUPIED_DATES, {
    variables: {
      id: data.itemID,
      itemType: data.itemType,
      referenceID
    }
  });
  const campingSettings = useSelector(state => state.settings.camping);
  const checkInSplit = campingSettings.checkIn.split(':');
  const checkOutSplit = campingSettings.checkOut.split(':');
  const [discount, setDiscount] = useState((parseInt(data.discount, 10) / 10000).toString());
  const [addition, setAddition] = useState((parseInt(data.addition, 10) / 10000).toString());
  const [guests, setGuests] = useState(parseInt(data.guests, 10).toString());
  const discountEl = useRef('');
  const additionEl = useRef('');
  const guestsEl = useRef('');
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const [startTime, setStartTime] = useState(new Date(data.startTime));
  const [endTime, setEndTime] = useState(new Date(data.endTime));
  const [removed, setRemoved] = useState(false);
  const [bad, setBad] = useState(false);
  const od = getOccupiedDates.data.getOccupiedDates;

  useEffect(() => {
    if (od) {
      let isBad = false;
      od.forEach(e => {
        const st = new Date(Date.parse(e.startTime));
        const et = new Date(Date.parse(e.endTime));

        if (!(et < startTime || st >= endTime)) {
          isBad = true;
        }
      });
      setBad(isBad);
    }
  }, [startTime, endTime, od]);

  useEffect(() => {
    if (discount) {
      const itemPrice =
        (parseInt(data.price, 10) *
          Math.round((new Date(data.endTime).getTime() - new Date(data.startTime).getTime()) / (1000 * 60 * 60 * 24))) /
        10000;
      if (parseInt(discount, 10) > itemPrice) {
        dispatch(addErr(`Rabatten kan inte vara högre än ${itemPrice}.`));
        setDiscount('0');
        discountEl.current.focus();
        discountEl.current.select();
      } else if (parseInt(discount, 10) < 0) {
        dispatch(addErr(`Rabatten kan inte vara mindre än ${0}.`));
        setDiscount('0');
        discountEl.current.focus();
        discountEl.current.select();
      } else if (discount === '') {
        setDiscount('0');
      }
    }
  }, [data.endTime, data.price, data.startTime, discount, dispatch]);

  return (
    <Container zIndex={zIndex}>
      {edit ? (
        <EditItem>
          <Cell>
            {data.image ? <Image url={data.image} /> : <Initials>{data.name.charAt(0).toUpperCase()}</Initials>}
          </Cell>
          <Cell>
            <h6>{data.name}</h6>
          </Cell>
          <Cell>
            <DatePicker
              bad={bad}
              hour={parseInt(checkInSplit[0], 10)}
              minutes={parseInt(checkInSplit[1], 10)}
              occupiedDates={od || []}
              label="Från"
              date={startTime}
              setDate={setStartTime}
              endDate={endTime}
              edit={edit}
            />
            <input type="text" name={`${data.id}startTime`} value={startTime} readOnly hidden />
          </Cell>
          <Cell>
            <DatePicker
              bad={bad}
              zIndex={zIndex}
              hour={parseInt(checkOutSplit[0], 10)}
              minutes={parseInt(checkOutSplit[1], 10)}
              occupiedDates={od || []}
              label="Till"
              date={endTime}
              setDate={setEndTime}
              endDate={startTime}
              edit={edit}
            />
            <input type="text" name={`${data.id}endTime`} value={endTime} readOnly hidden />
          </Cell>
          <Cell>
            <i>Nätter</i>
            <h6>
              {Math.round(
                (new Date(data.endTime).getTime() - new Date(data.startTime).getTime()) / (1000 * 60 * 60 * 24)
              )}
            </h6>
          </Cell>
          <Cell>
            <i>Pris</i>
            <h6>
              {`${(parseInt(data.price, 10) *
                Math.round(
                  (new Date(data.endTime).getTime() - new Date(data.startTime).getTime()) / (1000 * 60 * 60 * 24)
                )) /
                10000} kr`}
            </h6>
          </Cell>
          <Cell>
            <i>Rabatt</i>
            <Discount length={discount.length} onClick={() => discountEl.current.select()}>
              <input
                ref={discountEl}
                type="text"
                name={`${data.id}discount`}
                value={discount}
                onChange={e => setDiscount(e.target.value)}
                pattern="[0-9]+"
                required
              />
              <h6>kr</h6>
            </Discount>
          </Cell>
          <Cell>
            <i>Tillägg</i>
            <Discount length={addition.length} onClick={() => additionEl.current.select()}>
              <input
                ref={additionEl}
                type="text"
                name={`${data.id}addition`}
                value={addition}
                onChange={e => setAddition(e.target.value)}
                pattern="[0-9]+"
                required
              />
              <h6>kr</h6>
            </Discount>
          </Cell>
          <Cell>
            <i>Gäster</i>
            <Discount length={guests.length} onClick={() => guestsEl.current.select()}>
              <input
                ref={guestsEl}
                type="text"
                name={`${data.id}guests`}
                value={guests}
                onChange={e => setGuests(e.target.value)}
                pattern="[0-9]+"
                required
              />
              <h6>st</h6>
            </Discount>
          </Cell>
          <Cell>
            <Delete
              className={removed ? 'icon-cancel-circled' : 'icon-trash'}
              onClick={e => {
                e.preventDefault();
                setRemoved(b => {
                  if (!b) {
                    addItem(data);
                  } else {
                    removeItem(data);
                  }
                  return !b;
                });
              }}
            />
          </Cell>
        </EditItem>
      ) : (
        <InfoItem key={data.id}>
          <Cell>
            {data.image ? <Image url={data.image} /> : <Initials>{data.name.charAt(0).toUpperCase()}</Initials>}
          </Cell>
          <Cell>
            <h6>{data.name}</h6>
          </Cell>
          <Cell>
            <i>Från</i>
            <h6>{new Date(data.startTime).toLocaleDateString('sv-SV', options)}</h6>
          </Cell>
          <Cell>
            <i>Till</i>
            <h6>{new Date(data.endTime).toLocaleDateString('sv-SV', options)}</h6>
          </Cell>
          <Cell>
            <i>Nätter</i>
            <h6>
              {Math.round(
                (new Date(data.endTime).getTime() - new Date(data.startTime).getTime()) / (1000 * 60 * 60 * 24)
              )}
            </h6>
          </Cell>
          <Cell>
            <i>Pris</i>
            <h6>
              {`${(parseInt(data.price, 10) *
                Math.round(
                  (new Date(data.endTime).getTime() - new Date(data.startTime).getTime()) / (1000 * 60 * 60 * 24)
                )) /
                10000} kr`}
            </h6>
          </Cell>
          <Cell>
            <i>Rabatt</i>
            <h6>{data.discount ? `${parseInt(data.discount, 10) / 10000} kr` : `0 kr`}</h6>
          </Cell>
          <Cell>
            <i>Tillägg</i>
            <h6>{data.addition ? `${parseInt(data.addition, 10) / 10000} kr` : `0 kr`}</h6>
          </Cell>
          <Cell>
            <i>Gäster</i>
            <h6>{data.guests ? `${parseInt(data.guests, 10)} st` : `0 st`}</h6>
          </Cell>
        </InfoItem>
      )}
    </Container>
  );
};

BookedItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    itemID: PropTypes.string,
    itemType: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    price: PropTypes.string,
    discount: PropTypes.string,
    addition: PropTypes.string,
    guests: PropTypes.number
  }).isRequired,
  edit: PropTypes.bool.isRequired,
  zIndex: PropTypes.number.isRequired,
  referenceID: PropTypes.string.isRequired,
  addItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired
};

export default BookedItem;
