import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ImagePicker from 'Components/ImagePicker';
import { Colors, Dimensions, Fonts, Media } from 'fowlit';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(34, 34, 34, 0.7);
  z-index: 10003;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Box = styled.div`
  width: ${Media.phone};
  border-radius: ${Dimensions.radius};
  color: ${props => props.theme.color};
  background: ${props => props.theme.body};
  border: 1px solid ${props => props.theme.border};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 120px 20px 50px 50px 1fr 70px;
  grid-template-areas: 'image' 'name' 'title' 'text' 'form' 'button';
  padding: 50px 30px;
  grid-gap: 10px;
`;

const ImageArea = styled.div`
  display: grid;
  align-items: flex-end;
  justify-content: center;
`;

const Image = styled.div`
  background: ${props => `url('${props.src}')`} no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 100px;
  width: 100px;
`;

const Initials = styled.div`
  color: ${Colors.white};
  background: linear-gradient(to bottom left, ${Colors.blueberry}, ${Colors.blueberryDarker});
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${Fonts.head};
  font-size: ${Fonts.sizeRegular};
  text-transform: uppercase;
`;

const NameArea = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`;

const Name = styled.i`
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeSuperTiny};
`;

const TitleArea = styled.div`
  display: grid;
  align-items: flex-end;
  justify-content: center;
`;
const Title = styled.h5``;

const TextArea = styled.div`
  display: grid;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  padding: 0 70px;
`;

const Text = styled.p``;

const FormArea = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  margin: 0 10px;
  height: 40px;
  padding: 0 20px;
  border: none;
  color: ${Colors.white};
  border-radius: ${Dimensions.radius};
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeSuperTiny};
  cursor: pointer;
  outline: none;
`;

const Cancel = styled(Button)`
  background: ${Colors.strawberry};
  &:hover {
    background: ${Colors.strawberryDarker};
  }
`;
const Submit = styled(Button)`
  background: ${Colors.blueberry};
  &:hover {
    background: ${Colors.blueberryDarker};
  }
`;

const PopOverForm = ({ data, Form, formID, formData, onCancel, editImage, deleteImage }) => {
  return (
    <Container>
      <Box>
        <ImageArea>
          {(() => {
            if (editImage === null || deleteImage === null) {
              if (data.image === null) {
                return <Initials>{data.initials}</Initials>;
              }
              return <Image src={data.image} />;
            }
            if (data.image === null) {
              return <ImagePicker Image={Initials} text={data.initials} onUpdate={editImage} onDelete={deleteImage} />;
            }
            return <ImagePicker Image={Image} src={data.image} onUpdate={editImage} onDelete={deleteImage} />;
          })()}
        </ImageArea>
        <NameArea>
          <Name>{data.name}</Name>
        </NameArea>
        <TitleArea>
          <Title>{data.title}</Title>
        </TitleArea>
        <TextArea>
          <Text>{data.text}</Text>
        </TextArea>
        <FormArea>{Form && <Form formID={formID} formData={formData} close={onCancel} />}</FormArea>
        <ButtonArea>
          <Cancel onClick={onCancel}>Avbryt</Cancel>
          <Submit type="submit" form={formID}>
            {data.submitLabel}
          </Submit>
        </ButtonArea>
      </Box>
    </Container>
  );
};

PopOverForm.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    initials: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    submitLabel: PropTypes.string.isRequired
  }).isRequired,
  Form: PropTypes.elementType.isRequired,
  formID: PropTypes.string.isRequired,
  formData: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array, PropTypes.object])
    .isRequired,
  onCancel: PropTypes.func.isRequired,
  editImage: PropTypes.shape({
    document: PropTypes.string,
    definitions: PropTypes.array,
    loc: PropTypes.object
  }),
  deleteImage: PropTypes.shape({
    document: PropTypes.string,
    definitions: PropTypes.array,
    loc: PropTypes.object
  })
};

PopOverForm.defaultProps = {
  editImage: null,
  deleteImage: null
};

export default PopOverForm;
