/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dimensions, Colors, Fonts } from 'fowlit';
import { useComponentVisible } from 'Hooks';

const weekdays = [
  { id: 1, label: 'M' },
  { id: 2, label: 'T' },
  { id: 3, label: 'O' },
  { id: 4, label: 'T' },
  { id: 5, label: 'F' },
  { id: 6, label: 'L' },
  { id: 7, label: 'S' }
];

const Container = styled.div`
  display: grid;
`;

const Edit = styled.div`
  background: ${props => props.theme.body};
  color: ${props => props.theme.color};
  border-radius: ${Dimensions.radius};
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 50px;
  position: relative;
  cursor: pointer;
  padding: 0 0 0 20px;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: ${props => (props.active ? 0 : '5px')};
  border-bottom-right-radius: ${props => (props.active ? 0 : '5px')};
  border-left: 1px solid ${props => (props.bad ? Colors.strawberry : props.theme.border)};
  border-right: 1px solid ${props => (props.bad ? Colors.strawberry : props.theme.border)};
  border-bottom: 1px solid
    ${props => {
      if (props.bad) {
        if (props.active) {
          return 'transparent';
        }
        return Colors.strawberry;
      }
      if (props.active) {
        return 'transparent';
      }
      return props.theme.border;
    }};
  border-top: 1px solid ${props => (props.bad ? Colors.strawberry : props.theme.border)};
`;

const Info = styled.div`
  padding: 0 10px;
`;

const Label = styled.i`
  font-family: ${Fonts.text};
  font-size: 0.8rem;
`;

const Angle = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${props => (props.active ? `rotateZ(180deg)` : `none`)};
  transition: 200ms ease-in-out;
  font-size: ${Fonts.sizeTiny};
  width: 100%;
  height: 100%;
`;

const DateLabel = styled.h6``;

const Dropdown = styled.div`
  display: ${props => (props.active ? 'grid' : 'none')};
  grid-template-columns: 1fr;
  grid-template-rows: 30px 30px 1fr;
  grid-template-areas: 'month' 'week' 'days';
  grid-gap: 5px;
  justify-content: center;
  padding: 20px;
  background: ${props => props.theme.body};
  color: ${props => props.theme.color};
  position: absolute;
  top: 51px;
  left: -1px;
  right: -1px;
  box-shadow: 1px 5px 5px -7px ${Colors.black};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 1px solid ${props => (props.bad ? Colors.strawberry : props.theme.border)};
  border-right: 1px solid ${props => (props.bad ? Colors.strawberry : props.theme.border)};
  border-bottom: 1px solid ${props => (props.bad ? Colors.strawberry : props.theme.border)};
`;

const Month = styled.div`
  grid-area: month;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  grid-template-rows: 1fr;
  text-align: center;
`;

const Week = styled.div`
  grid-area: week;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
`;

const WeekLabel = styled.i`
  font-size: 0.7rem;
  color: ${props => (props.current ? Colors.blueberry : props.theme.color)};
  font-weight: ${Fonts.weightBold};
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
`;

const Days = styled.div`
  grid-area: days;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
`;

const Day = styled.i`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 30px;
  width: 30px;
  font-size: 0.7rem;
  user-select: none;
  cursor: pointer;
  transition: 200ms ease-out;
  border-radius: ${props => (props.selectedAsFrom || props.selectedAsTo ? '50%' : '0')};
  ${props => {
    if (props.disabled || props.afterEndTime) {
      return `
          opacity: 0.2;
          pointer-events: none;
          color: ${props.theme.color};
        `;
    }
    if (props.selected) {
      return `
				background: ${Colors.blueberry};
        border-radius: 50%;
        color: ${Colors.white};
      `;
    }
    if (props.today) {
      return `
        color: ${Colors.blueberry};
        font-weight: ${Fonts.weightBold};
      `;
    }
    return `
      color: ${props.theme.color};
    `;
  }}
  &:hover {
    color: ${Colors.white};
    background: ${props => props.theme.searchbarActive};
    opacity: 1;
  }
`;

const getDaysOfMonth = date => new Date(new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0).getDate();

const DatePicker = ({ bad, hour, minutes, occupiedDates, label, date, setDate, edit, endDate }) => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const [monthOptions, setMonthOptions] = useState({ month: 'short' });
  const { ref, visible, setVisible } = useComponentVisible(false);

  const [days, setDays] = useState(getDaysOfMonth(date));
  const [month, setMonth] = useState(date);

  useEffect(() => {
    setMonthOptions(
      new Date(month).getFullYear() === new Date().getFullYear()
        ? { month: 'long' }
        : { month: 'short', year: 'numeric' }
    );
    setDays(getDaysOfMonth(month));
  }, [month]);

  const isOccupied = d =>
    occupiedDates.filter(e => {
      const startTime = new Date(Date.parse(e.startTime)).getTime();
      const endTime = new Date(Date.parse(e.endTime)).getTime();
      return d >= startTime && d < endTime;
    }).length > 0;

  const isOkMonth = () => {
    const now = new Date();
    now.setMonth(now.getMonth() - 12);
    return now;
  };
  return (
    <Container>
      {edit ? (
        <Edit bad={bad} ref={ref} active={visible}>
          <div onClick={() => setVisible(v => !v)}>
            <Label>{label}</Label>
            <DateLabel>{new Date(date).toLocaleDateString('sv-SV', options)}</DateLabel>
          </div>
          <Angle active={visible} className="icon-angle-down" onClick={() => setVisible(v => !v)} />
          <Dropdown bad={bad} active={visible}>
            <Month>
              {isOkMonth() < month ? (
                <i
                  className="icon-angle-left"
                  onClick={() => setMonth(m => new Date(m).setMonth(new Date(m).getMonth() - 1))}
                />
              ) : (
                <i />
              )}

              <h5>{new Date(month).toLocaleDateString('sv-SE', monthOptions)}</h5>
              <i
                className="icon-angle-right"
                onClick={() => setMonth(m => new Date(m).setMonth(new Date(m).getMonth() + 1))}
              />
            </Month>
            <Week>
              {weekdays.map(e => (
                <WeekLabel key={e.id} current={e.id === new Date().getDay()}>
                  {e.label}
                </WeekLabel>
              ))}
            </Week>
            <Days>
              {(() => {
                const d = [];
                const firstDay = new Date(new Date(month).getFullYear(), new Date(month).getMonth(), 1, hour, minutes);
                if (firstDay.getDay() === 0) {
                  for (let i = 0; i < 6; i += 1) {
                    d.push(<i key={Math.random()} />);
                  }
                } else {
                  for (let i = 0; i < firstDay.getDay() - 1; i += 1) {
                    d.push(<i key={Math.random()} />);
                  }
                }
                for (let i = 1; i <= days; i += 1) {
                  const tempDate = new Date(
                    new Date(month).getFullYear(),
                    new Date(month).getMonth(),
                    i,
                    hour,
                    minutes
                  );
                  d.push(
                    <Day
                      key={i.toString()}
                      onClick={() => setDate(tempDate)}
                      today={
                        new Date().getDate() === tempDate.getDate() &&
                        new Date().getMonth() === tempDate.getMonth() &&
                        new Date().getFullYear() === tempDate.getFullYear()
                      }
                      selected={
                        new Date(date).getDate() === tempDate.getDate() &&
                        new Date(date).getMonth() === tempDate.getMonth() &&
                        new Date(date).getFullYear() === tempDate.getFullYear()
                      }
                      disabled={isOccupied(tempDate)}
                      afterEndTime={
                        new Date(endDate).getTime() > new Date(date).getTime()
                          ? new Date(
                              new Date(endDate).getFullYear(),
                              new Date(endDate).getMonth(),
                              new Date(endDate).getDate()
                            ).getTime() < tempDate.getTime()
                          : new Date(
                              new Date(endDate).getFullYear(),
                              new Date(endDate).getMonth(),
                              new Date(endDate).getDate(),
                              hour,
                              minutes
                            ).getTime() >= tempDate.getTime()
                      }
                    >
                      {i}
                    </Day>
                  );
                }
                return d;
              })()}
            </Days>
          </Dropdown>
        </Edit>
      ) : (
        <Info>
          <Label>{label}</Label>
          <DateLabel>{new Date(date).toLocaleDateString('sv-SE', options)}</DateLabel>
        </Info>
      )}
    </Container>
  );
};

DatePicker.propTypes = {
  hour: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  setDate: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  occupiedDates: PropTypes.arrayOf(PropTypes.object).isRequired,
  bad: PropTypes.bool.isRequired
};

export default DatePicker;
