import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { Colors, Dimensions, Fonts, Regexp } from 'fowlit';
import { Input, Select } from 'Components/Forms';
import { PopOverForm } from 'Components/PopOver';
import { addErr, addMsg } from 'Reducers/Error/actions';

const options = {
  day: 'numeric',
  month: 'short',
  year: 'numeric'
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 100px 650px 250px;
  grid-template-rows: 90px;
  grid-template-areas: 'img info button';
  background: ${props => props.theme.body};
  border: 2px solid ${props => props.theme.border};
  border-radius: ${Dimensions.radius};
  margin-bottom: 10px;
  user-select: none;
  transition: 200ms ease-out;
  &:hover {
    box-shadow: 1px 5px 15px -10px ${Colors.blackDarker};
    /*transform: translateY(-5px); Förstör för popup*/
    margin-top: 5px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
`;

const ImageBox = styled(Box)`
  grid-area: img;
`;
const Image = styled.div`
  background: ${props => `url('${props.url}')`} no-repeat;
  background-size: cover;
  width: 60px;
  height: 60px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 50%;
`;
const Initials = styled(Box)`
  background: linear-gradient(to bottom left, ${Colors.blueberry}, ${Colors.blueberryDarker});
  color: ${Colors.white};
  width: 60px;
  height: 60px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 50%;
  text-transform: uppercase;
  font-family: ${Fonts.head};
  font-size: ${Fonts.sizeSmall};
`;

const InfoBox = styled(Box)`
  grid-area: info;
  display: grid;
  grid-template-columns: 1.3fr 0.85fr 0.85fr;
`;
const Info = styled.div`
  color: ${props => props.theme.color};
  i {
    font-family: ${Fonts.text};
    font-size: 0.8rem;
  }
`;

const ButtonBox = styled(Box)`
  grid-area: button;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const Button = styled.button`
  height: 70%;
  font-size: 22px;
  border: none;
  appearance: none;
  outline: none;
  background: none;
  color: ${props => props.theme.color};
  cursor: pointer;
`;

const Edit = styled(Button)`
  &:hover {
    color: ${Colors.kiwi};
  }
`;

const Form = styled.form`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 250px;
  grid-gap: 10px;
`;

const ChangeRoleForm = ({ formID, formData, close }) => {
  const dispatch = useDispatch();
  const [role, setRole] = useState({ type: '', label: '' });
  const CHANGE_ROLE = gql`
    mutation($id: ID!, $role: String!) {
      editRole(id: $id, role: $role) {
        id
      }
    }
  `;
  const [changeRole, { data, loading, error }] = useMutation(CHANGE_ROLE);
  const roles = [{ type: 'admin', label: 'Administratör' }, { type: 'worker', label: 'Arbetare' }];

  useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(addErr(`Kunde inte byta roll.`));
      } else if (data) {
        dispatch(addMsg(`Bytte roll till ${role.label.toLowerCase()}`));
        close();
      }
    }
  }, [close, data, dispatch, error, loading, role.label]);

  return (
    <Form
      id={formID}
      onSubmit={e => {
        e.preventDefault();
        changeRole({ variables: { id: formData.id, role: role.type } });
      }}
    >
      <Select label="Roll" options={roles} value={role} setValue={setRole} />
    </Form>
  );
};

ChangeRoleForm.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])).isRequired,
  close: PropTypes.func.isRequired
};

const RemoveUserForm = ({ formID, formData, close }) => {
  const dispatch = useDispatch();
  const DELETE_USER = gql`
    mutation($id: ID!) {
      deleteUser(id: $id) {
        id
      }
    }
  `;
  const [deleteUser, { data, loading, error }] = useMutation(DELETE_USER);

  useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(addErr('Kunde inte ta bort användaren.'));
      } else if (data) {
        dispatch(addMsg('Tog bort användaren.'));
        close();
      }
    }
  }, [close, data, dispatch, error, loading]);

  return (
    <Form
      id={formID}
      onSubmit={e => {
        e.preventDefault();
        deleteUser({ variables: { id: formData.id } });
      }}
    />
  );
};

RemoveUserForm.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])).isRequired,
  close: PropTypes.func.isRequired
};

const ForceUserForm = ({ formID, formData, close }) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const FORCE_USER = gql`
    mutation($id: ID!, $password: String!) {
      forceNewPassword(id: $id, password: $password)
    }
  `;
  const [forceNewPassword, { data, loading, error }] = useMutation(FORCE_USER);

  useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(addErr('Kunde inte tvinga lösenordsbyte.'));
      } else if (data) {
        dispatch(addMsg('Tvingade användaren till att byta lösenord.'));
        close();
      }
    }
  }, [close, data, dispatch, error, loading]);

  return (
    <Form
      id={formID}
      onSubmit={e => {
        e.preventDefault();
        forceNewPassword({ variables: { id: formData.id, password } });
      }}
    >
      <Input
        type="password"
        label="Nytt lösenord"
        value={password}
        setValue={e => setPassword(e)}
        pattern={Regexp.password}
        minLength={8}
        maxLength={256}
        required
      />
    </Form>
  );
};

ForceUserForm.propTypes = {
  formID: PropTypes.string.isRequired,
  formData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])).isRequired,
  close: PropTypes.func.isRequired
};

const Delete = styled(Button)`
  border-left: 1px solid ${props => props.theme.border};
  border-right: 1px solid ${props => props.theme.border};
  &:hover {
    color: ${Colors.strawberry};
  }
`;

const Force = styled(Button)`
  &:hover {
    color: ${Colors.blueberry};
  }
`;

const createTime = date => {
  const h = date.getHours();
  const m = date.getMinutes();

  let t = '';
  t += h < 10 ? `0${h}` : h;
  t += ':';
  t += m < 10 ? `0${m}` : m;
  return t;
};

const User = ({ data }) => {
  const name = `${data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1)} ${data.lastName
    .charAt(0)
    .toUpperCase()}${data.lastName.slice(1)}`;
  const initials = data.firstName.charAt(0) + data.lastName.charAt(0);

  const since = `sedan ${new Date(Date.parse(data.createdAt)).getFullYear()}`;
  const lastLogin = createTime(new Date(Date.parse(data.lastLogin)));
  const date = new Date(Date.parse(data.lastLogin)).toLocaleDateString('sv-SV', options);

  const [removeUser, setRemoveUser] = useState(false);
  const [forceUser, setForceUser] = useState(false);
  const [role, setRole] = useState(false);

  const GenerateImage = () => {
    if (data.image !== null) {
      return <Image url={data.image} />;
    }
    return <Initials>{initials}</Initials>;
  };

  const getRole = () => {
    switch (data.role) {
      case 'admin':
        return 'Admin';
      case 'worker':
        return 'Arbetare';
      default:
        return 'Not yet implemented';
    }
  };

  return (
    <Container>
      <ImageBox>
        <GenerateImage />
      </ImageBox>
      <InfoBox>
        <Info>
          <h6>{name}</h6>
          <i>{data.username}</i>
        </Info>
        <Info>
          <h6>{getRole()}</h6>
          <i>{since}</i>
        </Info>
        <Info>
          {data.lastLogin && <h6>{date}</h6>}
          {data.lastLogin && <i>{lastLogin}</i>}
        </Info>
      </InfoBox>
      <ButtonBox>
        <Edit className="icon-edit" onClick={() => setRole(true)} />
        <Delete className="icon-trash" onClick={() => setRemoveUser(true)} />
        <Force className="icon-lock" onClick={() => setForceUser(true)} />
      </ButtonBox>
      {role && (
        <PopOverForm
          data={{
            id: data.id,
            name,
            image: data.image,
            initials,
            title: 'Vill du byta denna användares roll?',
            text: 'Användaren kommer att loggas ut innan den nya ändringen börjar gälla.',
            submitLabel: 'Byt roll'
          }}
          Form={ChangeRoleForm}
          formID="ChangeRoleForm"
          formData={data}
          onCancel={() => setRole(false)}
        />
      )}

      {removeUser && (
        <PopOverForm
          data={{
            id: data.id,
            name,
            image: data.image,
            initials,
            title: 'Vill du verkligen ta bort denna användare?',
            text: 'Denna åtgärd går inte att ångra. Kontakta en administratör om du har några frågor angående detta.',
            submitLabel: 'Ta bort användaren'
          }}
          Form={RemoveUserForm}
          formID="RemoveUserForm"
          formData={data}
          onCancel={() => setRemoveUser(false)}
        />
      )}
      {forceUser && (
        <PopOverForm
          data={{
            id: data.id,
            name,
            image: data.image,
            initials,
            title: 'Vill du tvinga denna användare till ett lösenordsbyte?',
            text: 'Denna åtgärd går inte att ångra. Kontakta en administratör om du har några frågor angående detta.',
            submitLabel: 'Tvinga lösenordsbyte'
          }}
          Form={ForceUserForm}
          formID="ForceUserForm"
          formData={data}
          onCancel={() => setForceUser(false)}
        />
      )}
    </Container>
  );
};

User.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    username: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    createdAt: PropTypes.string,
    lastLogin: PropTypes.string,
    image: PropTypes.string
  }).isRequired
};

User.defaultValues = {
  image: ''
};

export default User;
