import React from 'react';
import styled from 'styled-components';
import { Colors, Fonts, Media } from 'fowlit';
import { Buttons } from 'Res/stylesheets/buttons';
import waveLight from 'Res/Assets/Svg/wave-light.svg';
import waveDark from 'Res/Assets/Svg/wave-dark.svg';
import logo from 'Res/Assets/Logos/fowlit-blue-bird.svg';

import { getRandomString, encodeURL } from 'Library';

const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  background: white;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: ${Media.tablet}) {
    flex-flow: column;
    align-items: flex-start;
  }
`;

const Header = styled.header`
  padding: 0 20px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  i {
    display: block;
    font-size: ${Fonts.sizeRegular};
    color: ${Colors.blueberry};
    padding: 5px;
  }
  @media (max-width: ${Media.tablet}) {
    position: relative;
    box-sizing: border-box;
    width: 100vw;
  }
`;

const Text = styled.div`
  padding: 0 5vw 15vh 10vw;
  width: 55vw;
  z-index: 4;
  box-sizing: border-box;
  h2 {
    color: ${Colors.black};
  }
  p {
    color: ${Colors.black};
    margin: 25px 0 35px 0;
    max-width: 70%;
  }

  @media (max-width: ${Media.tablet}) {
    padding: 3vh 3vw;
    width: 100vw;
    text-align: center;
    h2 {
      font-size: ${Fonts.sizeSmall};
    }
    p {
      max-width: 100%;
    }
  }
`;

const Button = styled.button`
  ${Buttons.blueberry}
  width: 250px;
  margin-bottom: 25px;
`;

const Logo = styled.div`
  background: url(${logo}) no-repeat;
  position: absolute;
  right: 10vw;
  bottom: 15vh;
  height: 30vh;
  width: 30vw;
  transform: scaleX(-1);
  z-index: 3;

  @media (max-width: ${Media.tablet}) {
    display: none;
  }
`;

const Waves = styled.div`
  position: absolute;
  height: 40vh;
  width: 100vw;
  bottom: 0;
`;

const Wave = styled.div`
  background: url(${waveLight}) repeat-x;
  background-clip: border-box;
  background-size: cover;
  position: absolute;
  bottom: 0;
  right: -3vh;
  left: 0;
  top: 3vh;
  animation: wave1 7s ease-in-out infinite;
  transform: translate3d(-3vh, 0, 0);

  &:nth-of-type(2) {
    background: url(${waveDark}) repeat-x;
    background-clip: border-box;
    background-size: cover;
    right: -2vw;
    top: 7vh;
    animation: wave2 7s ease-in-out infinite;
    transform: translate3d(-2vh, 0, 0);
    opacity: 0.7;
  }

  @keyframes wave1 {
    0%,
    100% {
      top: 3vh;
      transform: translate3d(0, 0, 0);
    }
    50% {
      top: 0;
      transform: translate3d(-3vh, 0, 0);
    }
  }

  @keyframes wave2 {
    0%,
    100% {
      top: 7vh;
      transform: translate3d(0, 0, 0);
    }
    50% {
      top: 5vh;
      transform: translate3d(-2vh, 0, 0);
    }
  }
`;

const Landing = () => {
  const onClickHandler = () => {
    const data = {
      state: getRandomString(30),
      nonce: getRandomString(30)
    };

    window.localStorage.setItem('state', data.state);
    window.localStorage.setItem('nonce', data.nonce);

    window.location = encodeURL('/oauth2/code', data);
  };

  return (
    <Container>
      <Header>
        <i className="icon-fowlit" />
        <hr />
      </Header>
      <Text>
        <h2>Välkommen till Camp Igge och Ankarmons Campings bokningssystem.</h2>
        <p>
          Som en del av fowlit möjliggör denna tjänst att enkelt skapa allt ifrån enkla
          stugbokningar till träningsläger.
        </p>
        <Button onClick={onClickHandler}>Logga in</Button>
      </Text>
      <Logo />
      <Waves>
        <Wave />
        <Wave />
      </Waves>
    </Container>
  );
};

export default Landing;
