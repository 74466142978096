import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Colors, Fonts, Dimensions, Media } from 'fowlit';
import Calendar from './Calendar';
import { useComponentVisible } from '../../../Hooks';

const datepickerHeight = '70px';

const Container = styled.div`
  user-select: none;
  grid-area: date;
  position: relative;
  background: ${props => props.theme.searchbarBackground};
  border: 2px solid ${props => props.theme.searchbarBorder};
  display: grid;
  grid-template-columns: ${datepickerHeight} 1fr;
  grid-template-rows: ${datepickerHeight} 1fr;
  grid-template-areas: 'icon label' 'dropdown dropdown';
  border-left: none;
  @media (max-width: ${Media.phone}) {
    border-left: 1px solid ${props => props.theme.searchbarBorder};
  }
`;

const Icon = styled.i`
  grid-area: icon;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${Fonts.sizeTiny};
  color: ${props => (props.active ? props.theme.searchbarActive : props.theme.searchbarColor)};
  transition: 200ms ease-in-out;
`;

const Label = styled.div`
  grid-area: label;
  color: ${props => props.theme.searchbarColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${Fonts.text};
  font-size: ${Fonts.sizeSuperTiny};
  padding: 0 30px 0 0;
  cursor: pointer;

  i {
    transform: ${props => (props.active ? `rotateZ(180deg)` : `none`)};
    transition: 200ms ease-in-out;
    font-size: ${Fonts.sizeTiny};
  }
`;

const Dropdown = styled.div`
  background: ${props => props.theme.searchbarBackground};
  display: ${props => (props.active ? 'block' : 'none')};
  position: absolute;
  top: calc(${datepickerHeight} - 2px);
  left: -2px;
  box-shadow: 1px 5px 5px -7px ${Colors.black};
  border-bottom-left-radius: ${Dimensions.radius};
  border-bottom-right-radius: ${Dimensions.radius};
  border-left: 2px solid ${props => props.theme.searchbarBorder};
  border-right: 2px solid ${props => props.theme.searchbarBorder};
  border-bottom: 2px solid ${props => props.theme.searchbarBorder};
  @media (max-width: ${Media.phone}) {
    grid-area: dropdown;
    top: -2px;
    right: -1px;
  }
`;

const DatePicker = ({ label, value, setValue }) => {
  const { ref, visible, setVisible } = useComponentVisible(false);

  const interval = () => {
    const from = new Date(value[0]).toLocaleDateString('sv-SV', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
    const to = new Date(value[1]).toLocaleDateString('sv-SV', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
    const ret = `${from} - ${to}`;
    return `${ret}`;
  };

  return (
    <Container ref={ref}>
      <Icon active={visible || value.length !== 0} className="icon-calendar" />
      <Label active={visible} onClick={() => setVisible(v => !v)}>
        {value.length === 0 ? label : interval()}
        <i className="icon-angle-down" />
      </Label>
      <Dropdown active={visible}>
        <Calendar onSelect={setValue} />
      </Dropdown>
    </Container>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  setValue: PropTypes.func.isRequired
};

export default DatePicker;
