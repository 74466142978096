import React from 'react';

import Users from './Users';
import Bookings from './Bookings';
import Housings from './Housings';

const GraphQLState = () => {
  return (
    <>
      <Bookings />
      <Users />
      <Housings />
    </>
  );
};

export default GraphQLState;
