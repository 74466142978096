import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Colors } from 'fowlit';
import { useSelector } from 'react-redux';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  a {
    color: ${props => props.theme.color};
    font-size: 20px;
    i {
      position: relative;
    }
  }

  .active {
    color: ${Colors.blueberry};
  }
`;

const Dot = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  background: ${Colors.strawberry};
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid ${props => props.theme.body};
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: ${Colors.white}
    font-size: 0.5rem;
  }
`;

const Icon = () => {
  const basket = useSelector(state => state.basket);
  return (
    <Container>
      <NavLink to="/basket">
        <i className="icon-basket">
          {basket.length !== 0 && (
            <Dot>
              <i>{basket.length}</i>
            </Dot>
          )}
        </i>
      </NavLink>
    </Container>
  );
};

export default Icon;
