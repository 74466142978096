import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fonts } from 'fowlit';
import { useDispatch } from 'react-redux';
import { addErr } from 'Reducers/Error/actions';
import { setDiscount as setReduxDiscount } from '../../Reducers/Basket/actions';

const Container = styled.div`
  display: grid;
  grid-template-columns: ${props => `minmax(20px, ${props.length * 10}px)`} 20px;
  grid-gap: 3px;
  align-items: center;

  input {
    font-family: ${Fonts.head};
    font-weight: ${Fonts.weightBold};
    font-size: ${Fonts.sizeSuperTiny};
    background: none;
    color: ${props => props.theme.color};
    width: 100%;
    appearance: none;
    border: none;
    overflow-x: hidden;
  }
`;

const Discount = ({ discount: d, id, price: p }) => {
  const dispatch = useDispatch();

  const [discount, setDiscount] = useState((parseInt(d, 10) / 10000).toString());
  const el = useRef('');
  const price = parseInt(p, 10) / 10000;

  return (
    <Container length={discount.length}>
      <input
        ref={el}
        type="text"
        value={discount}
        onChange={e => setDiscount(e.target.value)}
        onBlur={async () => {
          if (parseInt(discount, 10) > price) {
            dispatch(addErr(`Rabatten kan inte vara högre än ${price}.`));
            el.current.focus();
            el.current.select();
          } else if (parseInt(discount, 10) < 0) {
            dispatch(addErr(`Rabatten kan inte vara mindre än ${0}.`));
            el.current.focus();
            el.current.select();
          } else if (discount === '' || discount === '0') {
            dispatch(
              setReduxDiscount({
                id,
                discount: '0'
              })
            );
          } else if (!isNaN(discount)) {
            dispatch(
              setReduxDiscount({
                id,
                discount: (discount.length !== 0 ? parseInt(discount, 10) * 10000 : 0).toString()
              })
            );
          }
        }}
        pattern="[0-9]+"
      />
      <h6>kr</h6>
    </Container>
  );
};

Discount.propTypes = {
  discount: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired
};

export default Discount;
