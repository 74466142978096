import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCurrentBooking,
  deleteCurrentBooking,
  errorCurrentBooking,
  initCurrentBookings,
  loadingCurrentBooking,
  updateCurrentBooking
} from '../Reducers/Bookings/Current/actions';
import {
  addLatestBooking,
  deleteLatestBooking,
  errorLatestBooking,
  initLatestBookings,
  loadingLatestBooking,
  updateLatestBooking
} from '../Reducers/Bookings/Latest/actions';
import {
  addTodayBooking,
  deleteTodayBooking,
  errorTodayBooking,
  initTodayBookings,
  loadingTodayBooking,
  updateTodayBooking
} from '../Reducers/Bookings/Today/actions';

const fragments = `
fragment PersonParts on Person {
  firstName
  lastName
  email
  phone
  address
  zipCode
  city
  country
  lastPurchase
}

fragment CompanyParts on Company {
  organizationNr
  reference
  email
  phone
  address
  zipCode
  city
  country
  lastPurchase
  name
}
`;

const bookingValues = `
referenceID
price
userID
date
discount
bookings {
  id
  name
  price
  discount
  itemID
  itemType
  startTime
  endTime
}
customer {
  ...PersonParts
  ...CompanyParts
}
`;

const GET_BOOKINGS = gql`
    ${fragments}

    query($nr: Int!, $offset: Int!) {
        getBookingReferences(nr: $nr, offset: $offset) {
            ${bookingValues}
        }
        getTodaysNewBookings {
            ${bookingValues}
        }
        getCurrentBookings {
            ${bookingValues}
        }
    }
`;

const BOOKING_REFERENCE_ADDED = gql`
    ${fragments}

    subscription {
        bookingReferenceAdded {
            ${bookingValues}
        }
    }
`;

const BOOKING_REFERENCE_EDITED = gql`
    ${fragments}

    subscription {
        bookingReferenceEdited {
            ${bookingValues}
        }
    }
`;

const BOOKING_REFERENCE_DELETED = gql`
    ${fragments}

    subscription {
        bookingReferenceDeleted {
            ${bookingValues}
        }
    }
`;

const isToday = bookings => {
  const now = new Date();
  return bookings.some(e => {
    const startTime = new Date(Date.parse(e.startTime));
    return (
      now.getFullYear() === startTime.getFullYear() &&
      now.getMonth() === startTime.getMonth() &&
      now.getDate() === startTime.getDate()
    );
  });
};

const isCurrent = bookings => {
  const now = new Date();
  return bookings.some(e => {
    const startTime = new Date(Date.parse(e.startTime));
    const endTime = new Date(Date.parse(e.endTime));
    return startTime < now && endTime > now;
  });
};

const Bookings = () => {
  const dispatch = useDispatch();
  const latest = useSelector(state => state.bookings.latest.bookings);
  const today = useSelector(state => state.bookings.today.bookings);
  const current = useSelector(state => state.bookings.current.bookings);

  const getBookings = useQuery(GET_BOOKINGS, {
    variables: {
      nr: 30,
      offset: 0
    }
  });

  const bookingReferenceAdded = useSubscription(BOOKING_REFERENCE_ADDED);
  const bookingReferenceEdited = useSubscription(BOOKING_REFERENCE_EDITED);
  const bookingReferenceDeleted = useSubscription(BOOKING_REFERENCE_DELETED);

  useEffect(() => {
    const { data, loading, error } = getBookings;
    if (loading) {
      dispatch(loadingCurrentBooking(true));
      dispatch(loadingTodayBooking(true));
      dispatch(loadingLatestBooking(true));
    } else if (error) {
      dispatch(errorCurrentBooking(error));
      dispatch(errorTodayBooking(error));
      dispatch(errorLatestBooking(error));
    } else if (data) {
      dispatch(loadingCurrentBooking(false));
      dispatch(loadingTodayBooking(false));
      dispatch(loadingLatestBooking(false));

      dispatch(initCurrentBookings(data.getCurrentBookings));
      dispatch(initLatestBookings(data.getBookingReferences));
      dispatch(initTodayBookings(data.getTodaysNewBookings));
    }
  }, [dispatch, getBookings]);

  useEffect(() => {
    if (bookingReferenceAdded.data) {
      const bf = bookingReferenceAdded.data.bookingReferenceAdded;
      // Insert to today
      if (isToday(bf.bookings)) {
        dispatch(addTodayBooking(bf));
      }

      // Insert to current
      if (isCurrent(bf.bookings)) {
        dispatch(addCurrentBooking(bf));
      }

      // Insert to latest
      dispatch(addLatestBooking(bf));
    }
  }, [bookingReferenceAdded.data, dispatch]);

  useEffect(() => {
    if (bookingReferenceEdited.data) {
      const bf = bookingReferenceEdited.data.bookingReferenceEdited;

      // Insert to today
      const isInToday = today.some(e => e.referenceID === bf.referenceID);
      if (isInToday) {
        if (isToday(bf.bookings)) {
          dispatch(updateTodayBooking(bf));
        } else {
          dispatch(deleteTodayBooking(bf));
        }
      } else if (isToday(bf.bookings)) {
        dispatch(addTodayBooking(bf));
      }

      const isInCurrent = current.some(e => e.referenceID === bf.referenceID);
      if (isInCurrent) {
        if (isCurrent(bf.bookings)) {
          dispatch(updateCurrentBooking(bf));
        } else {
          dispatch(deleteCurrentBooking(bf));
        }
      } else if (isCurrent(bf.bookings)) {
        dispatch(addCurrentBooking(bf));
      }

      const isInLatest = latest.some(e => e.referenceID === bf.referenceID);
      if (isInLatest) {
        dispatch(updateLatestBooking(bf));
      } else {
        dispatch(addLatestBooking(bf));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingReferenceEdited.data, dispatch]);

  useEffect(() => {
    if (bookingReferenceDeleted.data) {
      const bf = bookingReferenceDeleted.data.bookingReferenceDeleted;

      const isInToday = today.some(e => e.referenceID === bf.referenceID);
      if (isInToday) {
        dispatch(deleteTodayBooking(bf));
      }

      const isInCurrent = current.some(e => e.referenceID === bf.referenceID);
      if (isInCurrent) {
        dispatch(deleteCurrentBooking(bf));
      }

      const isInLatest = latest.some(e => e.referenceID === bf.referenceID);
      if (isInLatest) {
        dispatch(deleteLatestBooking(bf));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingReferenceDeleted.data, dispatch]);

  return <></>;
};

export default Bookings;
