import { ADD_ERR, POP_ERR } from './actions';

const errors = (state = [], action) => {
  switch (action.type) {
    case ADD_ERR: {
      const { id, text } = action.payload;
      const len = state.length;
      if (len > 1 && state[len - 1].text === state[len - 2].text && state[len - 1].text === action.payload.text) {
        return state;
      }

      return [
        ...state,
        {
          id,
          text,
          type: action.payload.type
        }
      ];
    }

    case POP_ERR:
      if (state.length > 0) {
        state.shift();
        return [...state];
      }
      return state;

    default:
      return state;
  }
};

export default errors;
