import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Colors, Dimensions, Fonts } from 'fowlit';
import { useDispatch } from 'react-redux';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { addErr, addMsg } from '../../Reducers/Error/actions';
import { Input, Select, Checkbox } from '../Forms';

const types = [
  { type: 'cabin', label: 'Stuga' },
  { type: 'hostel', label: 'Vandrarhem' },
  { type: 'caravan', label: 'Husvagn' },
  { type: 'tent', label: 'Tält' },
  { type: 'room', label: 'Arena' }
];

const Container = styled.div`
  display: grid;
  grid-template-columns: 940px;
  grid-template-rows: 30px 1fr 60px;
  grid-gap: 20px;
  box-shadow: 1px 5px 15px -10px ${Colors.blackDarker};
  border-radius: ${Dimensions.radius};
  border: 2px solid ${props => props.theme.border};
  background: ${props => props.theme.body};
  padding: 30px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.color};
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 60px 1fr 1fr 1fr;
  grid-gap: 20px;
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 60px);
  align-items: center;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  appearance: none;
  border: none;
`;

const Close = styled(Button)`
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  &:focus {
    background: ${Colors.strawberry};
    color: ${Colors.white};
  }
`;

const SaveBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Save = styled.button`
  color: ${Colors.white};
  font-family: ${Fonts.text};
  font-size: 0.8rem;
  outline: none;
  appearance: none;
  border: none;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  padding: 0 30px;
  background: ${Colors.blueberry};
  &:hover {
    background: ${Colors.blueberryDarker};
  }
  &:focus {
    background: ${Colors.blueberryDarker};
  }
`;

const Initials = styled.div`
  background: linear-gradient(
    to bottom left,
    ${Colors.blueberryLighter},
    ${Colors.blueberry},
    ${Colors.blueberryDarker}
  );
  color: ${Colors.white};
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: ${Fonts.head};
  font-size: ${Fonts.sizeSmall};
  border: 1px solid ${props => props.theme.border};
`;

const ADD_HOUSING = gql`
  mutation(
    $type: String!
    $name: String!
    $beds: Int
    $size: Float
    $price: String!
    $wifi: Boolean!
    $kitchen: Boolean!
    $electricity: Boolean!
    $tv: Boolean!
    $active: Boolean!
  ) {
    addHousing(
      housing: {
        type: $type
        name: $name
        beds: $beds
        size: $size
        price: $price
        wifi: $wifi
        kitchen: $kitchen
        electricity: $electricity
        tv: $tv
        active: $active
      }
    ) {
      id
    }
  }
`;

const CreateHousing = ({ setActive }) => {
  const dispatch = useDispatch();
  const [addHousing, { data, error, loading }] = useMutation(ADD_HOUSING);
  const nameEl = useRef('');
  const [name, setName] = useState('');
  const [beds, setBeds] = useState('');
  const [type, setType] = useState({ type: '', label: '' });
  const [size, setSize] = useState('');
  const [wifi, setWifi] = useState(false);
  const [kitchen, setKitchen] = useState(false);
  const [electricity, setElectricity] = useState(false);
  const [tv, setTV] = useState(false);
  const [price, setPrice] = useState('');

  useEffect(() => {
    nameEl.current.focus();
  }, []);

  useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(addErr('Kunde inte skapa ny bostad.'));
      } else if (data) {
        setActive(false);
        dispatch(addMsg('Skapade ny bostad!'));
      }
    }
  }, [data, dispatch, error, loading, setActive]);

  const createHousing = async e => {
    e.preventDefault();
    await addHousing({
      variables: {
        type: type.type,
        name,
        beds: parseInt(beds, 10),
        size: parseFloat(size),
        price: (parseInt(price, 10) * 10000).toString(),
        wifi,
        kitchen,
        electricity,
        tv,
        active: true
      }
    });
  };
  return (
    <Container>
      <Header>
        <h6>Skapar ny bostad</h6>
        <Close className="icon-cancel-circled" onClick={() => setActive(false)} />
      </Header>
      <Form id="form" onSubmit={e => createHousing(e)}>
        <Initials>{name.charAt(0).toUpperCase()}</Initials>
        <Box>
          <Input
            label="Namn"
            type="text"
            value={name}
            setValue={e => setName(e)}
            required
            minLength={1}
            maxLength={50}
            ref={nameEl}
          />
          <Input
            label="Pris"
            type="text"
            value={price}
            setValue={e => setPrice(e)}
            required
            pattern="[0-9]+"
            minLength={1}
            maxLength={50}
          />
          <Input
            label="Bäddar"
            type="text"
            value={beds}
            setValue={e => setBeds(e)}
            pattern="[0-9]+"
            minLength={1}
            maxLength={50}
          />
          <Input
            label="Storlek"
            type="text"
            value={size}
            setValue={e => setSize(e)}
            pattern="[0-9]+"
            minLength={1}
            maxLength={50}
          />
        </Box>
        <Box>
          <Select label="Typ" options={types} value={type} setValue={setType} />
        </Box>
        <Box>
          <Checkbox name="wifi" label="Har boendet wifi?" value={wifi} setValue={setWifi} />
          <Checkbox name="kitchen" label="Har boendet kök?" value={kitchen} setValue={setKitchen} />
          <Checkbox name="electricity" label="Har boendet el?" value={electricity} setValue={setElectricity} />
          <Checkbox name="tv" label="Har boendet tv?" value={tv} setValue={setTV} />
        </Box>
      </Form>
      <SaveBox>
        <Save type="submit" form="form">
          Spara
        </Save>
      </SaveBox>
    </Container>
  );
};

CreateHousing.propTypes = {
  setActive: PropTypes.func.isRequired
};
export default CreateHousing;
