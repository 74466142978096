import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Colors, Dimensions, Fonts } from 'fowlit';
import { useDispatch } from 'react-redux';
import { deleteProduct } from 'Reducers/Basket/actions';
import Discount from './Discount';
import Guests from './Guests';

const Item = styled.div`
  background: ${props => props.theme.body};
  color: ${props => props.theme.color};
  border: 2px solid ${props => props.theme.border};
  border-radius: ${Dimensions.radius};
  margin: 5px 0;
  user-select: none;
  transition: 200ms ease-out;
  display: grid;
  grid-template-columns: 80px 1fr 1.5fr 1.5fr repeat(4, 1fr) 60px};
  grid-template-rows: 90px;
  align-items: center;
  padding: 0 20px;
  &:hover {
    box-shadow: 1px 5px 15px -10px ${Colors.blackDarker};
    margin-top: 10px;
  }
`;

const Button = styled.button`
  font-size: 22px;
  border: none;
  appearance: none;
  outline: none;
  background: none;
  color: ${props => props.theme.color};
  cursor: pointer;
  transition: 300ms ease-out;
`;

const Remove = styled(Button)`
  &:hover {
    color: ${Colors.strawberry};
  }
`;

const Cell = styled.div`
  padding: 0 10px;
  i {
    font-family: ${Fonts.text};
    font-size: 0.8rem;
  }
`;

const Image = styled.div`
  background: ${props => `url('${props.url}')`} no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 60px;
  width: 60px;
`;

const Initials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom left, ${Colors.blueberry}, ${Colors.blueberryDarker});
  color: ${Colors.white};
  width: 60px;
  height: 60px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 50%;
  text-transform: uppercase;
  font-family: ${Fonts.head};
  font-size: ${Fonts.sizeSmall};
`;

const BigItem = ({ data }) => {
  const dispatch = useDispatch();
  const oneDay = 24 * 60 * 60 * 1000;
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const nights = Math.round((new Date(data.endTime).getTime() - new Date(data.startTime).getTime()) / oneDay);

  const GenerateType = () => {
    switch (data.itemType) {
      case 'cabin':
        return <i>Stuga</i>;
      case 'caravan':
        return <i>Husvagn</i>;
      case 'hostel':
        return <i>Vandrarhem</i>;
      case 'tent':
        return <i>Tält</i>;
      case 'room':
        return <i>Arena</i>;
      default:
        return <i />;
    }
  };

  return (
    <Item key={Math.random()}>
      <Cell>
        {!data.image ? (
          <Initials type={data.type}>{data.name.charAt(0).toUpperCase()}</Initials>
        ) : (
          <Image url={data.image} />
        )}
      </Cell>
      <Cell>
        <h6>{data.name}</h6>
        <GenerateType />
      </Cell>
      <Cell>
        <i>Från</i>
        <h6>{new Date(data.startTime).toLocaleDateString('sv-SV', options)}</h6>
      </Cell>
      <Cell>
        <i>Till</i>
        <h6>{new Date(data.endTime).toLocaleDateString('sv-SV', options)}</h6>
      </Cell>
      <Cell>
        <i>Nätter</i>
        <h6>{nights}</h6>
      </Cell>
      <Cell>
        <i>Pris</i>
        <h6>{`${(parseInt(data.price, 10) / 10000).toLocaleString('sv-SV')} kr`}</h6>
      </Cell>
      <Cell>
        <i>Gäster</i>
        <Guests value={data.guests} id={data.id} />
      </Cell>
      <Cell>
        <i>Rabatt</i>
        <Discount discount={data.discount} id={data.id} price={data.price} />
      </Cell>
      <Cell>
        <Remove className="icon-trash" onClick={() => dispatch(deleteProduct(data.id))} />
      </Cell>
    </Item>
  );
};

BigItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.string,
    housingID: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    userID: PropTypes.string,
    discount: PropTypes.string,
    itemType: PropTypes.string,
    guests: PropTypes.number
  }).isRequired
};

BigItem.defaultValues = {
  image: ''
};

export default BigItem;
